/**
 * Dependências externas
 */
import React, { useState } from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Collapse,
  Button,
  Flex,
  Box
} from '@chakra-ui/core';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import formatarMoeda from 'paginas/utils/formatarMoeda';
import TabelaDetalhamentoDaFatura from 'paginas/Faturas/apresentacao/TabelaDetalhamentoDaFatura';

const ValorFatura = props => {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  const nomeDoCampo = 'valor';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const { valor: itens } = getInformacoesDoCampo(props, 'itens');

  const valorApenasNumeros = replace(valor, /[^0-9]/g, '');

  const valorFormatado = isEmpty(valorApenasNumeros)
    ? valorApenasNumeros
    : formatarMoeda(valorApenasNumeros);

  return (
    <Box marginBottom="0.75rem">
      <Flex alignItems="flex-end">
        <FormControl
          style={{ marginBottom: 0 }}
          isInvalid={possuiErroDeValidacao}
          {...rest}
        >
          <FormLabel htmlFor={nomeDoCampo}>Valor da fatura</FormLabel>
          <Input
            type="text"
            id={nomeDoCampo}
            name={nomeDoCampo}
            onChange={handleChange}
            onBlur={handleBlur}
            value={valorFormatado}
            textAlign="right"
            autoComplete="off"
          />
          {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
        </FormControl>
        <Box marginLeft="10px">
          <Button
            type="button"
            onClick={handleToggle}
            variant="ghost"
            variantColor="blue"
          >
            Exibir itens da fatura
          </Button>
        </Box>
      </Flex>
      <Collapse isOpen={show}>
        <TabelaDetalhamentoDaFatura itens={itens} />
      </Collapse>
    </Box>
  );
};

export default connectToFormik(ValorFatura);
