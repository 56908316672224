/**
 * Dependências externas
 */
import get from 'lodash/get';
import { FormikProps } from 'formik';

const getValorDoCampo = ( obj: {formik: FormikProps<{}>}, nomeDoCampo: string ) =>
  get( obj, `formik.values[${nomeDoCampo}]` );

export default getValorDoCampo;
