// @ts-nocheck
/**
 * Dependências externas
 */
import React, { useRef, useState, useEffect } from 'react';
import {
  AlertDialog,
  Button,
  Scale,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/core';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import partialRight from 'lodash/fp/partialRight';
import { AxiosResponse } from 'axios';

/**
 * Dependências internas
 */
import Mensagem from 'apresentacao/Mensagem';

const getMensagemDeErro = partialRight(get, [
  'response.data.exception.message',
  'Ocorreu um erro ao tentar excluir.'
]);

const AlertaExclusao: React.FC<{
  onClose: () => void;
  tipoRegistro: string;
  idRegistro: number;
  nomeIdentificaçãoRegistro?: string;
  onSuccess: () => void;
  onCancel: () => void;
  excluir: (id: string | number) => Promise<AxiosResponse<any>>;
}> = props => {
  const {
    onClose,
    tipoRegistro,
    idRegistro,
    nomeIdentificaçãoRegistro,
    onSuccess,
    onCancel,
    excluir
  } = props;

  const cancelRef = useRef();
  const [excluindo, setExcluindo] = useState(false);
  const [erro, setErro] = useState(false);

  useEffect(() => {
    let didCancel = false;

    if (excluindo && idRegistro) {
      const excluirRegistro = () => {
        setErro(false);

        excluir(idRegistro)
          .then(() => {
            if (!didCancel) {
              onSuccess();
              onClose();
            }
          })
          .catch(result => {
            const setMensagemDeErro = compose(setErro, getMensagemDeErro);
            if (!didCancel) {
              setMensagemDeErro(result);
            }
          })
          .finally(() => !didCancel && setExcluindo(false));
      };

      excluirRegistro();
    }

    return () => {
      didCancel = true;
    };
  }, [excluindo, excluir, onClose, onSuccess, idRegistro]);

  return (
    <>
      <Scale in={true}>
        {(styles: Object) => (
          <AlertDialog
            leastDestructiveRef={cancelRef}
            onClose={onCancel}
            isOpen={true}
          >
            <AlertDialogOverlay opacity={styles.opacity} />
            <AlertDialogContent {...styles}>
              <AlertDialogHeader>Excluir {tipoRegistro}</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                {erro && (
                  <Mensagem tipo="error" mb="1.25rem">
                    {erro}
                  </Mensagem>
                )}
                Você tem certeza que deseja excluir o registro{' '}
                {nomeIdentificaçãoRegistro || idRegistro}? Essa ação não poderá
                ser desfeita
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={onCancel}
                  isDisabled={excluindo}
                >
                  Não
                </Button>
                <Button
                  variantColor="red"
                  ml={3}
                  onClick={() => setExcluindo(true)}
                  isLoading={excluindo}
                >
                  Sim
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </Scale>
    </>
  );
};

export default AlertaExclusao;
