/**
 * Dependências externas
 */
import takeRight from 'lodash/takeRight';
import slice from 'lodash/slice';
import join from 'lodash/join';
import concat from 'lodash/concat';
import toString from 'lodash/toString';
import isString from 'lodash/isString';

export default function formatarMoeda(valor: string | number) {
  let valorFormatado = '';
  let valorFloat;
  let valorString = isString(valor) ? valor : toString(valor);

  if (1 === valorString.length) {
    valorFloat = parseFloat('0.0' + valorString);
  } else if (2 === valorString.length) {
    valorFloat = parseFloat('0.' + valorString);
  } else if (valorString.length > 2) {
    const centavos = join(takeRight(valorString, 2), '');
    const parteInteira = slice(valorString, 0, valorString.length - 2);

    valorFloat = parseFloat(join(concat(parteInteira, '.', centavos), ''));
  }

  valorFormatado = valorFloat
    ? valorFloat.toLocaleString('pt-BR', {
        minimumFractionDigits: 2
      })
    : '';

  return valorFormatado;
}
