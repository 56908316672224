/**
 * Dependências externas
 */
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

/**
 * Dependências internas
 */
import style from './style';

const SelectPesquisavel = props => {
  const { async } = props;

  return async ? (
    <AsyncSelect
      {...props}
      cacheOptions
      styles={style}
      placeholder=""
      isClearable
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Nenhum resultado'}
      openMenuOnClick={false}
    />
  ) : (
    <Select
      placeholder=""
      isClearable
      isSearchable
      noOptionsMessage={() => 'Nenhum resultado'}
      {...props}
    />
  );
};

export default SelectPesquisavel;
