/**
 * Dependências externas
 */
import { useEffect, useState, useReducer } from 'react';

/**
 * Dependências internas
 */
import api from 'utils/api';

type FetchState = {
  carregando: boolean;
  erro: string | boolean;
  data?: object;
};

type Action =
  | { type: 'FETCH_INIT' }
  | { type: 'FETCH_SUCCESS'; payload: object }
  | { type: 'FETCH_FAILURE'; error?: string | boolean };

/**
 * Reducer que controla os estados de uma requisição HTTP.
 * @param {FetchState} state O estado atual.
 * @param {Action} action Ações para mudar o estado.
 * @returns {object}
 */
function dataFetchReducer(state: FetchState, action: Action): FetchState {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        carregando: true,
        erro: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        carregando: false,
        erro: false,
        data: action.payload
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        carregando: false,
        erro: action.error || true
      };
    default:
      throw new Error();
  }
}

const useDataApi = (initialUrl: string) => {
  const [url, setUrl] = useState(initialUrl);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    carregando: false,
    erro: false
  });

  useEffect(() => {
    let didCancel = false;

    if (url) {
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const result = await api.get(url);
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_FAILURE', error: error });
          }
        }
      };
      fetchData();
    }

    return () => {
      didCancel = true;
    };
  }, [url]);

  return { ...state, setUrl };
};

export default useDataApi;
