/**
 * Dependências externas
 */
import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton
} from '@chakra-ui/core';
import isFunction from 'lodash/isFunction';
import { MarginProps, WidthProps } from 'styled-system';

export type TiposDeMensagem = 'error' | 'success' | 'warning' | 'info';

export interface Props {
  tipo: TiposDeMensagem;
  fechar?: () => void;
}

const Mensagem: React.FC<Props & MarginProps & WidthProps> = props => {
  const { tipo, fechar, children, ...rest } = props;

  return (
    <Alert status={tipo} pr="32px" {...rest}>
      <AlertIcon style={{ marginTop: 0 }} />
      <AlertDescription>{children}</AlertDescription>
      {isFunction(fechar) && (
        <CloseButton
          onClick={fechar}
          position="absolute"
          right="8px"
          top="8px"
        />
      )}
    </Alert>
  );
};

export default Mensagem;
