/**
 * Dependências externas
 */
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';

const Descricao = props => {
  const nomeDoCampo = 'descricao';

  const { formik, ...rest } = props;

  const { handleChange, setFieldValue, handleBlur } = formik;

  const valorDoCampoTipo = get( props, `formik.values.tipo.label`, '' );

  useEffect( () => {
    setFieldValue( nomeDoCampo, valorDoCampoTipo, false );
  }, [ valorDoCampoTipo, setFieldValue ] );

  const valor = get( props, `formik.values[${nomeDoCampo}]` );
  const erro = get( props, `formik.errors[${nomeDoCampo}]` );
  const touched = get( props, `formik.touched[${nomeDoCampo}]` );

  const possuiErroDeValidacao = erro && touched;

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Descrição</FormLabel>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
        autoComplete="off"
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik( Descricao );
