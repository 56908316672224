/**
 * Dependẽncias externas
 */
import React from 'react';
import { Tooltip, IconButton } from '@chakra-ui/core';

export default function CancelarButton({ onClick, isProcessing }) {
  return (
    <Tooltip label="Cancelar" hasArrow placement="top" aria-label="Cancelar">
      <IconButton
        variant="ghost"
        variantColor="red"
        aria-label="Cancelar"
        icon="times"
        size="sm"
        onClick={onClick}
        isDisabled={isProcessing}
      />
    </Tooltip>
  );
}
