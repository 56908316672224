/**
 * Dependências externas
 */
import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import partial from 'lodash/fp/partial';
import partialRight from 'lodash/fp/partialRight';
import compose from 'lodash/fp/compose';
import { connect as connectToFormik } from 'formik';
import { Flex } from '@chakra-ui/core';
import axios from 'axios';

/**
 * Dependências internas
 */
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import getValorDoCampo from 'utils/form/getValorDoCampo';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';

/**
 * Campos
 */
import CEP from './CEP';
import Logradouro from './Logradouro';
import Numero from './Numero';
import Complemento from './Complemento';
import UF from './UF';
import Localidade from './Localidade';
import Bairro from './Bairro';
import Email from './Email';

function getEndereco(cep) {
  const accessToken = localStorage.getItem('accessToken');

  return axios.get(`https://cepweb.webit.inf.br/resources/cep/${cep}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

const GrupoDeCamposEndereco = props => {
  const desconectarUsuario = React.useContext(AutenticacaoContext);

  const { titulo, cobranca } = props;
  const setFieldValue = get(props, 'formik.setFieldValue');
  const valorCEP = getValorDoCampo(props, 'cepCobranca');

  const [
    recuperandoInformacoesDoCEP,
    setRecuperandoInformacoesDoCEP
  ] = useState(false);

  const [erroRecuperarCEP, setErroRecuperarCEP] = useState(false);

  useEffect(() => {
    if (recuperandoInformacoesDoCEP) {
      let didCancel = false;

      setErroRecuperarCEP(false);
      getEndereco(valorCEP)
        .then(({ data }) => {
          const getDataEndereco = compose(
            input => (isNull(input) ? '' : input),
            partial(partialRight(get, ['']), [data])
          );

          setFieldValue(
            'logradouroCobranca',
            getDataEndereco('logradouro.abrev'),
            false
          );

          setFieldValue('ufCobranca', getDataEndereco('uf'), false);

          setFieldValue(
            'localidadeCobranca',
            getDataEndereco('localidade.abrev'),
            false
          );
          setFieldValue(
            'bairroCobranca',
            getDataEndereco('bairro.abrev'),
            false
          );
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: () => setErroRecuperarCEP(true),
              handle401Error: desconectarUsuario,
              handle403Error: () => setErroRecuperarCEP(true),
              handle404Error: () => setErroRecuperarCEP(404),
              handleGenericError: () => setErroRecuperarCEP(true)
            });
          }
        })
        .finally(() => setRecuperandoInformacoesDoCEP(false));

      return () => {
        didCancel = true;
      };
    }
  }, [
    recuperandoInformacoesDoCEP,
    setFieldValue,
    valorCEP,
    desconectarUsuario
  ]);

  return (
    <GrupoDeCampos titulo={titulo}>
      <CEP
        isDisabled={!cobranca}
        cobranca={cobranca}
        isLoading={recuperandoInformacoesDoCEP}
        setRecuperandoInformacoesDoCEP={setRecuperandoInformacoesDoCEP}
        erroRecuperarCEP={erroRecuperarCEP}
        width={['25%', '25%', '25%', '20%']}
      />
      <Logradouro
        isDisabled={!cobranca}
        cobranca={cobranca}
        isLoading={recuperandoInformacoesDoCEP}
      />
      <Numero
        isDisabled={!cobranca}
        cobranca={cobranca}
        isLoading={recuperandoInformacoesDoCEP}
        width={['25%', '25%', '25%', '20%']}
      />
      <Complemento
        isDisabled={!cobranca}
        cobranca={cobranca}
        isLoading={recuperandoInformacoesDoCEP}
      />
      <Bairro
        isDisabled={!cobranca}
        cobranca={cobranca}
        isLoading={recuperandoInformacoesDoCEP}
      />
      <Flex>
        <Localidade
          isDisabled={!cobranca}
          cobranca={cobranca}
          isLoading={recuperandoInformacoesDoCEP}
          width={['100%', '70%', '60%']}
        />
        <UF
          isDisabled={!cobranca}
          cobranca={cobranca}
          isLoading={recuperandoInformacoesDoCEP}
          width={['25%', '25%', '25%', '10%']}
          marginLeft="4"
        />
      </Flex>

      {cobranca && <Email isDisabled={!cobranca} cobranca={cobranca} />}
    </GrupoDeCampos>
  );
};

export default connectToFormik(GrupoDeCamposEndereco);
