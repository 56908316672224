/*
 * Dependências externas
 */
import React from 'react';
import styled from '@emotion/styled';
import { Flex, IconButton, Icon } from '@chakra-ui/core';
import moment from 'moment';

/*
 * Dependências internas
 */
import DatePicker from './DatePicker';
import { ActionDatePicker } from 'apresentacao/date/datePickerReducer';

const ArrowButton = styled(IconButton)`
  :hover {
    background-color: #ebedf0;
  }
`;

function IconSetaEsquerda() {
  return <Icon name="setaEsquerda" />;
}

function IconSetaDireita() {
  return <Icon name="setaDireita" />;
}

function DatePickerWithNavigation({
  isOpen,
  from,
  to,
  enteredTo,
  dispatch,
  isDisabled
}: {
  isOpen: boolean;
  from: Date;
  to: Date | null;
  enteredTo: Date;
  dispatch: React.Dispatch<ActionDatePicker>;
  isDisabled: boolean;
}) {
  return (
    <Flex>
      <ArrowButton
        aria-label="Seleciona o mês anterior"
        isDisabled={isDisabled}
        icon={IconSetaEsquerda}
        variant="outline"
        style={{
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }}
        onClick={() => {
          const dataMesAnterior = from
            ? moment(from).subtract(1, 'month')
            : moment().subtract(1, 'month');

          const fromPrevious = dataMesAnterior.startOf('month').toDate();
          const toPrevious = dataMesAnterior.endOf('month').toDate();

          dispatch({
            type: 'selecionaPeriodo',
            from: fromPrevious,
            to: toPrevious
          });
        }}
      />

      <DatePicker
        isOpen={isOpen}
        from={from}
        to={to}
        enteredTo={enteredTo}
        dispatch={dispatch}
        isDisabled={isDisabled}
        style={{
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderLeft: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
      />

      <ArrowButton
        aria-label="Seleciona o próximo mês"
        icon={IconSetaDireita}
        isDisabled={isDisabled}
        variant="outline"
        style={{
          borderLeft: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        onClick={() => {
          const dataMesPosterior = from
            ? moment(from).add(1, 'month')
            : moment().add(1, 'month');

          const fromNext = dataMesPosterior.startOf('month').toDate();
          const toNext = dataMesPosterior.endOf('month').toDate();

          dispatch({
            type: 'selecionaPeriodo',
            from: fromNext,
            to: toNext
          });
        }}
      />
    </Flex>
  );
}

export default DatePickerWithNavigation;
