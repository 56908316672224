/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependências internas
 */
import BarraSuperior, { Link } from '../../../apresentacao/BarraSuperior';

const BarraSuperiorFaturas = (props) => {
  const {path, titulo} = props;

  return (
    <BarraSuperior titulo={titulo}>
      <Link to={`${path}/todas`} activeClassName="selected">
        Ver todas
      </Link>
    </BarraSuperior>
  );
};

export default BarraSuperiorFaturas;
