/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const Tr = styled.tr`
  :hover {
    background-color: #fafbfc;
  }
`;

export default Tr;
