/**
 * Dependências externas
 */
import React, { useState, useEffect, useContext } from 'react';
import { connect as connectToFormik } from 'formik';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import SelectPesquisavel from '../../../../../apresentacao/form/Select/SelectPesquisavel';
import apiURL from 'constants/apiURL';
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import useDataApi from 'hooks/useDataApi';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from "paginas/utils/handleHTTPError";

const Tipo = props => {
  const nomeDoCampo = 'tipo';

  const { formik, ...rest } = props;

  const desconectarUsuario = useContext( AutenticacaoContext );

  const { setFieldValue, setFieldTouched } = formik;

  const { data, carregando, erro: erroCarregarTipos } = useDataApi(
    `${apiURL}tiposlancamentos`
  );

  const [ options, setOptions ] = useState();

  useEffect( () => {
    if ( ! carregando && data ) {
      setOptions( () =>
        data.map( opcao => ( { value: opcao.id, label: opcao.conceito } ) )
      );
    }
  }, [ data, carregando ] );
  
  useEffect( () => {
    if ( erroCarregarTipos ) {
      handleHTTPError( {
        error: erroCarregarTipos, 
        handle401Error: desconectarUsuario, 
      } )
    }
  }, [ erroCarregarTipos, desconectarUsuario ] )
  
  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
    );

  return (
    <FormControl
      isInvalid={possuiErroDeValidacao || erroCarregarTipos}
      {...rest}
    >
      <FormLabel id={`label-${nomeDoCampo}`}>Tipo</FormLabel>

      <SelectPesquisavel
        aria-labelledby={`label-${nomeDoCampo}`}
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={value => {
          if ( value ) {
            setFieldValue( nomeDoCampo, value, false );
          } else {
            setFieldValue( nomeDoCampo, '', false );
          }
        }}
        onBlur={() => setFieldTouched( nomeDoCampo )}
        value={valor}
        options={options}
        isLoading={carregando}
      />

      {erroCarregarTipos && (
        <FormErrorMessage>
          Ocorreu um erro ao carregar as opções
        </FormErrorMessage>
      )}
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik( Tipo );
