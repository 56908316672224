/**
 * Converte um array para object.
 * @param {Array} array Array a ser convertido.
 * @param {string} keyField Key utilizada no objeto a ser criado.
 * @returns {object}
 */
function arrayToObject ( array: Array<any>, keyField: string ): object {
  return array.reduce(
    ( obj, item ) => ( {
      ...obj,
      [item[keyField]]: item
    } ),
    {}
  );
}

export default arrayToObject;
