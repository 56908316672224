/**
 * Dependências externas
 */
import React from 'react';
import map from 'lodash/map';

/**
 * Dependências internas
 */
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import formatarMoeda from 'paginas/utils/formatarMoeda';

function TabelaDetalhamentoDaFatura({ itens }) {
  return (
    <Table>
      <Thead>
        <tr>
          <Th>Descrição</Th>
          <Th style={{ textAlign: 'right' }}>Valor</Th>
        </tr>
      </Thead>
      <tbody>
        {map(itens, item => (
          <tr key={item.id}>
            <Td>{item.descricao}</Td>
            <Td style={{ textAlign: 'right' }}>{formatarMoeda(item.valor)}</Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TabelaDetalhamentoDaFatura;
