/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Radio
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';

const TipoDaBaixa = props => {
  const nomeDoCampo = 'tipoDaBaixa';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Tipo da baixa</FormLabel>
      <RadioGroup
        isInline
        spacing={4}
        value={valor}
        onChange={handleChange}
        onBlur={handleBlur}
        name={nomeDoCampo}
      >
        <Radio value="ACORDO" size="md">
          Acordo
        </Radio>
        <Radio value="DEPOSITO">Depósito</Radio>
        <Radio value="DINHEIRO">Dinheiro</Radio>
      </RadioGroup>
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(TipoDaBaixa);
