/**
 * Dependências externas
 */
import isEqual from 'lodash/fp/isEqual';
import partialRight from 'lodash/fp/partialRight';
import compose from 'lodash/fp/compose';
import getHttpStatusError from './getHttpStatusError';

const is400Error = compose(partialRight(isEqual, [400]), getHttpStatusError);

export default is400Error;
