/*
 * Dependências externas
 */
import React, {
  useReducer,
  useCallback,
  useEffect,
  useMemo,
  useContext
} from 'react';
import {
  Button,
  Flex,
  useToast,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  IconButton,
  Collapse,
  Box,
  Tooltip
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import without from 'lodash/without';
import union from 'lodash/union';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/fp/isEqual';
import negate from 'lodash/fp/negate';
import partial from 'lodash/fp/partial';
import compose from 'lodash/fp/compose';
import first from 'lodash/fp/first';
import tail from 'lodash/fp/tail';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';
import gt from 'lodash/fp/gt';

/**
 * Dependências internas
 */
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import Acoes from './acoes/Acoes';
import api from 'utils/api';
import formatarData from 'paginas/utils/formatarData';
import ExcluirInquilinoButton from './acoes/ExcluirInquilinoButton';
import RegistrarSaidaButton from './acoes/RegistrarSaidaButton';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';

/**
 * Campos do formulário
 */
import IdentidadeInquilino from './IdentidadeInquilino';
import NomeCompletoInquilino from './NomeCompletoInquilino';
import DataDeEntrada from './DataDeEntrada';

/**
 * Types
 */
import AcoesProps from './types/Acoes';

type PostSalvarInquilino = {
  unidadeId: string;
  data: { nome: string; identidade: string; entrada: string };
};

function postSalvarInquilino({ unidadeId, data }: PostSalvarInquilino) {
  return api.post(`unidades/${unidadeId}/inquilinos`, data);
}

type DeleteInquilino = {
  unidadeId: string;
  dataEntrada: string;
};

function deleteInquilino({ unidadeId, dataEntrada }: DeleteInquilino) {
  return api.delete(`unidades/${unidadeId}/inquilinos/${dataEntrada}`);
}

type PostRegistrarSaidaInquilino = {
  unidadeId: string;
  dataEntrada: string;
  data: { saida: string };
};

function postRegistrarSaidaInquilino({
  unidadeId,
  dataEntrada,
  data
}: PostRegistrarSaidaInquilino) {
  return api.post(
    `unidades/${unidadeId}/inquilinos/${dataEntrada}/baixar`,
    data
  );
}

const ThStyled = styled(Th)`
  height: 40px;
  :first-of-type {
    padding-left: 33px;
  }
`;

const TdStyled = styled(Td)`
  padding-top: 10px;
  vertical-align: top;

  :first-of-type {
    padding-left: 5px;
  }
`;

const BoxInquilino = styled(Box)`
  display: flex;

  div:first-of-type {
    flex-grow: 1;
  }
`;

type InquilinoState = {
  isEditing?: boolean;
  salvandoInquilino?: boolean;
  excluindoInquilino?: boolean;
  registrandoSaidaInquilino?: boolean;
  error?: string;
  showInformacoes: Array<string>;
  dataEntrada?: string;
  dataSaida?: string;
  inquilinos: InquilinoProps[];
};
type Action =
  | { type: 'addInquilino' }
  | { type: 'cancelarEdicao' }
  | { type: 'initSalvarInquilino' }
  | { type: 'successSalvarInquilino'; payload: InquilinoProps }
  | { type: 'errorSalvarInquilino'; payload: string }
  | { type: 'initExcluirInquilino'; payload: string }
  | { type: 'successExcluirInquilino' }
  | { type: 'errorExcluirInquilino'; payload: string }
  | { type: 'showInformacoesInquilino'; payload: string }
  | { type: 'hideInformacoesInquilino'; payload: string }
  | {
      type: 'initRegistrarSaidaInquilino';
      payload: { dataEntrada: string; dataSaida: string };
    }
  | { type: 'successRegistrarSaidaInquilino' }
  | { type: 'errorRegistrarSaidaInquilino'; payload: string };

function removeInquilinoDaLista({
  inquilinos,
  dataEntrada
}: {
  inquilinos: InquilinoProps[];
  dataEntrada: string;
}) {
  const sizeInquilinos = size(inquilinos);

  if (isEqual(sizeInquilinos)(1)) {
    return [] as InquilinoProps[];
  } else if (gt(sizeInquilinos)(1)) {
    const inquilinoExcluido = find(inquilinos, ['entrada', dataEntrada]);
    const listaAtualizada = without(inquilinos, inquilinoExcluido);

    return listaAtualizada;
  }

  return inquilinos;
}

function atualizaDataSaidaInquilino({
  inquilinos,
  dataEntrada,
  dataSaida
}: {
  inquilinos: InquilinoProps[];
  dataEntrada: string;
  dataSaida: string;
}) {
  if (isEmpty(inquilinos)) {
    return inquilinos;
  }

  const indexInquilino = findIndex(inquilinos, ['entrada', dataEntrada]);

  if (!isEqual(-1)(indexInquilino)) {
    const inquilinosAtualizados = [...inquilinos];

    inquilinosAtualizados[indexInquilino].saida = dataSaida;

    return inquilinosAtualizados;
  }

  return inquilinos;
}

function reducerInquilino(
  state: InquilinoState,
  action: Action
): InquilinoState {
  switch (action.type) {
    case 'addInquilino':
      return {
        ...state,
        isEditing: true
      };

    case 'cancelarEdicao':
      return {
        ...state,
        isEditing: false
      };

    case 'initSalvarInquilino':
      return {
        ...state,
        error: undefined,
        salvandoInquilino: true
      };

    case 'successSalvarInquilino':
      return {
        ...state,
        salvandoInquilino: false,
        isEditing: false,
        inquilinos: [action.payload, ...state.inquilinos]
      };

    case 'errorSalvarInquilino':
      return {
        ...state,
        salvandoInquilino: false,
        error: action.payload
      };

    case 'initExcluirInquilino':
      return {
        ...state,
        error: undefined,
        excluindoInquilino: true,
        dataEntrada: action.payload
      };

    case 'successExcluirInquilino':
      return {
        ...state,
        excluindoInquilino: false,
        dataEntrada: undefined,
        // @ts-ignore
        inquilinos: removeInquilinoDaLista({
          inquilinos: state.inquilinos,
          dataEntrada: state.dataEntrada || ''
        })
      };

    case 'errorExcluirInquilino':
      return {
        ...state,
        excluindoInquilino: false,
        error: action.payload,
        dataEntrada: undefined
      };

    case 'initRegistrarSaidaInquilino':
      return {
        ...state,
        error: undefined,
        registrandoSaidaInquilino: true,
        dataEntrada: action.payload.dataEntrada,
        dataSaida: action.payload.dataSaida
      };

    case 'successRegistrarSaidaInquilino':
      return {
        ...state,
        registrandoSaidaInquilino: false,
        inquilinos: atualizaDataSaidaInquilino({
          inquilinos: state.inquilinos,
          dataEntrada: state.dataEntrada || '',
          dataSaida: state.dataSaida || ''
        })
      };

    case 'errorRegistrarSaidaInquilino':
      return {
        ...state,
        registrandoSaidaInquilino: false,
        error: action.payload
      };

    case 'showInformacoesInquilino':
      return {
        ...state,
        showInformacoes: union(state.showInformacoes, [action.payload])
      };

    case 'hideInformacoesInquilino':
      return {
        ...state,
        showInformacoes: without(state.showInformacoes, action.payload)
      };

    default:
      return state;
  }
}

function InquilinoNovo(acoes: AcoesProps) {
  return (
    <Tr>
      <TdStyled style={{ paddingLeft: '33px' }}>
        <NomeCompletoInquilino name="nomeInquilino" />
        <Flex justifyContent="space-between" padding="15px 0 20px">
          <IdentidadeInquilino name="identidadeInquilino" />
        </Flex>
      </TdStyled>
      <TdStyled>
        <DataDeEntrada name="entradaInquilino" />
      </TdStyled>
      <TdStyled></TdStyled>
      <TdStyled>
        <Acoes {...acoes} />
      </TdStyled>
    </Tr>
  );
}

interface InquilinoProps {
  identidade: string;
  tipo: string;
  entrada: string;
  nome: string;
  saida?: string;
}

interface InquilinoActions {
  isFirst?: boolean;
  isShow?: boolean;
  isExcluindo?: boolean;
  isRegistrandoSaida?: boolean;
  handleShowInquilino?: () => void;
  excluirInquilino?: () => void;
  registrarSaidaInquilino?: (dataSaida: string) => void;
}

function InquilinoCadastrado({
  identidade,
  tipo,
  entrada,
  nome,
  saida,
  isShow,
  handleShowInquilino,
  excluirInquilino,
  isExcluindo,
  registrarSaidaInquilino,
  isRegistrandoSaida,
  isFirst
}: InquilinoProps & InquilinoActions) {
  const label = isShow ? 'Esconder informações' : 'Exibir mais informações';
  return (
    <Tr>
      <TdStyled>
        <BoxInquilino>
          <Tooltip label={label} hasArrow placement="top" aria-label={label}>
            <IconButton
              onClick={handleShowInquilino}
              alignSelf="baseline"
              marginRight="5px"
              variantColor="gray"
              aria-label={label}
              icon={isShow ? 'chevron-up' : 'chevron-down'}
              size="sm"
              variant="ghost"
              fontSize="1.2rem"
              height="1.5rem"
              minWidth="1.5rem"
            />
          </Tooltip>
          <Collapse startingHeight={20} isOpen={isShow} flexGrow={1}>
            <Box marginTop="2px">{nome}</Box>
            <Flex justifyContent="space-between" padding="15px 0 20px">
              <Flex flexDirection="column">
                <FormLabel
                  fontSize="11px"
                  color="#7e8a96"
                  textTransform="uppercase"
                >
                  CPF/CNPJ
                </FormLabel>
                <span>{identidade}</span>
              </Flex>
              <FormControl>
                <FormLabel
                  fontSize="11px"
                  color="#7e8a96"
                  textTransform="uppercase"
                  htmlFor="tipoInquilinoCadastrado"
                >
                  Pessoa
                </FormLabel>
                <RadioGroup isInline spacing={4} value={tipo}>
                  <Radio
                    value="FISICA"
                    size="sm"
                    isDisabled={'FISICA' !== tipo}
                  >
                    Física
                  </Radio>
                  <Radio
                    value="JURIDICA"
                    size="sm"
                    isDisabled={'JURIDICA' !== tipo}
                  >
                    Jurídica
                  </Radio>
                </RadioGroup>
              </FormControl>
            </Flex>
          </Collapse>
        </BoxInquilino>
      </TdStyled>
      <TdStyled style={{ paddingTop: '15px' }}>
        {formatarData({ data: entrada })}
      </TdStyled>
      <TdStyled style={{ paddingTop: '15px' }}>
        {formatarData({ data: saida })}
      </TdStyled>
      <TdStyled>
        {isFirst && (
          <ExcluirInquilinoButton
            onClick={excluirInquilino}
            isProcessing={isExcluindo}
          />
        )}
        {isFirst && isEmpty(saida) && (
          <RegistrarSaidaButton
            onClick={registrarSaidaInquilino}
            isProcessing={isRegistrandoSaida}
          />
        )}
      </TdStyled>
    </Tr>
  );
}

export default function Inquilino() {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const {
    values,
    setFieldError,
    setFieldValue,
    setFieldTouched
  }: {
    values: any;
    setFieldError: (field: string, errorMsg: string) => void;
    setFieldValue: (field: string, value: any, shoudValidate?: boolean) => void;
    setFieldTouched: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void;
  } = useFormikContext();

  const [state, dispatch] = useReducer(reducerInquilino, {
    showInformacoes: [],
    inquilinos: values.inquilinos
  });

  const toast = useToast();

  const onError = useCallback(
    mensagem =>
      toast({
        title: 'Erro ao adicionar inquilino',
        description: mensagem,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      }),
    [toast]
  );

  const handleShowInformacoesInquilino = useCallback(
    (idInquilino: string, isShow: boolean) => {
      if (isShow) {
        dispatch({ type: 'hideInformacoesInquilino', payload: idInquilino });
      } else {
        dispatch({ type: 'showInformacoesInquilino', payload: idInquilino });
      }
    },
    []
  );

  const adicionarInquilino = useCallback(() => {
    dispatch({ type: 'addInquilino' });
  }, []);

  const resetFields = useCallback(
    (fields = ['nomeInquilino', 'identidadeInquilino', 'entradaInquilino']) => {
      fields.forEach((fieldName: string) => {
        setFieldValue(fieldName, '', false);
        setFieldTouched(fieldName, false, false);
      });
    },
    [setFieldValue, setFieldTouched]
  );

  const cancelarEdicao = useCallback(() => {
    resetFields();

    dispatch({ type: 'cancelarEdicao' });
  }, [resetFields]);

  const salvarInquilino = useCallback(() => {
    const isEmptyNomeInquilino = isEmpty(values.nomeInquilino);
    const isEmptyIdentidadeInquilino = isEmpty(values.identidadeInquilino);
    const isInvalidFormatIdentidadeInquilino = !/^\d{11}$|^\d{14}$/.test(
      values.identidadeInquilino
    );
    const isEmptyEntradaInquilino = isEmpty(values.entradaInquilino);

    if (
      !isEmptyNomeInquilino &&
      !isEmptyIdentidadeInquilino &&
      !isInvalidFormatIdentidadeInquilino &&
      !isEmptyEntradaInquilino
    ) {
      dispatch({ type: 'initSalvarInquilino' });
    } else {
      isEmptyNomeInquilino &&
        setFieldTouched('entradaInquilino', true, false) &&
        setFieldError('nomeInquilino', 'Campo obrigatório');

      isInvalidFormatIdentidadeInquilino &&
        setFieldTouched('identidadeInquilino', true, false);
      setFieldError('identidadeInquilino', 'Formato inválido');

      isEmptyIdentidadeInquilino &&
        setFieldTouched('identidadeInquilino', true, false) &&
        setFieldError('identidadeInquilino', 'Campo obrigatório');

      isEmptyEntradaInquilino &&
        setFieldTouched('entradaInquilino', true, false) &&
        setFieldError('entradaInquilino', 'Campo obrigatório');
    }
  }, [
    values.nomeInquilino,
    values.identidadeInquilino,
    values.entradaInquilino,
    setFieldError,
    setFieldTouched
  ]);

  const excluirInquilino = useCallback((dataEntrada: string) => {
    dispatch({ type: 'initExcluirInquilino', payload: dataEntrada });
  }, []);

  const registrarSaidaInquilino = useCallback(
    (input: { dataEntrada: string; dataSaida: string }) => {
      dispatch({ type: 'initRegistrarSaidaInquilino', payload: input });
    },
    []
  );

  useEffect(() => {
    let didCancel = false;

    if (state.salvandoInquilino) {
      const data = {
        nome: values.nomeInquilino,
        identidade: values.identidadeInquilino,
        entrada: values.entradaInquilino
      };

      postSalvarInquilino({ unidadeId: values.id, data })
        .then(() => {
          const dataInquilino = {
            ...data,
            tipo: 'FISICA',
            saida: ''
          };

          if (!didCancel) {
            dispatch({
              type: 'successSalvarInquilino',
              payload: dataInquilino
            });
            resetFields();
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'errorSalvarInquilino',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'errorSalvarInquilino',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'errorSalvarInquilino',
                  payload: mensagemDeErro
                })
            });
          }
        });
    }

    return () => {
      didCancel = true;
    };
  }, [
    state.salvandoInquilino,
    values.id,
    values.nomeInquilino,
    values.identidadeInquilino,
    values.entradaInquilino,
    desconectarUsuario,
    resetFields
  ]);

  useEffect(() => {
    let didCancel = false;

    if (state.excluindoInquilino) {
      deleteInquilino({
        unidadeId: values.id,
        dataEntrada: state.dataEntrada || ''
      })
        .then(() => {
          if (!didCancel) {
            dispatch({ type: 'successExcluirInquilino' });
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'errorExcluirInquilino',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'errorExcluirInquilino',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'errorExcluirInquilino',
                  payload: mensagemDeErro
                })
            });
          }
        });
    }

    return () => {
      didCancel = true;
    };
  }, [
    state.excluindoInquilino,
    values.id,
    state.dataEntrada,
    desconectarUsuario
  ]);

  useEffect(() => {
    let didCancel = false;

    if (state.registrandoSaidaInquilino) {
      postRegistrarSaidaInquilino({
        unidadeId: values.id,
        dataEntrada: state.dataEntrada || '',
        data: { saida: state.dataSaida || '' }
      })
        .then(() => {
          if (!didCancel) {
            dispatch({ type: 'successRegistrarSaidaInquilino' });
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'errorRegistrarSaidaInquilino',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'errorRegistrarSaidaInquilino',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'errorRegistrarSaidaInquilino',
                  payload: mensagemDeErro
                })
            });
          }
        });
    }

    return () => {
      didCancel = true;
    };
  }, [
    state.registrandoSaidaInquilino,
    values.id,
    state.dataEntrada,
    state.dataSaida,
    desconectarUsuario
  ]);

  useEffect(() => {
    if (state.error) {
      onError(state.error);
    }
  }, [state.error, onError]);

  const inquilinosCadastrados = useCallback(
    (inquilinos: Array<InquilinoProps>) => {
      const firstInquilino = first(inquilinos);
      const idFirstInquilino =
        firstInquilino &&
        `${firstInquilino.identidade}${firstInquilino.entrada}`;

      const isShow = compose(
        negate(isEqual(-1)),
        partial(indexOf, [state.showInformacoes])
      );

      return (
        <>
          {firstInquilino && (
            <InquilinoCadastrado
              isFirst
              isShow={isShow(idFirstInquilino)}
              handleShowInquilino={() =>
                idFirstInquilino &&
                handleShowInformacoesInquilino(
                  idFirstInquilino,
                  isShow(idFirstInquilino)
                )
              }
              isExcluindo={state.excluindoInquilino}
              excluirInquilino={() => excluirInquilino(firstInquilino.entrada)}
              isRegistrandoSaida={state.registrandoSaidaInquilino}
              registrarSaidaInquilino={(dataSaida: string) =>
                registrarSaidaInquilino({
                  dataEntrada: firstInquilino.entrada,
                  dataSaida
                })
              }
              {...firstInquilino}
            />
          )}
          {map((inquilino: InquilinoProps) => {
            const idInquilino = `${inquilino.identidade}${inquilino.entrada}`;

            return (
              <InquilinoCadastrado
                key={idInquilino}
                isShow={isShow(idInquilino)}
                handleShowInquilino={() =>
                  handleShowInformacoesInquilino(
                    idInquilino,
                    isShow(idInquilino)
                  )
                }
                {...inquilino}
              />
            );
          }, tail(inquilinos))}
        </>
      );
    },
    [
      handleShowInformacoesInquilino,
      state.showInformacoes,
      excluirInquilino,
      state.excluindoInquilino,
      state.registrandoSaidaInquilino,
      registrarSaidaInquilino
    ]
  );

  const inquilinosCadastradosMemoized = useMemo(
    () => inquilinosCadastrados(state.inquilinos),
    [state.inquilinos, inquilinosCadastrados]
  );

  return (
    <GrupoDeCampos titulo="Inquilino">
      <Button
        type="button"
        variant="outline"
        variantColor="blue"
        width="180px"
        marginTop="12px"
        onClick={adicionarInquilino}
        isDisabled={state.isEditing}
      >
        Adicionar inquilino
      </Button>
      <Table style={{ border: '1px solid #e8e8e8' }}>
        <Thead style={{ boxShadow: 'none', borderBottom: '1px solid #e8e8e8' }}>
          <tr>
            <ThStyled style={{ width: '428px' }}>Nome</ThStyled>
            <ThStyled style={{ width: '200px' }}>Entrada</ThStyled>
            <ThStyled style={{ width: '200px' }}>Saída</ThStyled>
            <ThStyled style={{ width: '100px' }}></ThStyled>
          </tr>
        </Thead>
        <tbody>
          {state.isEditing && (
            <InquilinoNovo
              isProcessing={state.salvandoInquilino}
              salvarInquilino={salvarInquilino}
              cancelarEdicao={cancelarEdicao}
            />
          )}
          {inquilinosCadastradosMemoized}
        </tbody>
      </Table>
    </GrupoDeCampos>
  );
}
