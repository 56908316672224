/**
 * Dependências externas
 */
import React from 'react';
import { Flex, Select, Button, FormControl, FormLabel } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import AreaPrincipal from 'apresentacao/AreaPrincipal';
import ConteudoDaPagina from 'apresentacao/ConteudoDaPagina';
import rota from 'constants/rota';
import BarraSuperior from 'apresentacao/BarraSuperior';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';
import Mensagem from 'apresentacao/Mensagem';
import useDownloadFileFromUrl from 'hooks/useDownloadFileFromUrl';

export default function Inadimplencia() {
  const desconectarUsuario = React.useContext(AutenticacaoContext);
  const { downloadState, downloadFile } = useDownloadFileFromUrl();

  const [inputAgruparPor, setInputAgruparPor] = React.useState(
    'sumarioInadimplenciaUnidade'
  );

  const [error, setError] = React.useState('');
  const limparError = React.useCallback(() => {
    setError('');
  }, []);

  const downloadRelatorio = React.useCallback(() => {
    limparError();
    downloadFile(
      `relatorios/${inputAgruparPor}`,
      `relatorio-inadimplencia-por-${
        'sumarioInadimplenciaUnidade' === inputAgruparPor
          ? 'unidade'
          : 'competencia'
      }.pdf`
    );
  }, [inputAgruparPor, downloadFile, limparError]);

  React.useEffect(() => {
    if (downloadState.error) {
      handleHTTPError({
        error: downloadState.error,
        handle400Error: setError,
        handle401Error: desconectarUsuario,
        handle403Error: setError,
        handleGenericError: setError
      });
    }
  }, [downloadState.error, desconectarUsuario]);

  return (
    <AreaPrincipal>
      <BarraSuperior titulo={rota.inadimplencia.titulo} />
      <ConteudoDaPagina>
        {!!error && (
          <>
            <Mensagem
              tipo="error"
              fechar={limparError}
              marginBottom="5"
              width={['100%', '100%', '100%', '60%']}
            >
              {error}
            </Mensagem>
          </>
        )}

        <Flex alignItems="flex-end">
          <FormControl>
            <FormLabel htmlFor="agruparPor">Agrupar por</FormLabel>
            <Select
              id="agruparPor"
              width="170px"
              value={inputAgruparPor}
              onChange={event => setInputAgruparPor(event.target.value)}
              isDisabled={downloadState.baixando}
            >
              <option value="sumarioInadimplenciaUnidade">Unidade</option>
              <option value="sumarioInadimplenciaCompetencia">
                Competência
              </option>
            </Select>
          </FormControl>
          <Button
            onClick={downloadRelatorio}
            isDisabled={downloadState.baixando}
            isLoading={downloadState.baixando}
            variantColor="blue"
            marginLeft="5"
          >
            Gerar relatório
          </Button>
        </Flex>
      </ConteudoDaPagina>
    </AreaPrincipal>
  );
}
