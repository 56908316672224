/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Radio
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';

const TipoPessoa = props => {
  const { isDisabled, formik, name, ...rest } = props;

  const nomeDoCampo = name;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={possuiErroDeValidacao}
      {...rest}
    >
      <FormLabel htmlFor={nomeDoCampo} marginBottom="2">
        Pessoa
      </FormLabel>
      <RadioGroup
        isInline
        spacing={5}
        value={valor}
        onChange={handleChange}
        onBlur={handleBlur}
        name={nomeDoCampo}
      >
        <Radio
          value="FISICA"
          size="md"
          isDisabled={'FISICA' !== valor && isDisabled}
        >
          Física
        </Radio>
        <Radio value="JURIDICA" isDisabled={'JURIDICA' !== valor && isDisabled}>
          Jurídica
        </Radio>
      </RadioGroup>
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(TipoPessoa);
