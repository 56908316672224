/**
 * Dependências externas
 */
import React, { useState } from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  IconButton
} from '@chakra-ui/core';
import { MarginProps } from 'styled-system';

const Senha: React.FC<MarginProps> = props => {
  const nomeDoCampo = 'senha';

  const [field, meta] = useField(nomeDoCampo);

  const valor = field.value;
  const erro = meta.error;
  const touched = meta.touched;

  const possuiErroDeValidacao = !!erro && touched;

  const [exibirSenha, setExibirSenha] = useState(false);
  const handleClickBotaoExibirSenha = () => setExibirSenha(!exibirSenha);

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...props}>
      <FormLabel htmlFor="senha">Senha</FormLabel>
      <InputGroup>
        <Input
          pr="2.5rem"
          type={exibirSenha ? 'text' : 'password'}
          id={nomeDoCampo}
          name={nomeDoCampo}
          onChange={field.onChange}
          value={valor}
        />
        <InputRightElement width="2.5rem">
          <Tooltip
            aria-label={exibirSenha ? 'Esconder senha' : 'Exibir senha'}
            hasArrow
            label={exibirSenha ? 'Esconder' : 'Exibir'}
            placement="top"
          >
            <IconButton
              aria-label={exibirSenha ? 'Esconder senha' : 'Exibir senha'}
              icon={exibirSenha ? 'view' : 'view-off'}
              fontSize="18px"
              onClick={handleClickBotaoExibirSenha}
              variant="ghost"
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>

      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default Senha;
