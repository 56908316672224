/**
 * Dependências externas
 */
import React from 'react';
import { Tooltip, IconButton } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import CancelarButton from './CancelarButton';

/**
 * Types
 */
import AcoesProps from '../types/Acoes';

type SalvarInquilinoButtonProps = {
  onClick: () => void;
  isProcessing?: boolean;
};

function SalvarInquilinoButton({
  onClick,
  isProcessing
}: SalvarInquilinoButtonProps) {
  return (
    <Tooltip
      label="Salvar inquilino"
      hasArrow
      placement="top"
      aria-label="Salvar inquilino"
    >
      <IconButton
        variant="ghost"
        variantColor="blue"
        aria-label="Salvar inquilino"
        icon="check"
        size="sm"
        onClick={onClick}
        isLoading={isProcessing}
        isDisabled={isProcessing}
      />
    </Tooltip>
  );
}

export default function Acoes({
  isEditing,
  salvarInquilino,
  cancelarEdicao,
  isProcessing
}: AcoesProps) {
  return (
    <>
      <SalvarInquilinoButton
        isProcessing={isProcessing}
        onClick={salvarInquilino}
      />
      <CancelarButton isProcessing={isProcessing} onClick={cancelarEdicao} />
    </>
  );
}
