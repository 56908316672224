/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/core';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import validaCampoInquilino from 'paginas/Unidades/apresentacao/Form/campos/inquilino/utils/validaCampoInquilino';

const DataDeEntrada = props => {
  const { formik, name, ...rest } = props;

  const nomeDoCampo = name;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao, touched } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const erroDeValidacao = validaCampoInquilino({
    possuiErroDeValidacao,
    touched,
    valor
  });

  return (
    <FormControl width="170px" isInvalid={erroDeValidacao} {...rest}>
      <Input
        type="date"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
        aria-label="Data de entrada"
        paddingRight="0"
        height="2rem"
        lineHeight="2rem"
        paddingLeft="0.5rem"
      />
      {erroDeValidacao && (
        <FormErrorMessage>{erro || 'Campo obrigatório'}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default connectToFormik(DataDeEntrada);
