/**
 * Dependências externas
 */
import axios from 'axios';

/**
 * Dependências internas
 */
import apiURL from 'constants/apiURL';

const api = axios.create( {
  baseURL: apiURL
} );

export default api;
