/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import replace from 'lodash/replace';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  Input,
  InputRightElement,
  Flex,
  Icon,
  Button
} from '@chakra-ui/core';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import CarregandoInput from 'apresentacao/form/CarregandoInput';

const CEP = props => {
  const {
    cobranca,
    formik,
    width,
    setRecuperandoInformacoesDoCEP,
    isLoading,
    erroRecuperarCEP,
    ...rest
  } = props;

  const nomeDoCampo = cobranca ? 'cepCobranca' : 'cep';

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const valorFormatado = replace(valor, /[^0-9]/g, '');

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>CEP</FormLabel>
      <Flex align="center">
        <Flex width={width}>
          <InputGroup>
            <Input
              type="text"
              id={nomeDoCampo}
              name={nomeDoCampo}
              onChange={handleChange}
              onBlur={handleBlur}
              value={valorFormatado}
              autoComplete="off"
              isDisabled={isLoading || rest.isDisabled}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  setRecuperandoInformacoesDoCEP(true);
                }
              }}
            />
            <InputRightElement>
              {isLoading && <CarregandoInput />}
            </InputRightElement>
          </InputGroup>
        </Flex>
        {cobranca && (
          <Flex>
            <Button
              variant="outline"
              marginLeft="5px"
              type="button"
              onClick={() => setRecuperandoInformacoesDoCEP(true)}
              isLoading={isLoading}
            >
              Pesquisar
            </Button>
          </Flex>
        )}
        {erroRecuperarCEP && !isLoading && (
          <Flex>
            <Icon
              name="warning"
              color="orange.600"
              marginLeft="3"
              marginRight="1"
              size="14px"
            />
            <FormHelperText color="orange.600" marginTop="0">
              {404 === erroRecuperarCEP
                ? 'CEP não encontrado'
                : 'Não foi possível realizar a pesquisa'}
            </FormHelperText>
          </Flex>
        )}
      </Flex>

      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(CEP);
