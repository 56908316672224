/**
 * Dependências externas
 */
import styled from '@emotion/styled';

/*
 * Dependências internas
 */
import layout from '../constants/layout';

const AreaPrincipal = styled.div`
  margin-left: ${layout.larguraBarraLateral};
  background-color: #fafbfc;
  min-height: 100%;
  padding-bottom: 50px;
`;

export default AreaPrincipal;
