/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText,
  Flex
} from '@chakra-ui/core';
import replace from 'lodash/replace';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import validaCampoInquilino from 'paginas/Unidades/apresentacao/Form/campos/inquilino/utils/validaCampoInquilino';

const IdentidadeInquilino = props => {
  const { formik, name, ...rest } = props;

  const nomeDoCampo = name;
  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao, touched } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const valorFormatado = replace(valor, /[^0-9]/g, '');

  const erroDeValidacao =
    (!!valorFormatado && !/^\d{11}$|^\d{14}$/.test(valorFormatado)) ||
    validaCampoInquilino({
      possuiErroDeValidacao,
      touched,
      valor
    });

  return (
    <FormControl isInvalid={erroDeValidacao} {...rest}>
      <Flex alignItems="baseline">
        <FormLabel
          fontSize="11px"
          color="#7e8a96"
          textTransform="uppercase"
          htmlFor={nomeDoCampo}
        >
          CPF/CNPJ
        </FormLabel>
        <FormHelperText color="#7e8a96" fontSize="12px" marginTop="0">
          digite apenas números
        </FormHelperText>
      </Flex>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valorFormatado}
        autoComplete="off"
        height="2rem"
        lineHeight="2rem"
        paddingX="0.5rem"
      />
      {erroDeValidacao && !valorFormatado && (
        <FormErrorMessage>{erro || 'Campo obrigatório'}</FormErrorMessage>
      )}
      {erroDeValidacao && !!valorFormatado && (
        <FormErrorMessage>{erro || 'Formato inválido'}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default connectToFormik(IdentidadeInquilino);
