/**
 * Dependências externas
 */
import React from 'react';
import get from 'lodash/get';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  FormHelperText
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import formatarMoeda from 'paginas/utils/formatarMoeda';

const Valor = props => {
  const nomeDoCampo = 'valor';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const valor = replace(
    get(props, `formik.values[${nomeDoCampo}]`),
    /[^0-9]/g,
    ''
  );
  const erro = get(props, `formik.errors[${nomeDoCampo}]`);
  const touched = get(props, `formik.touched[${nomeDoCampo}]`);

  const possuiErroDeValidacao = erro && touched;

  const valorFormatado = isEmpty(valor) ? valor : formatarMoeda(valor);

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo} paddingBottom="0">
        Valor
      </FormLabel>
      <FormHelperText marginTop="0" paddingBottom="4px" marginBottom="6px">
        Digite apenas números
      </FormHelperText>
      <Input
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valorFormatado}
        textAlign="right"
        autoComplete="off"
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(Valor);
