/**
 * Dependências externas
 */
import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import Form from './Form';

/**
 * Types
 */
import { LoginProps } from './login';

function Login(props: LoginProps) {
  return (
    <Flex flexDirection="column" justifyContent="center" height="100%">
      <div>
        <Heading as="h1" size="lg" textAlign="center" mb={3}>
          Condomínio Webit
        </Heading>
        <Box
          p={5}
          shadow="md"
          borderWidth="1px"
          w="22rem"
          style={{ margin: '0 auto' }}
        >
          <Form {...props} />
        </Box>
      </div>
    </Flex>
  );
}

export default Login;
