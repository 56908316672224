/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import AreaPrincipal from 'apresentacao/AreaPrincipal';
import BarraSuperiorArquivosRemessa from './apresentacao/BarraSuperiorArquivosRemessa';
import ConteudoDaPagina from 'apresentacao/ConteudoDaPagina';
import rota from 'constants/rota';
import TodosArquivosRemessa from './TodosArquivosRemessa';

function ArquivosRemessa(props: RouteComponentProps) {
  const path = get(props, 'match.path');

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorArquivosRemessa
          path={path}
          titulo={rota.arquivosRemessa.titulo}
        />

        <ConteudoDaPagina>
          <Switch>
            <Route
              path={`${path}/todos`}
              exact
              component={TodosArquivosRemessa}
            />

            <Redirect to={`${path}/todos`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
}

export default ArquivosRemessa;
