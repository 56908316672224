/**
 * Dependências externas
 */
import React from 'react';
import { Text, Icon } from '@chakra-ui/core';

const NenhumRegistro: React.FC<{}> = props => (
  <Text
    color="gray.400"
    textAlign="center"
    fontSize="2xl"
    marginTop="50px"
    {...props}
  >
    <Icon name="warning-2" size="40px" color="gray.300" />
    <br />
    Nenhum registro
  </Text>
);

export default NenhumRegistro;
