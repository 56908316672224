/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  Switch,
  FormLabel
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';

const Periodico = props => {
  const nomeDoCampo = 'periodico';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const { valor = false, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Periódico</FormLabel>
      <br />
      <Switch
        id={nomeDoCampo}
        value={valor}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={rest.isDisabled}
      />{' '}
      {valor ? 'Sim' : 'Não'}
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(Periodico);
