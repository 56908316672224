/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependências internas
 */
import BarraSuperior, { Link } from '../../../apresentacao/BarraSuperior';

const BarraSuperiorTiposDeLancamento = (props) => {
  const {path, titulo} = props;

  return (
    <BarraSuperior titulo={titulo}>
      <Link to={`${path}/todos`} activeClassName="selected">
        Ver todos
      </Link>

      <Link to={`${path}/novo`} activeClassName="selected">
        Adicionar novo
      </Link>
    </BarraSuperior>
  );
};

export default BarraSuperiorTiposDeLancamento;
