/**
 * Dependências externas
 */
import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import partial from 'lodash/fp/partial';
import { withFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Box,
  Flex,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  FormLabel
} from '@chakra-ui/core';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate
} from 'react-day-picker/moment';
import styled from '@emotion/styled';

/**
 * Dependências internas
 */
import api from 'utils/api';
import apiURL from 'constants/apiURL';
import rota from 'constants/rota';
import useDataApi from 'hooks/useDataApi';
import useDownloadFileFromUrl from 'hooks/useDownloadFileFromUrl';
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import Mensagem from 'apresentacao/Mensagem';
import Carregando from 'apresentacao/Carregando';
import is404Error from 'paginas/utils/is404Error';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';
import formatarData from 'paginas/utils/formatarData';

/**
 * Campos do formulário
 */
import Identidade from './campos/Identidade';
import NomeCompleto from './campos/NomeCompleto';
import GrupoDeCamposEndereco from './campos/endereco/GrupoDeCamposEndereco';
import TipoPessoa from './campos/TipoPessoa';
import Inquilino from './campos/inquilino/Inquilino';
import InformacoesAdicionais from './campos/InformacoesAdicionais';

const DatePickerInput = styled(DayPickerInput)`
  .DayPickerInput-OverlayWrapper {
    background: red;
    border-radius: 0 !important;
  }
`;

const validacaoCampos = yup.object().shape({
  fracaoIdeal: yup.string().required('Campo obrigatório'),
  nomeProprietario: yup.string().required('Campo obrigatório'),
  identidadeProprietario: yup
    .string()
    .matches(/^\d{11}$|^\d{14}$/, 'Formato inválido')
    .required('Campo obrigatório'),
  emailCobranca: yup.string().email('Digite um e-mail válido')
});

function atualizarUnidade({ id, data }) {
  return api.put(`unidades/${id}`, data);
}

const getFromDados = (obj, propName, defaultValue = '') =>
  get(obj, `dados[${propName}]`, defaultValue);

const getFromProprietario = (obj, propName, defaultValue = '') =>
  get(obj, `dados.proprietario[${propName}]`, defaultValue);

const getFromEndereco = (obj, propName, defaultValue = '') =>
  get(obj, `dados.endereco[${propName}]`, defaultValue);

const getFromEnderecoCobranca = (obj, propName, defaultValue = '') =>
  get(obj, `dados.enderecoCobranca[${propName}]`, defaultValue);

const formLogica = {
  mapPropsToValues: props => {
    const getDados = partial(getFromDados, [props]);
    const getProprietario = partial(getFromProprietario, [props]);
    const getEndereco = partial(getFromEndereco, [props]);
    const getEnderecoCobranca = partial(getFromEnderecoCobranca, [props]);

    return {
      id: getDados('id'),
      contratoId: getDados('contratoId'),
      fracaoIdeal: getDados('fracaoIdeal'),
      nomeProprietario: getProprietario('nome'),
      identidadeProprietario: getProprietario('identidade'),
      tipoProprietario: getProprietario('tipo'),
      logradouro: getEndereco('logradouro'),
      localidade: getEndereco('localidade'),
      uf: getEndereco('uf'),
      cep: getEndereco('cep'),
      numero: getEndereco('numero'),
      complemento: getEndereco('complemento'),
      bairro: getEndereco('bairro'),
      logradouroCobranca: getEnderecoCobranca('logradouro'),
      localidadeCobranca: getEnderecoCobranca('localidade'),
      ufCobranca: getEnderecoCobranca('uf'),
      cepCobranca: getEnderecoCobranca('cep'),
      numeroCobranca: getEnderecoCobranca('numero'),
      complementoCobranca: getEnderecoCobranca('complemento'),
      bairroCobranca: getEnderecoCobranca('bairro'),
      nomeInquilino: '',
      identidadeInquilino: '',
      tipoInquilino: '',
      entradaInquilino: '',
      inquilinos: getDados('inquilinos'),
      emailCobranca: getDados('emailCobranca')
    };
  },
  validationSchema: validacaoCampos,
  handleSubmit: (value, { setStatus, props }) => {
    const numeroCobranca = Number(value.numeroCobranca);
    const data = {
      nome: value.nomeProprietario,
      identidade: value.identidadeProprietario,
      enderecoCobranca: {
        logradouro: value.logradouroCobranca,
        localidade: value.localidadeCobranca,
        uf: value.ufCobranca,
        cep: value.cepCobranca,
        numero: isNumber(numeroCobranca) ? numeroCobranca : null,
        complemento: value.complementoCobranca,
        bairro: value.bairroCobranca
      },
      emailCobranca: value.emailCobranca
    };

    const { desconectarUsuario } = props;

    return atualizarUnidade({ id: value.id, data })
      .then(() => {
        setStatus({
          tipo: 'success',
          mensagem: `${rota.unidades.tituloSingular} salva com sucesso`
        });
      })
      .catch(error => {
        const setError = mensagemDeErro =>
          setStatus({
            tipo: 'error',
            mensagem: mensagemDeErro
          });

        handleHTTPError({
          error,
          handle400Error: setError,
          handle401Error: desconectarUsuario,
          handle403Error: setError,
          handleGenericError: setError
        });
      });
  },
  displayName: 'LancamentoForm'
};

const FormApresentacao = props => {
  const {
    handleSubmit,
    isSubmitting,
    status,
    setStatus,
    values,
    desconectarUsuario
  } = props;

  const possuiMensagem = !isEmpty(status);

  const history = useHistory();

  const toast = useToast();

  const showToast = React.useCallback(
    status =>
      toast({
        title: 'error' === status.tipo ? 'Erro ao atualizar' : undefined,
        description: status.mensagem,
        status: status.tipo,
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      }),
    [toast]
  );

  React.useEffect(() => {
    if (possuiMensagem) {
      showToast(status);
    }
  }, [possuiMensagem, status, showToast]);

  const setMessage = React.useCallback(
    ({ type, text }) => {
      setStatus({
        tipo: type,
        mensagem: text
      });
    },
    [setStatus]
  );
  const clearMessage = React.useCallback(() => {
    if (possuiMensagem) {
      setStatus(null);
    }
  }, [setStatus, possuiMensagem]);

  return (
    <>
      <BotaoFaturasEmAtraso
        setMessage={setMessage}
        clearMessage={clearMessage}
        desconectarUsuario={desconectarUsuario}
      />

      <BotaoCartaCobranca
        setMessage={setMessage}
        clearMessage={clearMessage}
        desconectarUsuario={desconectarUsuario}
      />

      {possuiMensagem && (
        <Mensagem
          tipo={status.tipo}
          fechar={() => setStatus(undefined)}
          mb="1.25rem"
          width={['100%', '100%', '100%', '90%']}
        >
          {status.mensagem}
        </Mensagem>
      )}
      <Box
        padding="0 1.25rem 1.25rem"
        shadow="md"
        borderWidth="1px"
        backgroundColor="#fff"
        width={['100%', '100%', '100%', '90%']}
      >
        <form onSubmit={handleSubmit}>
          <GrupoDeCampos titulo="Proprietário">
            <NomeCompleto name="nomeProprietario" />
            <Flex alignItems="flex-start">
              <Identidade
                name="identidadeProprietario"
                width={['30%', '30%', '30%', '25%']}
              />
              <TipoPessoa isDisabled name="tipoProprietario" marginLeft="10" />
            </Flex>
          </GrupoDeCampos>

          <Inquilino />

          <GrupoDeCamposEndereco titulo="Endereço de cobrança" cobranca />

          <InformacoesAdicionais />

          <Button
            mt="2rem"
            variantColor="blue"
            type="submit"
            isLoading={isSubmitting}
          >
            {values.id ? 'Atualizar' : 'Salvar'}
          </Button>

          <Button
            ml={4}
            mt="2rem"
            variantColor="red"
            variant="outline"
            type="button"
            isDisabled={isSubmitting}
            onClick={() => history.push(`${rota.unidades.url}/todas`)}
          >
            Cancelar
          </Button>
        </form>
      </Box>
    </>
  );
};

const Formik = withFormik(formLogica)(FormApresentacao);

const Form = props => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const { novo } = props;

  const { idRegistro } = useParams();

  const { data, carregando, erro, setUrl } = useDataApi();

  useEffect(() => {
    if (idRegistro && !novo) {
      setUrl(`${apiURL}unidades/${idRegistro}`);
    }
  }, [idRegistro, setUrl, novo]);

  return (
    <>
      {erro &&
        (is404Error(erro) ? (
          <p>Não foi possível encontrar a unidade {idRegistro}.</p>
        ) : (
          <p>Ocorreu um erro.</p>
        ))}

      {!erro && carregando && <Carregando align="center" />}

      {!erro && !carregando && (!isUndefined(data) || novo) && (
        <Formik dados={data} desconectarUsuario={desconectarUsuario} />
      )}
    </>
  );
};

function BotaoFaturasEmAtraso({
  setMessage,
  clearMessage,
  desconectarUsuario
}) {
  const [dataReferencia, setDataReferencia] = React.useState(new Date());

  const { idRegistro } = useParams();

  const { downloadState, downloadFile } = useDownloadFileFromUrl();

  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = React.useCallback(() => setIsOpen(false), []);

  const downloadFaturasEmAtraso = React.useCallback(() => {
    clearMessage();
    downloadFile(
      `relatorios/valorPresenteDividaUnidade/${idRegistro}?referencia=${formatarData(
        { data: dataReferencia, formato: 'YYYY-MM-DD' }
      )}`,
      `relatorio-divida-unidade-${idRegistro}-${formatarData({
        data: dataReferencia,
        formato: 'DD-MM-YY'
      })}.pdf`
    );
  }, [downloadFile, idRegistro, dataReferencia, clearMessage]);

  React.useEffect(() => {
    if (downloadState.error) {
      handleHTTPError({
        error: downloadState.error,
        handle400Error: message => setMessage({ type: 'error', text: message }),
        handle401Error: desconectarUsuario,
        handle403Error: message => setMessage({ type: 'error', text: message }),
        handle404Error: () =>
          setMessage({
            type: 'warning',
            message: 'A unidade não possui faturas em atraso.'
          }),
        handleGenericError: message =>
          setMessage({ type: 'error', text: message })
      });
    }
  }, [downloadState.error, desconectarUsuario, setMessage]);

  return (
    <Popover placement="bottom" isOpen={isOpen} onOpen={open} onClose={close}>
      <PopoverTrigger>
        <Button
          leftIcon="relatorio"
          size="sm"
          variant="ghost"
          variantColor="blue"
          marginBottom="10px"
          isLoading={downloadState.baixando}
          isDisabled={downloadState.baixando}
          loadingText="Faturas em atraso"
        >
          Faturas em atraso
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex="10" p="4" width="200px">
        <PopoverArrow backgroundColor="white" />
        <PopoverCloseButton />
        <FormLabel fontSize="sm">Data de referência</FormLabel>
        <DatePickerInput
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale: 'pt-br',
            localeUtils: MomentLocaleUtils
          }}
          value={dataReferencia}
          onDayChange={setDataReferencia}
        />
        <Button
          onClick={() => {
            downloadFaturasEmAtraso();
            close();
          }}
          marginTop="5"
          size="xs"
          variantColor="blue"
        >
          Gerar relatório
        </Button>
      </PopoverContent>
    </Popover>
  );
}

function BotaoCartaCobranca({ setMessage, clearMessage, desconectarUsuario }) {
  const [dataReferencia, setDataReferencia] = React.useState(new Date());
  const { idRegistro } = useParams();
  const { downloadState, downloadFile } = useDownloadFileFromUrl();
  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);

  const downloadCartaCobranca = React.useCallback(() => {
    clearMessage();
    downloadFile(
      `relatorios/cartaCobranca/${idRegistro}?referencia=${formatarData({
        data: dataReferencia,
        formato: 'YYYY-MM-DD'
      })}`,
      `carta-cobranca-${idRegistro}-${formatarData({
        data: dataReferencia,
        formato: 'DD-MM-YY'
      })}.pdf`
    );
  }, [downloadFile, idRegistro, dataReferencia, clearMessage]);

  React.useEffect(() => {
    if (downloadState.error) {
      handleHTTPError({
        error: downloadState.error,
        handle400Error: message => setMessage({ type: 'error', text: message }),
        handle401Error: desconectarUsuario,
        handle403Error: message => setMessage({ type: 'error', text: message }),
        handle404Error: () =>
          setMessage({
            type: 'warning',
            message: 'A unidade não possui faturas em atraso.'
          }),
        handleGenericError: message =>
          setMessage({ type: 'error', text: message })
      });
    }
  }, [downloadState.error, desconectarUsuario, setMessage]);

  return (
    <Popover placement="bottom" isOpen={isOpen} onOpen={open} onClose={close}>
      <PopoverTrigger>
        <Button
          leftIcon="relatorio"
          size="sm"
          variant="ghost"
          variantColor="blue"
          marginBottom="10px"
          isLoading={downloadState.baixando}
          isDisabled={downloadState.baixando}
          loadingText="Carta Cobrança"
        >
          Carta Cobrança
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex="10" p="4" width="200px">
        <PopoverArrow backgroundColor="white" />
        <PopoverCloseButton />
        <FormLabel fontSize="sm">Data de referência</FormLabel>
        <DatePickerInput
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            locale: 'pt-br',
            localeUtils: MomentLocaleUtils
          }}
          value={dataReferencia}
          onDayChange={setDataReferencia}
        />
        <Button
          onClick={() => {
            downloadCartaCobranca();
            close();
          }}
          marginTop="5"
          size="xs"
          variantColor="blue"
        >
          Gerar
        </Button>
      </PopoverContent>
    </Popover>
  );
}

export default Form;
