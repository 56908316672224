/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/core';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import validaCampoInquilino from 'paginas/Unidades/apresentacao/Form/campos/inquilino/utils/validaCampoInquilino';

type Props = { name: string; formik?: any };

const NomeCompleto = (props: Props) => {
  const { formik, name, ...rest } = props;

  const nomeDoCampo = name;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao, touched } = getInformacoesDoCampo(
    { formik },
    nomeDoCampo
  );

  const erroDeValidacao = validaCampoInquilino({
    possuiErroDeValidacao,
    touched,
    valor
  });

  return (
    <FormControl isInvalid={erroDeValidacao} {...rest}>
      <Input
        autoFocus
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
        autoComplete="off"
        aria-label="Nome completo"
        height="2rem"
        lineHeight="2rem"
        paddingX="0.5rem"
      />
      {erroDeValidacao && (
        <FormErrorMessage>{erro || 'Campo obrigatório'}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default connectToFormik(NomeCompleto);
