/**
 * Dependências externas
 */
import moment from 'moment';

export default function formatarData({
  data,
  formato = 'DD/MM/YYYY'
}: {
  data?: string | Date;
  formato?: string;
}) {
  const momentData = moment(data);

  return momentData.isValid() && momentData.format(formato);
}
