/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependênicas internas
 */
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import NomeTitular from './NomeTitular';
import IdentidadeTitular from './IdentidadeTitular';
import TipoTitular from './TipoTitular';

const DadosDoTitular = () => {
  return (
    <GrupoDeCampos titulo="Titular">
      <NomeTitular isDisabled />

      <IdentidadeTitular isDisabled width={['35%', '35%', '35%', '30%']} />

      <TipoTitular isDisabled />
    </GrupoDeCampos>
  );
};

export default DadosDoTitular;
