/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import rota from '../../constants/rota';
import AreaPrincipal from '../../apresentacao/AreaPrincipal';
import BarraSuperiorLancamento from './apresentacao/BarraSuperiorLancamento';
import ConteudoDaPagina from '../../apresentacao/ConteudoDaPagina';
import Novo from './Novo';
import Todos from './TodosLancamentos';

const Lancamento = props => {
  const path = get( props, 'match.path' );

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorLancamento path={path} titulo={rota.lancamento.titulo} />

        <ConteudoDaPagina>
          <Switch>
            <Route path={`${path}/novo`} exact component={Novo} />

            <Route
              path={`${path}/todos`}
              exact
              render={() => <Todos />}
            />

            <Redirect to={`${path}/todos`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
};

export default Lancamento;
