/**
 * Dependências externas
 */
import React from 'react';
import styled from '@emotion/styled';
import gte from 'lodash/fp/gte';
import lte from 'lodash/fp/lte';
import { Button, Box } from '@chakra-ui/core';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';
import { FocusOn } from 'react-focus-on';

/**
 * Dependências internas
 */
import formatarData from 'paginas/utils/formatarData';
import moment from 'moment';
import { ActionDatePicker } from 'apresentacao/date/datePickerReducer';

const ButtoWithHover = styled(Button)`
  :hover {
    background-color: #ebedf0;
  }
`;

/**
 * Verifica se está selecionando o primeiro dia do intervalo.
 * @param {Date} from
 * @param {Date} to
 * @param {Date} day
 * @returns {boolean}
 */
function isSelectingFirstDay(from: Date | null, to: Date | null, day: Date) {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
  const isRangeSelected = from && to;

  return !!(!from || isBeforeFirstDay || isRangeSelected);
}

const DayPickerRange = styled(DayPicker)`
  &.Range
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }

  &.Range .DayPicker-Day {
    border-radius: 0 !important;
  }
`;

function Calendar({
  from,
  to,
  enteredTo,
  dispatch
}: {
  from: Date;
  to: Date | null;
  enteredTo: Date;
  dispatch: React.Dispatch<ActionDatePicker>;
}) {
  const handleDayClick = (day: Date) => {
    if (from && to && gte(day)(from) && lte(day)(to)) {
      dispatch({ type: 'reset' });
      return;
    }

    const dispatchWithDay = (
      action: 'selecionaDataInicial' | 'selecionaDataFinal' | 'destacaIntervalo'
    ) => dispatch({ type: action, day });

    if (isSelectingFirstDay(from, to, day)) {
      dispatchWithDay('selecionaDataInicial');
    } else {
      dispatchWithDay('selecionaDataFinal');
    }
  };

  const handleDayMouseEnter = (day: Date) => {
    if (!isSelectingFirstDay(from, to, day)) {
      dispatch({ type: 'destacaIntervalo', day });
    }
  };

  return (
    <DayPickerRange
      className="Range"
      numberOfMonths={2}
      localeUtils={MomentLocaleUtils}
      locale="pt-br"
      selectedDays={[from, { from, to: enteredTo }]}
      initialMonth={from || moment().toDate()}
      modifiers={{
        start: from,
        end: enteredTo
      }}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
    />
  );
}

/**
 * Formata uma data para DD MMM.
 * @param {string} data Data a ser formatada.
 * @returns {string | false}
 */
function formatarDataDiaMes(data: string | Date) {
  return formatarData({ data, formato: 'DD MMM' });
}

const DatePicker = ({
  isOpen,
  from,
  to,
  enteredTo,
  dispatch,
  isDisabled,
  style
}: {
  isOpen: boolean;
  from: Date;
  to: Date | null;
  enteredTo: Date;
  dispatch: any;
  isDisabled: boolean;
  style?: React.CSSProperties;
}) => {
  const buttonRef = React.useRef();

  return (
    <div style={{ position: 'relative' }}>
      <ButtoWithHover
        isDisabled={isDisabled}
        leftIcon="calendar"
        variant="outline"
        style={{
          paddingLeft: !from && !to ? '25px' : undefined,
          ...style
        }}
        onClick={() => {
          if (isOpen) {
            dispatch({ type: 'fecharCalendario' });
          } else {
            dispatch({ type: 'abrirCalendario' });
          }
        }}
        ref={buttonRef}
      >
        {formatarDataDiaMes(from)} {to && ` - ${formatarDataDiaMes(to)}`}
      </ButtoWithHover>

      {isOpen && (
        <FocusOn
          scrollLock={false}
          onClickOutside={() => {
            if (!to) {
              dispatch({ type: 'selecionaDataFinal', day: from });
            }
            dispatch({ type: 'fecharCalendario' });
          }}
          onEscapeKey={() => dispatch({ type: 'fecharCalendario' })}
          shards={[buttonRef]}
        >
          <Box
            position="absolute"
            marginTop="5px"
            shadow="md"
            borderWidth="1px"
            borderColor="gray.600"
            borderRadius=".25rem"
            backgroundColor="#fff"
            width="542px"
          >
            <Calendar
              from={from}
              to={to}
              enteredTo={enteredTo}
              dispatch={dispatch}
            />
          </Box>
        </FocusOn>
      )}
    </div>
  );
};

export default DatePicker;
