/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const Table = styled.table`
  width: 100%;
`;

export default Table;
