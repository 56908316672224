import React from 'react';
import { Heading, Divider, Stack } from '@chakra-ui/core';

const GrupoDeCampos: React.FC<{ titulo: string }> = props => {
  const { titulo, children } = props;

  return (
    <>
      {titulo && (
        <>
          <Heading as="h3" size="md" marginBottom="20px" marginTop="20px">
            {titulo}
          </Heading>
          <Divider />
        </>
      )}

      {children && <Stack spacing={3}>{children}</Stack>}
    </>
  );
};

export default GrupoDeCampos;
