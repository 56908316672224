/**
 * Dependências externas
 */
import isEqual from 'lodash/fp/isEqual';
import partialRight from 'lodash/fp/partialRight';
import compose from 'lodash/fp/compose';
import getHttpStatusError from './getHttpStatusError';

const is404Error = compose(partialRight(isEqual, [404]), getHttpStatusError);

export default is404Error;
