/**
 * Dependências externas
 */
import get from 'lodash/get';
import last from 'lodash/last';
import curryN from 'lodash/fp/curryN';
import partialRight from 'lodash/fp/partialRight';
import compose from 'lodash/fp/compose';
import split from 'lodash/split';

/**
 * Recupera o id do registro criado que é retornado no cabeçalho HTTP .
 * @param {any} retornoHTTP Retorno HTTP do servidor.
 * @returns {string}
 */
function recuperaIdDoRegistroCriado(retornoHTTP: object): string {
  return compose(
    last,
    partialRight(curryN(2, split), ['/']),
    partialRight(get, ['headers.location', ''])
  )(retornoHTTP);
}

export default recuperaIdDoRegistroCriado;
