/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import AreaPrincipal from 'apresentacao/AreaPrincipal';
import BarraSuperiorArquivosRetorno from './apresentacao/BarraSuperiorArquivosRetorno';
import ConteudoDaPagina from 'apresentacao/ConteudoDaPagina';
import rota from 'constants/rota';
import TodosArquivosRetorno from './TodosArquivosRetorno';

function ArquivosRetorno(props: RouteComponentProps) {
  const path = get(props, 'match.path');

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorArquivosRetorno
          path={path}
          titulo={rota.arquivosRetorno.titulo}
        />

        <ConteudoDaPagina>
          <Switch>
            <Route
              path={`${path}/todos`}
              exact
              component={TodosArquivosRetorno}
            ></Route>

            <Redirect to={`${path}/todos`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
}

export default ArquivosRetorno;
