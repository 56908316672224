/**
 * Dependências externas
 */
import React from 'react';
import { Tooltip, IconButton } from '@chakra-ui/core';

export default function ExcluirInquilinoButton({ onClick, isProcessing }) {
  return (
    <Tooltip
      label="Excluir registro"
      hasArrow
      placement="top"
      aria-label="Excluir registro"
    >
      <IconButton
        variant="ghost"
        variantColor="red"
        aria-label="Excluir registro"
        icon="delete"
        size="sm"
        onClick={onClick}
        isLoading={isProcessing}
        isDisabled={isProcessing}
      />
    </Tooltip>
  );
}
