/**
 * Dependências externas
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '@chakra-ui/core';

import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';
import { Global, css } from '@emotion/core';

/**
 * Dependências internas
 */
import Form from './apresentacao/Form';
import api from 'utils/api';
import formatarData from 'paginas/utils/formatarData';

function downloadReducer(state, action) {
  switch (action.type) {
    case 'downloadInit':
      return {
        ...state,
        baixando: true,
        erro: false
      };

    case 'downloadSuccess':
      return {
        ...state,
        baixando: false,
        erro: false
      };

    case 'downloadError':
      return {
        ...state,
        baixando: false,
        erro: true,
        mensagem: action.payload
      };

    case 'toggleError':
      return {
        ...state,
        erro: !state.erro
      };

    default:
      throw new Error();
  }
}

function downloadArquivo(nomeDoArquivo, arquivo) {
  const arquivoBlob = new Blob([arquivo]);
  const arquivoURL = URL.createObjectURL(arquivoBlob);

  const link = document.createElement('a');
  link.href = arquivoURL;
  link.download = nomeDoArquivo;
  link.click();

  URL.revokeObjectURL(arquivoURL);
}

function getRelatorioDivida({ unidadeId, dataReferencia }) {
  return api.get(
    `relatorios/valorPresenteDividaUnidade/${unidadeId}?referencia=${dataReferencia}`,
    {
      responseType: 'blob'
    }
  );
}

const Editar = () => {
  const [state, dispatch] = React.useReducer(downloadReducer, {
    baixando: false,
    erro: false
  });

  const [dataReferencia] = React.useState(new Date());

  const { idRegistro } = useParams();

  React.useEffect(() => {
    let didCancel = false;

    if (state.baixando) {
      getRelatorioDivida({
        unidadeId: idRegistro,
        dataReferencia: formatarData({
          data: dataReferencia,
          formato: 'YYYY-MM-DD'
        })
      })
        .then(resultado => {
          if (!didCancel) {
            downloadArquivo(
              `relatorio-divida-unidade-${idRegistro}-${formatarData({
                data: dataReferencia,
                formato: 'DD-MM-YY'
              })}.pdf`,
              resultado.data
            );

            dispatch({
              type: 'downloadSuccess'
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    return () => {
      didCancel = true;
    };
  }, [state.baixando, idRegistro, dataReferencia]);

  return (
    <>
      <Global
        styles={css`
          .DayPickerInput > input {
            width: 100%;
            border: 1px solid #cbd5e0;
            border-radius: 0.25rem;
            padding: 0 0.5rem;

            :focus {
              border-color: #3182ce !important;
              box-shadow: 0 0 0 1px #3182ce;
              outline: 2px solid transparent !important;
              outline-offset: 2px !important;
            }
          }
          .DayPicker-Day {
            border-radius: 0 !important;
          }
        `}
      />

      <Heading as="h2" size="lg" marginBottom="10px">
        Editar unidade
      </Heading>

      <Form />
    </>
  );
};

export default Editar;
