/**
 * Dependências externas
 */
import React from 'react';
import styled from '@emotion/styled';
import {
  Heading,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

const Link = styled(NavLink)`
  font-size: 14px;
  padding: 0 10px;

  color: #939393;

  :hover {
    color: #000;
  }

  &.selected {
    color: #000;
  }
`;

const Item = styled(MenuItem)`
  :hover {
    a {
      color: #000;
    }
  }
`;

const DropdownButton: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        fontWeight="normal"
        color="#a3a3a3"
        _hover={{ color: '#000' }}
        {...{
          rightIcon: 'chevron-down',
          variant: 'ghost'
        }}
      >
        {label}
      </MenuButton>

      <MenuList>
        {React.Children.map(children, thisArg => (
          <Item fontSize="14px">{thisArg}</Item>
        ))}
      </MenuList>
    </Menu>
  );
};

const BarraSuperior: React.FC<{ titulo: string }> = ({
  titulo,
  children,
  ...rest
}) => {
  return (
    <Box
      shadow="md"
      h="66px"
      backgroundColor="#fff"
      pl="50px"
      marginBottom="20px"
      {...rest}
    >
      <Flex alignItems="center" height="100%">
        <Heading as="h1" size="sm" mr="21px">
          {titulo}
        </Heading>

        {children}
      </Flex>
    </Box>
  );
};

export default BarraSuperior;

export { Link, DropdownButton };
