/**
 * Dependências externas
 */
import get from 'lodash/get';
import { FormikProps } from 'formik';

/**
 * Dependências internas
 */
import getValorDoCampo from './getValorDoCampo';

const getErroDoCampo = (obj: {formik: FormikProps<{}>}, nomeDoCampo: string) =>
  get(obj, `formik.errors[${nomeDoCampo}]`);

const getTouchedDoCampo = (obj: {formik: FormikProps<{}>}, nomeDoCampo: string) =>
  get(obj, `formik.touched[${nomeDoCampo}]`);

const getInformacoesDoCampo = (obj: {formik: FormikProps<{}>}, nomeDoCampo: string) => {
  const erro = getErroDoCampo(obj, nomeDoCampo);
  const touched = getTouchedDoCampo(obj, nomeDoCampo);

  return {
    valor: getValorDoCampo(obj, nomeDoCampo),
    erro,
    possuiErroDeValidacao: erro && touched,
    touched
  };
};

export default getInformacoesDoCampo;
