/**
 * Dependências externas
 */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Button,
  Box,
  FormControl,
  FormLabel,
  Tooltip
} from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

/**
 * Dependências internas
 */
import rota from 'constants/rota';

const FormAcessarUnidade = () => {
  const history = useHistory();
  const [ inputCodigoUnidade, setInputCodigoUnidade ] = useState( '' );

  const handleFormSubmit = event => {
    event.preventDefault();

    history.push( `${rota.unidades.url}/editar/${toUpper( inputCodigoUnidade )}` );
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Box d="flex" alignItems="flex-end" marginBottom="5">
        <FormControl>
          <FormLabel htmlFor="codigoUnidade">Código</FormLabel>

          <Input
            name="codigoUnidade"
            value={inputCodigoUnidade}
            onChange={event => setInputCodigoUnidade( event.target.value )}
            width="140px"
            autoComplete="off"
          />
        </FormControl>
        <Tooltip
          label={isEmpty( inputCodigoUnidade ) && 'Informe o código da unidade'}
          hasArrow={isEmpty( inputCodigoUnidade )}
          placement="top"
          bg={isEmpty( inputCodigoUnidade ) ? 'gray.700' : 'transparent'}
          boxShadow={! isEmpty( inputCodigoUnidade ) && 'none'}
        >
          <Button
            type="submit"
            variantColor="blue"
            variant="outline"
            marginLeft="4"
            isDisabled={isEmpty( inputCodigoUnidade )}
          >
            Acessar unidade{' '}
          </Button>
        </Tooltip>
      </Box>
    </form>
  );
};

export default FormAcessarUnidade;
