/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependências internas
 */
import api from 'utils/api';

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  }
);

type State = {
  baixando: boolean;
  error: boolean | object;
  filename: string;
  url: string;
};

type Action =
  | { type: 'downloadInit'; payload: { url: string; filename: string } }
  | { type: 'downloadSuccess' }
  | { type: 'downloadError'; payload: boolean | object }
  | { type: 'toggleError' };

function downloadReducer(state: State, action: Action) {
  switch (action.type) {
    case 'downloadInit':
      return {
        ...state,
        baixando: true,
        error: false,
        url: action.payload.url,
        filename: action.payload.filename
      };

    case 'downloadSuccess':
      return {
        ...state,
        baixando: false,
        error: false
      };

    case 'downloadError':
      return {
        ...state,
        baixando: false,
        error: action.payload
      };

    case 'toggleError':
      return {
        ...state,
        error: !state.error
      };

    default:
      throw new Error();
  }
}

function downloadFile(nomeDoArquivo: string, arquivo: BlobPart) {
  const arquivoBlob = new Blob([arquivo]);
  const arquivoURL = URL.createObjectURL(arquivoBlob);

  const link = document.createElement('a');
  link.href = arquivoURL;
  link.download = nomeDoArquivo;
  link.click();

  URL.revokeObjectURL(arquivoURL);
}

export default function useDownloadFileFromUrl() {
  const [state, dispatch] = React.useReducer(downloadReducer, {
    url: '',
    filename: '',
    baixando: false,
    error: false
  });

  React.useEffect(() => {
    let didCancel = false;

    if (state.baixando) {
      api
        .get(state.url, { responseType: 'blob' })
        .then(resultado => {
          if (!didCancel) {
            downloadFile(state.filename, resultado.data);

            dispatch({
              type: 'downloadSuccess'
            });
          }
        })
        .catch(error => {
          console.log({ error });

          dispatch({ type: 'downloadError', payload: error });
        });
    }

    return () => {
      didCancel = true;
    };
  }, [state.baixando, state.filename, state.url]);

  return {
    downloadState: { baixando: state.baixando, error: state.error },
    downloadFile: (url: string, filename: string) =>
      dispatch({ type: 'downloadInit', payload: { url, filename } })
  };
}
