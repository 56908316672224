/**
 * Dependências externas
 */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Flex,
  Checkbox,
  Stack,
  FormControl,
  Alert,
  AlertIcon
} from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/fp/map';
import omit from 'lodash/omit';
import get from 'lodash/get';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import indexOf from 'lodash/indexOf';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

/**
 * Dependências internas
 */
import Carregando from 'apresentacao/Carregando';
import SelectSimples from 'apresentacao/form/Select/SelectSimples';
import BotaoFiltro from 'apresentacao/BotaoFiltro';
import useDataApi from 'hooks/useDataApi';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import is400Error from 'paginas/utils/is400Error';
import is403Error from 'paginas/utils/is403Error';

function getErrorMessage(error) {
  if (is400Error(error)) {
    return 'Houve um erro devido aos dados enviados.';
  } else if (is403Error(error)) {
    return 'Usuário não está autorizado a executar essa ação.';
  } else {
    return 'Ocorreu um erro no servidor. Favor procurar o suporte técnico.';
  }
}

const BotaFiltroConceito = props => {
  const { filtrarPor, setFiltrarPor } = props;
  const { setUrl, ...stateFiltroConceito } = useDataApi('');
  const [conceitosSelecionados, setConceitosSelecionados] = useState({});
  const desconectarUsuario = useContext(AutenticacaoContext);

  useEffect(() => {
    if (stateFiltroConceito.erro) {
      handleHTTPError({
        error: stateFiltroConceito.erro,
        handle401Error: desconectarUsuario
      });
    }
  }, [stateFiltroConceito.erro, desconectarUsuario]);

  return (
    <BotaoFiltro
      label="Conceito"
      onClick={() => setUrl('tiposlancamentos')}
      onClose={() => {
        atualizaFiltroSeMudou({
          tipo: 'conceito',
          filtrosSelecionados: filtrarPor,
          novosFiltros: conceitosSelecionados,
          fnSetFiltro: setFiltrarPor
        });
      }}
      quantidadeSelecionada={size(conceitosSelecionados)}
    >
      <OpcoesFiltro
        identificador="id"
        label="conceito"
        selecionados={conceitosSelecionados}
        setSelecionados={setConceitosSelecionados}
        {...stateFiltroConceito}
      />
    </BotaoFiltro>
  );
};

const BotaFiltroAgrupador = props => {
  const { filtrarPor, setFiltrarPor } = props;
  const { setUrl, data, carregando, erro } = useDataApi('');
  const [agrupadoresSelecionados, setAgrupadoresSelecionados] = useState([]);
  const desconectarUsuario = useContext(AutenticacaoContext);

  useEffect(() => {
    if (filtrarPor.agrupador) {
      setAgrupadoresSelecionados(
        map(
          opcao => ({ value: opcao, label: opcao }),
          keys(filtrarPor.agrupador)
        )
      );
    }
  }, [filtrarPor.agrupador]);

  useEffect(() => {
    if (erro) {
      handleHTTPError({
        error: erro,
        handle401Error: desconectarUsuario
      });
    }
  }, [erro, desconectarUsuario]);

  return (
    <BotaoFiltro
      label="Agrupador"
      onClick={() => setUrl('agrupadores')}
      onClose={() => {
        atualizaFiltroSeMudou({
          tipo: 'agrupador',
          filtrosSelecionados: filtrarPor,
          novosFiltros: reduce(
            agrupadoresSelecionados,
            (result, agrupador) => ({ ...result, [agrupador.value]: true }),
            {}
          ),
          fnSetFiltro: setFiltrarPor
        });
      }}
      quantidadeSelecionada={size(agrupadoresSelecionados)}
    >
      {erro && (
        <Alert status="error">
          <AlertIcon />
          {getErrorMessage(erro)}
        </Alert>
      )}

      {!erro && carregando && <Carregando align="center" />}

      {!erro && !carregando && (
        <FormControl mt={5}>
          <SelectSimples
            autoFocus
            name="filtroAgrupadorInput"
            value={agrupadoresSelecionados}
            options={map(opcao => ({ value: opcao, label: opcao }), data)}
            isMulti
            onChange={(arg1, { action, option, removedValue }) => {
              switch (action) {
                case 'select-option':
                  setAgrupadoresSelecionados(prev => [
                    ...prev,
                    { value: option.value, label: option.value }
                  ]);
                  break;

                case 'remove-value':
                  setAgrupadoresSelecionados(prev =>
                    filter(prev, opcao => opcao.value !== removedValue.value)
                  );
                  break;

                case 'clear':
                  setAgrupadoresSelecionados([]);
                  break;

                default:
                  break;
              }
            }}
          />
        </FormControl>
      )}
    </BotaoFiltro>
  );
};

const OpcoesFiltro = props => {
  const {
    data = [],
    carregando,
    erro,
    setSelecionados,
    selecionados,
    identificador,
    label
  } = props;

  return (
    <>
      {erro && (
        <Alert status="error">
          <AlertIcon />
          {getErrorMessage(erro)}
        </Alert>
      )}
      {!erro && carregando && <Carregando align="center" />}
      {!erro && !carregando && !isEmpty(data) && (
        <Stack pl={6} mt={1} spacing={1}>
          {map(
            registro => (
              <Checkbox
                key={identificador ? registro[identificador] : registro}
                onChange={event => {
                  const name = event.target.name;
                  const checked = event.target.checked;

                  if (checked) {
                    setSelecionados(prev => ({
                      ...prev,
                      [name]: checked
                    }));
                  } else {
                    setSelecionados(prev => omit(prev, [name]));
                  }
                }}
                name={label ? registro[label] : registro}
                defaultIsChecked={
                  isObject(registro)
                    ? get(
                        selecionados,
                        label ? registro[label] : registro,
                        false
                      )
                    : -1 !== indexOf(selecionados, registro)
                }
              >
                {label ? registro[label] : registro}
              </Checkbox>
            ),
            data
          )}
        </Stack>
      )}
    </>
  );
};

const atualizaFiltroSeMudou = ({
  tipo,
  filtrosSelecionados,
  novosFiltros,
  fnSetFiltro
}) => {
  if (isEmpty(filtrosSelecionados)) {
    if (!isEmpty(novosFiltros)) {
      fnSetFiltro({ [tipo]: novosFiltros });
    }
  } else {
    if (isEmpty(novosFiltros)) {
      fnSetFiltro(omit(filtrosSelecionados, [tipo]));
    } else {
      if (!isEqual(filtrosSelecionados[tipo], novosFiltros)) {
        fnSetFiltro(prev => ({
          ...prev,
          [tipo]: novosFiltros
        }));
      }
    }
  }
};

const BarraFiltroLancamento = props => {
  const { setFiltrarPor, filtrarPor } = props;

  return (
    <Box
      shadow="md"
      h="50px"
      backgroundColor="#fff"
      pl="50px"
      marginBottom="20px"
      marginTop="-29px"
      marginLeft="-50px"
      marginRight="-50px"
      zIndex="999"
    >
      <Flex alignItems="center" height="100%">
        <p style={{ fontSize: '14px', color: '#939393' }}>Filtrar por:</p>

        <BotaFiltroConceito
          filtrarPor={filtrarPor}
          setFiltrarPor={setFiltrarPor}
        />

        <BotaFiltroAgrupador
          filtrarPor={filtrarPor}
          setFiltrarPor={setFiltrarPor}
        />
      </Flex>
    </Box>
  );
};

export default BarraFiltroLancamento;
