/**
 * Dependências externas
 */
import React, {useContext} from 'react';
import { Heading } from '@chakra-ui/core';
import lowerCase from 'lodash/lowerCase';

/**
 * Dependências internas
 */
import rota from '../../constants/rota';
import Form from './apresentacao/Form';
import AutenticacaoContext from 'context/AutenticacaoContext';

const tituloSingularComLetrasMinusculas = lowerCase( rota.lancamento.tituloSingular );

const Novo = () => {
  const desconectarUsuario = useContext( AutenticacaoContext );

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Novo {tituloSingularComLetrasMinusculas}
      </Heading>
      
      <Form novo desconectarUsuario={desconectarUsuario} />
    </>
  );
};

export default Novo;
