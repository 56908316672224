/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import rota from '../../constants/rota';
import AreaPrincipal from '../../apresentacao/AreaPrincipal';
import BarraSuperiorUnidades from './apresentacao/BarraSuperiorUnidades';
import ConteudoDaPagina from '../../apresentacao/ConteudoDaPagina';
import Novo from './Novo';
import TodasUnidades from './TodasUnidades';
import Editar from './Editar';

const Unidades = props => {
  const path = get( props, 'match.path' );

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorUnidades path={path} titulo={rota.unidades.titulo} />

        <ConteudoDaPagina>
          <Switch>
            <Route
              path={`${path}/todas`}
              exact
              component={TodasUnidades}
            ></Route>
            <Route path={`${path}/nova`} exact component={Novo}></Route>
            <Route
              path={`${path}/editar/:idRegistro`}
              strict
              component={Editar}
            ></Route>

            <Redirect to={`${path}/todas`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
};

export default Unidades;
