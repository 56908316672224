/**
 * Dependências externas
 */
import React, { useEffect, useCallback, useMemo } from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';
import round from 'lodash/round';
import replace from 'lodash/replace';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import partialRight from 'lodash/fp/partialRight';
import partial from 'lodash/fp/partial';
import add from 'lodash/fp/add';
import divide from 'lodash/fp/divide';
import multiply from 'lodash/fp/multiply';
import isFinite from 'lodash/isFinite';
import gt from 'lodash/fp/gt';
import isEmpty from 'lodash/isEmpty';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';
import formatarMoeda from 'paginas/utils/formatarMoeda';

const moedaBRLToNumber = compose(
  toNumber,
  partialRight(replace, [',', '.']),
  partialRight(replace, ['.', '']),
  formatarMoeda
);

const DataPagamento = props => {
  const nomeDoCampo = 'dataPagamento';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur, setFieldValue, values } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const numeroDiasAposVencimento = useMemo(() => {
    const data = moment(valor);
    const dataVencimento = moment(values.vencimento, 'DD/MM/YYYY');

    return data.diff(dataVencimento, 'days');
  }, [valor, values.vencimento]);

  const valorOriginal = useMemo(() => moedaBRLToNumber(values.valor), [
    values.valor
  ]);

  const multiplyByValorFatura = useCallback(multiply(valorOriginal), [
    valorOriginal
  ]);

  const valorMulta = useMemo(
    () =>
      compose(
        partialRight(divide, [10000]),
        multiplyByValorFatura,
        toNumber
      )(values.valorMulta),
    [multiplyByValorFatura, values.valorMulta]
  );

  const valorJuros = useMemo(
    () =>
      compose(
        partialRight(divide, [300000]),
        multiplyByValorFatura,
        partial(multiply, [
          numeroDiasAposVencimento,
          toNumber(values.valorJuros)
        ])
      )(),
    [numeroDiasAposVencimento, multiplyByValorFatura, values.valorJuros]
  );

  const valorCorrigido = useMemo(() => {
    if (gt(numeroDiasAposVencimento)(0)) {
      return compose(
        partialRight(round, [2]),
        partial(add, [valorOriginal]),
        partial(add, [valorMulta, valorJuros])
      )();
    } else {
      return valorOriginal;
    }
  }, [numeroDiasAposVencimento, valorJuros, valorMulta, valorOriginal]);

  useEffect(() => {
    if (isFinite(valorCorrigido) && !isEmpty(valor)) {
      setFieldValue('valorCorrigido', valorCorrigido, false);
      setFieldValue('valorPagamento', valorCorrigido, false);
    } else {
      setFieldValue('valorCorrigido', '', false);
      setFieldValue('valorPagamento', '', false);
    }
  }, [setFieldValue, valorCorrigido, valor]);

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Data do pagamento</FormLabel>
      <Input
        type="date"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(DataPagamento);
