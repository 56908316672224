/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Switch
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';

const Tributavel = props => {
  const nomeDoCampo = 'tributavel';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Tributável</FormLabel>
      <br />
      <Switch
        id={nomeDoCampo}
        value={valor}
        onChange={handleChange}
        onBlur={handleBlur}
        isDisabled={rest.isDisabled}
      />{' '}
      {valor ? 'Sim' : 'Não'}
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(Tributavel);
