/**
 * Dependências externas
 */
import React, { useEffect, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import partial from 'lodash/fp/partial';
import get from 'lodash/get';
import { withFormik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Text,
  Tooltip
} from '@chakra-ui/core';
import { useHistory, useParams } from 'react-router-dom';
/**
 * Dependências internas
 */
import rota from 'constants/rota';
import apiURL from 'constants/apiURL';
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import Mensagem from 'apresentacao/Mensagem';
import Carregando from 'apresentacao/Carregando';
import useDataApi from 'hooks/useDataApi';
import recuperaIdDoRegistroCriado from 'utils/form/recuperaIdDoRegistroCriado';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';

/**
 * Campos do formulário
 */
import Conceito from './campos/Conceito';
import Despesa from './campos/Despesa';
import Historia from './campos/Historia';
import Periodico from './campos/Periodico';
import Receita from './campos/Receita';
import TipoDeRateio from './campos/TipoDeRateio';
import Tributavel from './campos/Tributavel';
import api from 'utils/api';

const postTipoDeLancamento = values => api.post('tiposlancamentos', values);

const FormApresentacao = props => {
  const { handleSubmit, isSubmitting, status, setStatus, values } = props;

  const possuiMensagem = !isEmpty(status);

  const history = useHistory();

  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = React.useCallback(() => setIsOpen(false), []);

  const isFieldsDisabled = React.useMemo(() => Boolean(values.id), [values.id]);

  return (
    <>
      {possuiMensagem && (
        <Mensagem
          tipo={status.tipo}
          fechar={() => setStatus(undefined)}
          mb="1.25rem"
          width={['100%', '100%', '100%', '50%']}
        >
          {status.mensagem}
        </Mensagem>
      )}
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        backgroundColor="#fff"
        width={['100%', '100%', '100%', '50%']}
      >
        <form onSubmit={handleSubmit}>
          <GrupoDeCampos>
            <Conceito isDisabled={isFieldsDisabled} />
            <Receita isDisabled={isFieldsDisabled} />
            <Despesa isDisabled={isFieldsDisabled} />
            <Periodico isDisabled={isFieldsDisabled} />
            <Tributavel isDisabled={isFieldsDisabled} />
            <TipoDeRateio isDisabled={isFieldsDisabled} />
            <Historia isDisabled={isFieldsDisabled} />
          </GrupoDeCampos>

          {values.id ? (
            <Tooltip
              aria-label="Não é possível atualizar. Somente leitura."
              hasArrow
              label="Não é possível atualizar. Somente leitura."
              placement="top"
            >
              <Button
                mt="2rem"
                variantColor="blue"
                type="button"
                cursor="not-allowed"
                isLoading={isSubmitting}
                opacity="0.5"
              >
                Atualizar
              </Button>
            </Tooltip>
          ) : (
            <Popover
              isOpen={isOpen}
              onOpen={open}
              onClose={close}
              placement="top"
            >
              <PopoverTrigger>
                <Button
                  isDisabled={isSubmitting}
                  mt="2rem"
                  variantColor="blue"
                  isLoading={isSubmitting}
                >
                  Salvar
                </Button>
              </PopoverTrigger>
              <PopoverContent zIndex={4}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>
                  <Text fontWeight="bold">Atenção!</Text>
                </PopoverHeader>
                <PopoverBody>
                  <Text fontSize="md" marginBottom="2">
                    A criação de um Tipo de Lançamento é parte do cadastro do
                    Sistema e, portanto, é uma ação excepcional. Não crie um
                    novo se não tiver certeza do que está fazendo.
                  </Text>
                  <Text fontSize="md">
                    Em caso de dúvidas, contate o suporte técnico. Essa ação não
                    poderá ser desfeita. Deseja continuar?
                  </Text>
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      close();
                      handleSubmit();
                    }}
                  >
                    Sim
                  </Button>
                  <Button onClick={close} variant="ghost" type="button">
                    Não
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}

          <Button
            ml={4}
            mt="2rem"
            variantColor="red"
            variant="outline"
            type="button"
            isDisabled={isSubmitting}
            onClick={() => history.push(`${rota.tiposDeLancamento.url}/todos`)}
          >
            Cancelar
          </Button>
        </form>
      </Box>
    </>
  );
};

const validacaoCampos = yup.object().shape({
  conceito: yup.string().required('Campo obrigatório'),
  receita: yup.string().required('Campo obrigatório'),
  despesa: yup.string().required('Campo obrigatório'),
  tipoRateio: yup.string().required('Campo obrigatório'),
  historia: yup
    .string()
    .required('Campo obrigatório')
    .max(1024, 'Não é possível inserir mais que 1024 caracteres')
});

function getFromDados(obj, propName, defaultValue = '') {
  return get(obj, `dados[${propName}]`, defaultValue);
}

const formLogica = {
  mapPropsToValues: props => {
    const getDados = partial(getFromDados, [props]);

    return {
      id: getDados('id'),
      conceito: getDados('conceito'),
      receita: getDados('receita'),
      despesa: getDados('despesa'),
      periodico: getDados('periodico'),
      tributavel: getDados('tributavel'),
      tipoRateio: getDados('tipoRateio'),
      historia: getDados('historia')
    };
  },
  validationSchema: validacaoCampos,
  handleSubmit: (value, { setSubmitting, setStatus, setFieldValue, props }) => {
    setStatus(undefined);

    const { desconectarUsuario } = props;

    const { id, ...values } = value;

    if (id) {
      console.log(id);
    } else {
      postTipoDeLancamento(values)
        .then(result => {
          const idDoRegistroCriado = recuperaIdDoRegistroCriado(result);

          if (!isEmpty(idDoRegistroCriado)) {
            setFieldValue('id', idDoRegistroCriado, false);
          }

          setStatus({
            tipo: 'success',
            mensagem: 'Tipo de lançamento salvo com sucesso'
          });
        })
        .catch(error => {
          const setError = mensagemDeErro =>
            setStatus({
              tipo: 'error',
              mensagem: mensagemDeErro
            });

          handleHTTPError({
            error,
            handle400Error: setError,
            handle401Error: desconectarUsuario,
            handle403Error: setError,
            handleGenericError: setError
          });
        })
        .finally(() => setSubmitting(false));
    }
  },
  displayName: 'TipoDeLancamentoForm'
};

const Formik = withFormik(formLogica)(FormApresentacao);

const Form = props => {
  const { novo } = props;

  const desconectarUsuario = useContext(AutenticacaoContext);

  const { idRegistro } = useParams();

  const { data, carregando, erro, setUrl } = useDataApi();

  useEffect(() => {
    if (idRegistro && !novo) {
      setUrl(`${apiURL}tiposlancamentos/${idRegistro}`);
    }
  }, [idRegistro, setUrl, novo]);

  return (
    <>
      {erro && <p>Ocorreu um erro.</p>}

      {!erro && carregando && <Carregando align="center" />}

      {!erro && !carregando && (!isUndefined(data) || novo) && (
        <Formik dados={data} desconectarUsuario={desconectarUsuario} />
      )}
    </>
  );
};

export default Form;
