/**
 * Dependências externas
 */
import React, { useState, useEffect, useContext } from 'react';
import { Box, Flex, FormControl, Alert, AlertIcon } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/fp/map';
import omit from 'lodash/omit';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

/**
 * Dependências internas
 */
import Carregando from 'apresentacao/Carregando';
import SelectSimples from 'apresentacao/form/Select/SelectSimples';
import BotaoFiltro from 'apresentacao/BotaoFiltro';
import useDataApi from 'hooks/useDataApi';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import is400Error from 'paginas/utils/is400Error';
import is403Error from 'paginas/utils/is403Error';

function getErrorMessage(error) {
  if (is400Error(error)) {
    return 'Houve um erro devido aos dados enviados.';
  } else if (is403Error(error)) {
    return 'Usuário não está autorizado a executar essa ação.';
  } else {
    return 'Ocorreu um erro no servidor. Favor procurar o suporte técnico.';
  }
}

const BotaFiltroUnidade = props => {
  const { filtrarPor, setFiltrarPor } = props;
  const { setUrl, data, carregando, erro } = useDataApi('');
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState([]);
  const desconectarUsuario = useContext(AutenticacaoContext);

  useEffect(() => {
    if (filtrarPor.unidade) {
      setUnidadesSelecionadas(
        map(opcao => ({ value: opcao, label: opcao }), keys(filtrarPor.unidade))
      );
    }
  }, [filtrarPor.unidade]);

  useEffect(() => {
    if (erro) {
      handleHTTPError({
        error: erro,
        handle401Error: desconectarUsuario
      });
    }
  }, [erro, desconectarUsuario]);

  return (
    <BotaoFiltro
      label="Unidade"
      onClick={() => setUrl('unidades/ids')}
      onClose={() => {
        atualizaFiltroSeMudou({
          tipo: 'referencia',
          filtrosSelecionados: filtrarPor,
          novosFiltros: reduce(
            unidadesSelecionadas,
            (result, agrupador) => ({ ...result, [agrupador.value]: true }),
            {}
          ),
          fnSetFiltro: setFiltrarPor
        });
      }}
      quantidadeSelecionada={size(unidadesSelecionadas)}
    >
      {erro && (
        <Alert status="error">
          <AlertIcon />
          {getErrorMessage(erro)}
        </Alert>
      )}

      {!erro && carregando && <Carregando align="center" />}

      {!erro && !carregando && (
        <FormControl mt={5}>
          <SelectSimples
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            name="filtroAgrupadorInput"
            value={unidadesSelecionadas}
            options={map(opcao => ({ value: opcao, label: opcao }), data)}
            isMulti
            onChange={(arg1, { action, option, removedValue }) => {
              switch (action) {
                case 'select-option':
                  setUnidadesSelecionadas(prev => [
                    ...prev,
                    { value: option.value, label: option.value }
                  ]);
                  break;

                case 'remove-value':
                  setUnidadesSelecionadas(prev =>
                    filter(prev, opcao => opcao.value !== removedValue.value)
                  );
                  break;

                case 'clear':
                  setUnidadesSelecionadas([]);
                  break;

                default:
                  break;
              }
            }}
          />
        </FormControl>
      )}
    </BotaoFiltro>
  );
};

const atualizaFiltroSeMudou = ({
  tipo,
  filtrosSelecionados,
  novosFiltros,
  fnSetFiltro
}) => {
  if (isEmpty(filtrosSelecionados)) {
    if (!isEmpty(novosFiltros)) {
      fnSetFiltro({ [tipo]: novosFiltros });
    }
  } else {
    if (isEmpty(novosFiltros)) {
      fnSetFiltro(omit(filtrosSelecionados, [tipo]));
    } else {
      if (!isEqual(filtrosSelecionados[tipo], novosFiltros)) {
        fnSetFiltro(prev => ({
          ...prev,
          [tipo]: novosFiltros
        }));
      }
    }
  }
};

const BarraFiltroLancamento = props => {
  const { setFiltrarPor, filtrarPor } = props;

  return (
    <Box
      shadow="md"
      h="50px"
      backgroundColor="#fff"
      pl="50px"
      marginBottom="20px"
      marginTop="-29px"
      marginLeft="-50px"
      marginRight="-50px"
      zIndex="40"
      position="relative"
    >
      <Flex alignItems="center" height="100%">
        <p style={{ fontSize: '14px', color: '#939393' }}>Filtrar por:</p>

        <BotaFiltroUnidade
          filtrarPor={filtrarPor}
          setFiltrarPor={setFiltrarPor}
        />
      </Flex>
    </Box>
  );
};

export default BarraFiltroLancamento;
