import React from 'react';
import {
  Box,
  Button,
  Tag,
  TagLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/core';
import gt from 'lodash/fp/gt';

const BotaoFiltro: React.FC<{
  label: string;
  onClick: () => void;
  onClose: () => void;
  quantidadeSelecionada: number;
}> = props => {
  const { label, onClick, onClose, quantidadeSelecionada, children } = props;
  const possuiItemSelecionado = gt(quantidadeSelecionada, 0);

  return (
    <Popover onClose={onClose}>
      <PopoverTrigger>
        <Box display="inline" position="relative" marginLeft="15px">
          <Button
            variant="ghost"
            size="sm"
            color="#939393"
            fontWeight={possuiItemSelecionado ? 'bold' : 'normal'}
            onClick={onClick}
          >
            {label}
          </Button>
          {possuiItemSelecionado && (
            <Tag
              size="sm"
              rounded="full"
              variant="solid"
              color="#fefefe"
              backgroundColor="#e74c3c"
              fontWeight="700"
              minHeight="unset"
              minWidth="unset"
              maxWidth="unset"
              paddingX="0"
              width="15px"
              height="15px"
              justifyContent="center"
              position="absolute"
              right="-2px"
              bottom="-2px"
            >
              <TagLabel fontSize=".6875rem">{quantidadeSelecionada}</TagLabel>
            </Tag>
          )}
        </Box>
      </PopoverTrigger>
      <PopoverContent p={5} zIndex={1}>
        <PopoverArrow bg="white" />
        <PopoverCloseButton />
        {children}
      </PopoverContent>
    </Popover>
  );
};

export default BotaoFiltro;
