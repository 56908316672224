/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const Thead = styled.thead`
  box-shadow: rgb(232, 232, 232) 0 -1px 0 0 inset;
`;

export default Thead;
