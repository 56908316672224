/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import { FormControl, FormLabel, Input } from '@chakra-ui/core';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from '../../../../../utils/form/getInformacoesDoCampo';

const ValorCorrigido = props => {
  const nomeDoCampo = 'valorCorrigido';

  const { valor } = getInformacoesDoCampo(props, nomeDoCampo);

  return (
    <FormControl {...props}>
      <FormLabel htmlFor={nomeDoCampo}>Valor corrigido</FormLabel>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        value={valor}
        textAlign="right"
      />
    </FormControl>
  );
};

export default connectToFormik(ValorCorrigido);
