/**
 * Dependências externas
 */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Text
} from '@chakra-ui/core';
import { useHistory } from 'react-router-dom';
import partial from 'lodash/fp/partial';
import partialRight from 'lodash/fp/partialRight';
import get from 'lodash/get';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/isEmpty';
import { withFormik } from 'formik';
import * as yup from 'yup';

/**
 * Dependências internas
 */
import rota from 'constants/rota';
import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import formatarData from 'paginas/utils/formatarData';
import api from 'utils/api';
import recuperaIdDoRegistroCriado from 'utils/form/recuperaIdDoRegistroCriado';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import moedaBRLToInt from 'paginas/utils/moedaBRLToInt';
import Mensagem from 'apresentacao/Mensagem';

/**
 * Campos
 */
import IdentificacaoDaFatura from './campos/IdentificacaoDaFatura';
import Unidade from './campos/Unidade';
import MesDeCompetencia from './campos/MesDeCompetencia';
import Vencimento from './campos/Vencimento';
import DadosDoTitular from './campos/titular/DadosDoTitular';
import TipoDaBaixa from './campos/TipoDaBaixa';
import Observacao from './campos/Observacao';
import DataPagamento from './campos/DataPagamento';
import ValorPagamento from './campos/ValorPagamento';
import ValorCorrigido from './campos/ValorCorrigido';
import ValorFatura from './campos/ValorFatura';

function postLiquidarFatura({ idFatura, data }) {
  return api.post(`faturas/${idFatura}/liquidar`, data);
}

function BotaoLiquidarFatura({
  isCamposObrigatoriosPreenchidos,
  handleSubmit,
  isLoading,
  isDisabled
}) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => {
    if (isCamposObrigatoriosPreenchidos) {
      setIsOpen(true);
    } else {
      handleSubmit();
    }
  }, [isCamposObrigatoriosPreenchidos, handleSubmit]);
  const close = useCallback(() => setIsOpen(false), []);

  return (
    <Popover isOpen={isOpen} onOpen={open} onClose={close} placement="top">
      <PopoverTrigger>
        <Button
          isDisabled={isDisabled}
          mt="2rem"
          variantColor="blue"
          isLoading={isLoading}
        >
          Liquidar fatura
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text fontWeight="bold">Atenção!</Text>
          <Text fontSize="xs">Essa ação não pode ser desfeita.</Text>
        </PopoverHeader>
        <PopoverBody>
          <Text fontSize="md">Você tem certeza que deseja prosseguir?</Text>
        </PopoverBody>
        <PopoverFooter>
          <Button
            variant="ghost"
            onClick={() => {
              close();
              handleSubmit();
            }}
          >
            Sim
          </Button>
          <Button onClick={close} variant="ghost" type="button">
            Não
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FormApresentacao(props) {
  const { handleSubmit, isSubmitting, values, status, setStatus } = props;

  const history = useHistory();

  const getFromValues = partial(partialRight(get, ['']), [values]);

  const isCamposObrigatoriosPreenchidos = Boolean(
    getFromValues('dataPagamento') &&
      getFromValues('valorPagamento') &&
      getFromValues('tipoDaBaixa')
  );

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (status && isEqual('success')(status.tipo)) {
      setIsSubmitted(true);
    }
  }, [status]);

  const possuiMensagem = !isEmpty(status);

  return (
    <>
      {possuiMensagem && (
        <Mensagem
          tipo={status.tipo}
          fechar={() => setStatus(undefined)}
          mb="1.25rem"
          width={['100%', '100%', '100%', '70%']}
        >
          {status.mensagem}
        </Mensagem>
      )}
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        backgroundColor="#fff"
        width={['100%', '100%', '100%', '70%']}
      >
        <form>
          <GrupoDeCampos>
            <IdentificacaoDaFatura
              isDisabled
              width={['35%', '35%', '35%', '30%']}
            />
            <Unidade isDisabled width={['35%', '35%', '35%', '30%']} />
            <MesDeCompetencia isDisabled width={['40%', '40%', '40%', '36%']} />
            <Vencimento isDisabled width={['40%', '40%', '40%', '36%']} />
          </GrupoDeCampos>

          <DadosDoTitular />

          <GrupoDeCampos titulo="Pagamento">
            <ValorFatura isDisabled width={['35%', '35%', '35%', '30%']} />
            <DataPagamento width={['40%', '40%', '40%', '36%']} />
            <ValorCorrigido isDisabled width={['35%', '35%', '35%', '30%']} />
            <ValorPagamento width={['35%', '35%', '35%', '30%']} />
            <TipoDaBaixa />
            <Observacao />
          </GrupoDeCampos>

          <BotaoLiquidarFatura
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            isCamposObrigatoriosPreenchidos={isCamposObrigatoriosPreenchidos}
            isDisabled={isSubmitted}
          />

          <Button
            ml={4}
            mt="2rem"
            variantColor="red"
            variant="outline"
            type="button"
            isDisabled={isSubmitting}
            onClick={() => history.push(`${rota.faturas.url}/todas`)}
          >
            Cancelar
          </Button>
        </form>
      </Box>
    </>
  );
}

const validationSchema = yup.object().shape({
  dataPagamento: yup.string().required('Campo obrigatório'),
  valorPagamento: yup.string().required('Campo obrigatório'),
  tipoDaBaixa: yup.string().required('Campo obrigatório')
});

const formLogica = {
  validationSchema,
  mapPropsToValues: props => {
    const getFromProps = partialRight(partial(get, [props.data]), ['']);
    const getFromTitular = partialRight(
      partial(get, [getFromProps('titular')]),
      ['']
    );

    return {
      id: getFromProps('id'),
      referencia: getFromProps('referencia'),
      nomeTitular: getFromTitular('nome'),
      identidadeTitular: getFromTitular('identidade'),
      tipoTitular: getFromTitular('tipo'),
      competencia: formatarData({
        data: getFromProps('vencimento'),
        formato: 'MMMM (MM)'
      }),
      valor: getFromProps('valor'),
      valorMulta: getFromProps('multa'),
      valorJuros: getFromProps('juros'),
      itens: getFromProps('itens'),
      vencimento: formatarData({ data: getFromProps('vencimento') }),
      dataPagamento: '',
      valorCorrigido: '',
      valorPagamento: '',
      observacao: '',
      tipoDaBaixa: ''
    };
  },
  handleSubmit: (value, { setSubmitting, setStatus, setFieldValue, props }) => {
    const {
      id,
      valorPagamento,
      dataPagamento,
      tipoDaBaixa,
      observacao
    } = value;

    const { desconectarUsuario } = props;

    postLiquidarFatura({
      idFatura: id,
      data: {
        valorPagamento: moedaBRLToInt(valorPagamento),
        dataPagamento,
        meio: tipoDaBaixa,
        observacao
      }
    })
      .then(result => {
        const idDoRegistroCriado = recuperaIdDoRegistroCriado(result);

        if (!isEmpty(idDoRegistroCriado)) {
          setFieldValue('id', idDoRegistroCriado, false);
        }

        setStatus({
          tipo: 'success',
          mensagem: `Liquidação da fatura realizada com sucesso`
        });
      })
      .catch(error => {
        const setError = mensagemDeErro =>
          setStatus({
            tipo: 'error',
            mensagem: mensagemDeErro
          });

        handleHTTPError({
          error,
          handle400Error: setError,
          handle401Error: desconectarUsuario,
          handle403Error: setError,
          handleGenericError: setError
        });
      })
      .finally(() => setSubmitting(false));
  },
  displayName: 'FormLiquidacaoManualDaFatura'
};

const FormLiquidacaoManualDaFatura = withFormik(formLogica)(FormApresentacao);

export { FormLiquidacaoManualDaFatura };
