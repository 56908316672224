/**
 * Dependências externas
 */
import React from 'react';
import get from 'lodash/get';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';

const Data = props => {
  const nomeDoCampo = 'data';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const valor = get( props, `formik.values[${nomeDoCampo}]` );
  const erro = get( props, `formik.errors[${nomeDoCampo}]` );
  const touched = get( props, `formik.touched[${nomeDoCampo}]` );

  const possuiErroDeValidacao = erro && touched;

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Data</FormLabel>
      <Input
        type="date"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik( Data );
