/**
 * Dependências externas
 */
import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import {
  Heading,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import rota from '../../constants/rota';
import NenhumRegistro from '../../apresentacao/NenhumRegistro';
import Carregando from '../../apresentacao/Carregando';
import Mensagem from '../../apresentacao/Mensagem';
import Table from '../../apresentacao/tabela/Table';
import Thead from '../../apresentacao/tabela/Thead';
import Th from '../../apresentacao/tabela/Th';
import Td from '../../apresentacao/tabela/Td';
import Tr from '../../apresentacao/tabela/Tr';
import tabelaReducer from '../../apresentacao/tabela/utils/tabelaReducer';
import getTiposDeLancamento from 'paginas/utils/getTiposDeLancamento';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';

const TodasUnidades = props => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: undefined,
    registrosExcluidos: [],
    mensagem: undefined
  });

  const registrosMemoized = useMemo(
    () =>
      isObject(state.registros) &&
      map(state.registros, tipoDeLancamento => (
        <Tr key={tipoDeLancamento.id}>
          <Td>
            <Link
              as={ReachLink}
              to={`${rota.tiposDeLancamento.url}/editar/${tipoDeLancamento.id}`}
              color="blue.600"
            >
              {tipoDeLancamento.conceito}
            </Link>
          </Td>
          <Td>
            {'ADMINISTRACAO' === tipoDeLancamento.receita
              ? 'Administração'
              : 'Condomínio'}
          </Td>
          <Td>
            {'ADMINISTRACAO' === tipoDeLancamento.despesa
              ? 'Administração'
              : 'Condomínio'}
          </Td>
          <Td>
            {'UNIDADE' === tipoDeLancamento.tipoRateio
              ? 'Unidade'
              : 'Fração ideal'}
          </Td>
          <Td style={{ textAlign: 'right' }}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Ações"
                icon="tresPontosVertical"
                variant="ghost"
                fontSize="38px"
                color="gray.400"
              />

              <MenuList>
                <MenuItem
                  onClick={() =>
                    props.history.push(
                      `${rota.tiposDeLancamento.url}/editar/${tipoDeLancamento.id}`
                    )
                  }
                >
                  <Link
                    to={`${rota.tiposDeLancamento.url}/editar/${tipoDeLancamento.id}`}
                  >
                    Editar
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </Tr>
      )),
    [state.registros, props.history]
  );

  useEffect(() => {
    dispatch({ type: 'carregarRegistros' });
  }, []);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const resultado = await getTiposDeLancamento();
          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: resultado.data
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }
  }, [state.carregando, desconectarUsuario]);

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Todos
      </Heading>

      {!isEmpty(state.mensagem) && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={() => dispatch({ type: 'limparMensagem' })}
            marginBottom="5"
            width={['100%', '100%', '100%', '80%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.erro && <p>Ocorreu um erro..</p>}

      {!state.erro && state.carregando && <Carregando align="center" />}

      {!state.erro &&
        !state.carregando &&
        isObject(registrosMemoized) &&
        (isEmpty(registrosMemoized) ? (
          <NenhumRegistro width={['100%', '100%', '100%', '90%']} />
        ) : (
          <Box
            paddingTop="1"
            shadow="md"
            borderWidth="1px"
            backgroundColor="#fff"
            width={['100%', '100%', '100%', '90%']}
          >
            <Table>
              <Thead>
                <tr>
                  <Th>Conceito</Th>
                  <Th>Receita</Th>
                  <Th>Despesa</Th>
                  <Th>Tipo de rateio</Th>
                  <Th />
                </tr>
              </Thead>
              <tbody>{registrosMemoized}</tbody>
            </Table>
          </Box>
        ))}
    </>
  );
};

export default TodasUnidades;
