const customStyles = {
  control: (styles, state) => ({
    ...styles,
    minHeight: '2.5rem',
    borderColor: state.isFocused ? '#3182ce' : 'inherit',
    boxShadow: state.isFocused && '0 0 0 1px #3182ce',
    ':hover': {
      ...styles[':hover'],
      borderColor: '#cbd5e0'
    }
  })
};

export default customStyles;
