/**
 * Dependências externas
 */
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import { withFormik, FormikProps, FormikBag } from 'formik';
import * as yup from 'yup';
import { Button } from '@chakra-ui/core';
import axios from 'axios';

/**
 * Dependências internas
 */
import api from 'utils/api';
import autenticacaoURL from 'constants/autenticacaoURL';
import Mensagem from 'apresentacao/Mensagem';

/**
 * Campos do formulário
 */
import Usuario from './campos/Usuario';
import Senha from './campos/Senha';

/**
 * Types
 */
import { LoginProps } from '../login';

interface FormValues {
  usuario: string;
  senha: string;
}

function FormApresentacao({
  handleSubmit,
  isSubmitting,
  status,
  setStatus,
  sessaoExpirou
}: { sessaoExpirou: boolean } & FormikProps<FormValues>) {
  useEffect(() => {
    if (sessaoExpirou) {
      setStatus({
        tipo: 'warning',
        mensagem: 'Sua sessão expirou.'
      });
    }
  }, [sessaoExpirou, setStatus]);

  const possuiMensagem = !isEmpty(status);

  return (
    <form onSubmit={handleSubmit}>
      {possuiMensagem && (
        <Mensagem
          tipo={status.tipo}
          fechar={() => setStatus(undefined)}
          mb="1.25rem"
        >
          {status.mensagem}
        </Mensagem>
      )}

      <Usuario />

      <Senha mt={3} />

      <Button
        mt={4}
        variantColor="teal"
        width="100%"
        type="submit"
        isLoading={isSubmitting}
      >
        Acessar
      </Button>
    </form>
  );
}

const validacaoCampos = yup.object().shape({
  usuario: yup.string().required('Campo obrigatório'),
  senha: yup.string().required('Campo obrigatório')
});

const formLogica = {
  mapPropsToValues: () => ({
    usuario: '',
    senha: ''
  }),
  validationSchema: validacaoCampos,
  handleSubmit: (
    values: FormValues,
    { setSubmitting, setStatus, props }: FormikBag<LoginProps, FormValues>
  ) => {
    const { usuario, senha } = values;

    const params = new URLSearchParams();
    params.append('user', usuario);
    params.append('password', senha);

    setStatus(undefined);
    axios
      .post(autenticacaoURL, params)
      .then(result => {
        const accessToken = result.data.access_token;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('usuario', upperFirst(usuario));
        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        props.depoisDeAutenticar();
      })
      .catch(() => {
        setStatus({
          tipo: 'error',
          mensagem: 'Usuário e/ou senha incorretos.'
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
  displayName: 'LoginForm'
};

const Form = withFormik<LoginProps, FormValues>(formLogica)(FormApresentacao);

export default Form;
