/**
 * Dependências externas
 */
import React, { useReducer, useEffect, useMemo, useContext } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  Heading,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link
} from '@chakra-ui/core';

/**
 * Dependências internas
 */
import rota from 'constants/rota';
import Carregando from 'apresentacao/Carregando';
import Mensagem from 'apresentacao/Mensagem';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import tabelaReducer from 'apresentacao/tabela/utils/tabelaReducer';
import api from 'utils/api';
import FormAcessarUnidade from './FormAcessarUnidade';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';

const getUnidades = () => api.get(`unidades`);

const TodasUnidades = props => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: [],
    registrosExcluidos: [],
    mensagem: undefined
  });

  const registrosMemoized = useMemo(
    () =>
      map(state.registros, unidade => (
        <Tr key={unidade.id}>
          <Td>
            <Link
              as={ReachLink}
              to={`${rota.unidades.url}/editar/${unidade.id}`}
              color="blue.600"
            >
              {unidade.id}
            </Link>
          </Td>
          <Td>{unidade.proprietario && unidade.proprietario.nome}</Td>
          <Td>{unidade.inquilino && unidade.inquilino.nome}</Td>
          <Td style={{ textAlign: 'right' }}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Ações"
                icon="tresPontosVertical"
                variant="ghost"
                fontSize="38px"
                color="gray.400"
              />

              <MenuList>
                <MenuItem
                  onClick={() =>
                    props.history.push(
                      `${rota.unidades.url}/editar/${unidade.id}`
                    )
                  }
                >
                  <Link to={`${rota.unidades.url}/editar/${unidade.id}`}>
                    Editar
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </Tr>
      )),
    [state.registros, props.history]
  );

  useEffect(() => {
    dispatch({ type: 'carregarRegistros' });
  }, []);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const resultado = await getUnidades();
          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: resultado.data
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }
  }, [state.carregando, desconectarUsuario]);

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Todas
      </Heading>

      {!isEmpty(state.mensagem) && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={() => dispatch({ type: 'limparMensagem' })}
            marginBottom="5"
            width={['100%', '100%', '100%', '90%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.erro && <p>Ocorreu um erro..</p>}

      {!state.erro && state.carregando && <Carregando align="center" />}

      {!state.erro && !state.carregando && !isEmpty(registrosMemoized) && (
        <>
          <FormAcessarUnidade />
          <Box
            paddingTop="1"
            shadow="md"
            borderWidth="1px"
            backgroundColor="#fff"
            width={['100%', '100%', '100%', '90%']}
          >
            <Table>
              <Thead>
                <tr>
                  <Th>Código</Th>
                  <Th>Proprietário</Th>
                  <Th>Inquilino</Th>
                  <Th />
                </tr>
              </Thead>
              <tbody>{registrosMemoized}</tbody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
};

export default TodasUnidades;
