/**
 * Dependências externas
 */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import parseInt from 'lodash/parseInt';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Button, Stack, Box } from '@chakra-ui/core';
import moment from 'moment';

/**
 * Dependências internas
 */
import rota from 'constants/rota';
import Mensagem from 'apresentacao/Mensagem';
import recuperaIdDoRegistroCriado from 'utils/form/recuperaIdDoRegistroCriado';
import handleHTTPError from 'paginas/utils/handleHTTPError';

/**
 * Campos do formulário
 */
import Data from './campos/Data';
import Descricao from './campos/Descricao';
import Tipo from './campos/Tipo';
import Unidade from './campos/Unidade';
import Valor from './campos/Valor';
import Observacao from './campos/Observacao';
import api from 'utils/api';

const postLancamentos = values => api.post('lancamentos', values);

const FormApresentacao = props => {
  const { handleSubmit, isSubmitting, status, setStatus, values } = props;

  const possuiMensagem = !isEmpty(status);

  return (
    <>
      {possuiMensagem && (
        <Mensagem
          tipo={status.tipo}
          fechar={() => setStatus(undefined)}
          mb="1.25rem"
          width={['100%', '100%', '100%', '70%']}
        >
          {status.mensagem}
        </Mensagem>
      )}
      <Box
        p={5}
        shadow="md"
        borderWidth="1px"
        backgroundColor="#fff"
        width={['100%', '100%', '100%', '70%']}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Data width={['40%', '40%', '40%', '36%']} />

            <Tipo />

            <Unidade />

            <Valor width={['35%', '35%', '35%', '30%']} />

            <Descricao />

            <Observacao />
          </Stack>

          <Button
            mt="2rem"
            variantColor="blue"
            type="submit"
            isLoading={isSubmitting}
          >
            {values.id ? 'Atualizar' : 'Salvar'}
          </Button>

          <Button
            ml={4}
            mt="2rem"
            variantColor="red"
            variant="outline"
            type="button"
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>
        </form>
      </Box>
    </>
  );
};

const validacaoCampos = yup.object().shape({
  data: yup.string().required('Campo obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
  tipo: yup.string().required('Campo obrigatório'),
  unidade: yup.string().required('Campo obrigatório'),
  valor: yup.string().required('Obrigatório')
});

const formLogica = {
  mapPropsToValues: () => ({
    id: '',
    data: moment().format('YYYY-MM-DD'),
    descricao: '',
    tipo: '',
    unidade: '',
    valor: '',
    observacao: ''
  }),
  validationSchema: validacaoCampos,
  handleSubmit: (value, { setSubmitting, setStatus, setFieldValue, props }) => {
    const { id, tipo, unidade, valor, ...othersValeus } = value;

    const { desconectarUsuario } = props;

    if (id) {
      console.log(id);
    } else {
      postLancamentos({
        ...othersValeus,
        valor: parseInt(replace(valor, /[^0-9]/g, '')),
        tipoLancamentoId: tipo.value,
        agrupadorId: unidade.value
      })
        .then(result => {
          const idDoRegistroCriado = recuperaIdDoRegistroCriado(result);

          if (!isEmpty(idDoRegistroCriado)) {
            setFieldValue('id', idDoRegistroCriado, false);
          }

          setStatus({
            tipo: 'success',
            mensagem: `${rota.lancamento.tituloSingular} salvo com sucesso`
          });
        })
        .catch(error => {
          const setError = mensagemDeErro =>
            setStatus({
              tipo: 'error',
              mensagem: mensagemDeErro
            });

          handleHTTPError({
            error,
            handle400Error: setError,
            handle401Error: desconectarUsuario,
            handle403Error: setError,
            handleGenericError: setError
          });
        })
        .finally(() => setSubmitting(false));
    }
  },
  displayName: 'LancamentoForm'
};

const Form = withFormik(formLogica)(FormApresentacao);

export default Form;
