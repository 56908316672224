/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';

const Numero = props => {
  const { formik, isLoading, isDisabled, cobranca, ...rest } = props;

  const nomeDoCampo = cobranca ? 'numeroCobranca' : 'numero';
  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={possuiErroDeValidacao}
      {...rest}
    >
      <FormLabel htmlFor={nomeDoCampo}>Número</FormLabel>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valor}
        autoComplete="off"
        isDisabled={isLoading || isDisabled}
      />

      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(Numero);
