/**
 * Dependências externas
 */
import React from 'react';
import Select from 'react-select';

/**
 * Dependências internas
 */
import style from './style';

const SelectSimples: React.FC = props => (
  <Select
    isSearchable
    isClearable
    styles={style}
    {...props}
    placeholder=""
    noOptionsMessage={() => 'Nenhum resultado'}
  />
);

export default SelectSimples;
