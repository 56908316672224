/**
 * Dependẽncias externas
 */
import React, { useState, useRef } from 'react';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  Button,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/core';
import FocusLock from 'react-focus-lock';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

function Form({ inputRef, registrarSaida, isProcessing }) {
  const [inputValue, setInputValue] = useState(moment().format('YYYY-MM-DD'));

  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel htmlFor="dataSaida">Data de saída</FormLabel>
        <Input
          ref={inputRef}
          id="dataSaida"
          type="date"
          height="2rem"
          lineHeight="2rem"
          paddingX="0.5rem"
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
        />
      </FormControl>

      <Button
        onClick={() => registrarSaida(inputValue)}
        size="sm"
        isDisabled={isEmpty(inputValue) || isProcessing}
        isLoading={isProcessing}
        variantColor="blue"
      >
        Registrar saída
      </Button>
    </Stack>
  );
}

export default function RegistrarSaidaButton({ onClick, isProcessing }) {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const inputRef = useRef(null);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={open}
      onClose={close}
      usePortal={false}
      placement="top"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          variantColor="blue"
          aria-label="Registrar saída do inquilino"
          icon="saidaInquilino"
          size="sm"
          isDisabled={isProcessing}
        />
      </PopoverTrigger>
      <PopoverContent padding={3} width="215px">
        <PopoverArrow />
        <PopoverCloseButton />
        <FocusLock returnFocus persistentFocus={false}>
          <Form
            inputRef={inputRef}
            registrarSaida={onClick}
            isProcessing={isProcessing}
          />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}
