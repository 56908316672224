/**
 * Dependências externas
 */
import React, { useState, useEffect } from 'react';
import { Global, css } from '@emotion/core';
import { theme, ThemeProvider, CSSReset } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

/**
 * Dependências interas
 */
import icones from './constants/icones';
import rota from './constants/rota';
import api from 'utils/api';
import Carregando from 'apresentacao/Carregando';
import Login from './Login';
import Lancamentos from './paginas/Lancamentos';
import Unidades from './paginas/Unidades';
import BarraLateralEsquerda from './apresentacao/BarraLateralEsquerda';
import TiposDeLancamento from './paginas/TiposDeLancamento';
import Faturas from './paginas/Faturas';
import ArquivosRemessa from './paginas/ArquivosRemessa';
import ArquivosRetorno from './paginas/ArquivosRetorno';
import Inadimplencia from 'paginas/Inadimplencia';
import AutenticacaoContext from 'context/AutenticacaoContext';

const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...icones
  }
};

const EstiloGlobal = () => (
  <>
    <CSSReset />
    <Global
      styles={css`
        html,
        body,
        #root {
          min-height: 100%;
          height: 100%;
        }
      `}
    />
  </>
);

const PrivateRoute = props => {
  const {
    component: Component,
    autenticado,
    desconectarUsuario,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={props =>
        autenticado ? (
          <Component {...props} desconectarUsuario={desconectarUsuario} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

function App() {
  const [verificandoAutenticacao, setVerificandoAutenticacao] = useState(true);
  const [autenticado, setAutenticado] = useState();
  const [sessaoExpirou, setSessaoExpirou] = useState();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    if (!isEmpty(accessToken)) {
      setAutenticado(true);
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    setVerificandoAutenticacao(false);
  }, []);

  useEffect(() => {
    if (!isUndefined(autenticado) && !autenticado) {
      delete api.defaults.headers.common['Authorization'];
      localStorage.clear();
    }
  }, [autenticado]);

  const desconectarUsuario = (solicitadoPeloUsuario = false) => {
    setSessaoExpirou(!solicitadoPeloUsuario);
    setAutenticado(false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <EstiloGlobal />

      <Router>
        {verificandoAutenticacao ? (
          <Carregando align="center" style={{ marginTop: '30px' }} />
        ) : autenticado ? (
          <AutenticacaoContext.Provider value={desconectarUsuario}>
            <PrivateRoute
              component={BarraLateralEsquerda}
              autenticado={autenticado}
              desconectarUsuario={desconectarUsuario}
            />

            <Switch>
              <PrivateRoute
                path={rota.unidades.url}
                component={Unidades}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.tiposDeLancamento.url}
                component={TiposDeLancamento}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.faturas.url}
                component={Faturas}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.lancamento.url}
                component={Lancamentos}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.arquivosRemessa.url}
                component={ArquivosRemessa}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.arquivosRetorno.url}
                component={ArquivosRetorno}
                autenticado={autenticado}
              />

              <PrivateRoute
                path={rota.inadimplencia.url}
                component={Inadimplencia}
                autenticado={autenticado}
              />

              <Redirect to={rota.lancamento} />
            </Switch>
          </AutenticacaoContext.Provider>
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Login
                  depoisDeAutenticar={() => setAutenticado(true)}
                  sessaoExpirou={sessaoExpirou}
                />
              )}
            />

            <Redirect to="/" />
          </Switch>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
