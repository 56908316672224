/**
 * Dependências externas
 */
import React, {
  useReducer,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from 'react';
import map from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import partialRight from 'lodash/fp/partialRight';
import partial from 'lodash/fp/partial';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Heading,
  Box,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useToast
} from '@chakra-ui/core';
import ObjectsToCsv from 'objects-to-csv';
import { saveAs } from 'file-saver';

/**
 * Dependências internas
 */
import Carregando from 'apresentacao/Carregando';
import Mensagem from 'apresentacao/Mensagem';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import tabelaReducer from 'apresentacao/tabela/utils/tabelaReducer';
import api from 'utils/api';
import formatarData from 'paginas/utils/formatarData';
import FormArquivoRetorno from './FormArquivoRetorno';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';

const getArquivosRetorno = () => api.get(`arquivosretorno`);

function baixarArquivoRetorno({ id, nomeArquivo, onError }) {
  api
    .get(`arquivosretorno/${id}/registros`)
    .then(async resultado => {
      const data = get(resultado, 'data');

      const csvObject = new ObjectsToCsv(data);

      const csv = await csvObject.toString();

      const arquivo = new Blob([csv], {
        type: 'text/csv'
      });

      saveAs(arquivo, nomeArquivo);
    })
    .catch(onError);
}

function ArquivoRetornoElement(arquivoRetorno, onErrorBaixarArquivo) {
  return (
    <Tr key={arquivoRetorno.id}>
      <Td>{arquivoRetorno.sequencial}</Td>
      <Td style={{ wordBreak: 'break-word' }}>{arquivoRetorno.nome}</Td>
      <Td>
        {formatarData({
          data: arquivoRetorno.geracao,
          formato: 'DD/MM/YYYY HH:mm:ss'
        })}
      </Td>
      <Td>
        {formatarData({
          data: arquivoRetorno.recepcao,
          formato: 'DD/MM/YYYY HH:mm:ss'
        })}
      </Td>
      <Td>{arquivoRetorno.registros}</Td>
      <Td style={{ textAlign: 'right' }}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Ações"
            icon="tresPontosVertical"
            variant="ghost"
            fontSize="38px"
            color="gray.400"
          />

          <MenuList>
            <MenuItem
              onClick={() => {
                baixarArquivoRetorno({
                  id: arquivoRetorno.id,
                  nomeArquivo: `${formatarData({
                    data: arquivoRetorno.geracao,
                    formato: 'DDMMYY'
                  })}.csv`,
                  onError: onErrorBaixarArquivo
                });
              }}
            >
              Baixar
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

const TodosArquivosRetorno = () => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: [],
    registrosExcluidos: [],
    mensagem: undefined
  });

  const toast = useToast();

  const onErrorBaixarArquivo = useCallback(
    () =>
      toast({
        title: 'Ocorreu um erro.',
        description: 'Não foi possível baixar o arquivo',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      }),
    [toast]
  );

  const arquivosRetorno = useCallback(
    compose(
      partial(map, [
        partialRight(ArquivoRetornoElement, [onErrorBaixarArquivo])
      ]),
      partialRight(orderBy, [['id'], ['desc']])
    ),
    []
  );

  const carregarRegistros = () => {
    dispatch({ type: 'carregarRegistros', offset: 0 });
  };

  const registrosMemoized = useMemo(() => arquivosRetorno(state.registros), [
    state.registros,
    arquivosRetorno
  ]);

  useEffect(carregarRegistros, []);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const resultado = await getArquivosRetorno();

          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: resultado.data
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }
  }, [state.carregando, state.offset, desconectarUsuario]);

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Todos
      </Heading>

      {!isEmpty(state.mensagem) && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={() => dispatch({ type: 'limparMensagem' })}
            marginBottom="5"
            width={['100%', '100%', '100%', '90%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.erro && <p>Ocorreu um erro..</p>}

      {!state.erro && (
        <FormArquivoRetorno atualizarRegistrosCarregados={carregarRegistros} />
      )}

      {!state.erro && state.carregando && (
        <Box paddingTop="12" width={['100%', '100%', '100%', '90%']}>
          <Carregando align="center" />
        </Box>
      )}

      {!state.erro && !state.carregando && !isEmpty(registrosMemoized) && (
        <Box
          paddingTop="1"
          shadow="md"
          borderWidth="1px"
          backgroundColor="#fff"
          width={['100%', '100%', '100%', '90%']}
        >
          <Table>
            <Thead>
              <tr>
                <Th>Sequencial</Th>
                <Th>Arquivo</Th>
                <Th>Geração</Th>
                <Th>Recepção</Th>
                <Th>Registros</Th>
                <Th />
              </tr>
            </Thead>
            <tbody>{registrosMemoized}</tbody>
          </Table>
        </Box>
      )}
    </>
  );
};

export default TodosArquivosRetorno;
