/**
 * Dependências externas
 */
import React, { useEffect, useState, useContext } from 'react';
import { connect as connectToFormik } from 'formik';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import SelectPesquisavel from '../../../../../apresentacao/form/Select/SelectPesquisavel';
import apiURL from 'constants/apiURL';
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import useDataApi from 'hooks/useDataApi';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from "paginas/utils/handleHTTPError";

const Unidade = props => {
  const nomeDoCampo = 'unidade';

  const desconectarUsuario = useContext( AutenticacaoContext );

  const { formik, ...rest } = props;

  const { setFieldValue, setFieldTouched } = formik;

  const { data, carregando, erro: erroCarregarAgrupadores } = useDataApi(
    `${apiURL}agrupadores`
  );

  const [ options, setOptions ] = useState();

  useEffect( () => {
    if ( ! carregando && data ) {
      setOptions( () => data.map( opcao => ( { value: opcao, label: opcao } ) ) );
    }
  }, [ data, carregando ] );

  useEffect( () => {
    if ( erroCarregarAgrupadores ) {
      handleHTTPError( {
        error: erroCarregarAgrupadores, 
        handle401Error: desconectarUsuario, 
      } )
    }
  }, [ erroCarregarAgrupadores, desconectarUsuario ] )

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  return (
    <FormControl
      isInvalid={possuiErroDeValidacao || erroCarregarAgrupadores}
      {...rest}
    >
      <FormLabel id={`label-${nomeDoCampo}`}>Agrupador</FormLabel>

      <SelectPesquisavel
        aria-labelledby={`label-${nomeDoCampo}`}
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={value => {
          if ( value ) {
            setFieldValue( nomeDoCampo, value, false );
          } else {
            setFieldValue( nomeDoCampo, '', false );
          }
        }}
        onBlur={() => setFieldTouched( nomeDoCampo )}
        value={valor}
        options={options}
        isLoading={carregando}
      />

      {erroCarregarAgrupadores && (
        <FormErrorMessage>
          Ocorreu um erro ao carregar as opções
        </FormErrorMessage>
      )}
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik( Unidade );
