/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const Th = styled.th`
  padding: 0 10px;
  height: 50px;
  font-size: 11px;
  font-weight: 500;
  color: #7e8a96;
  vertical-align: middle;
  cursor: default;
  text-transform: uppercase;
  text-align: left;
`;

export default Th;
