/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import AreaPrincipal from 'apresentacao/AreaPrincipal';
import BarraSuperiorTiposDeLancamento from './apresentacao/BarraSuperiorTiposDeLancamento';
import ConteudoDaPagina from 'apresentacao/ConteudoDaPagina';
import Novo from './Novo';
import Editar from './Editar';
import TodosOsTiposDeLancamento from './TodosOsTiposDeLancamento';
import rota from 'constants/rota';

const TiposDeLancamento = props => {
  const path = get( props, 'match.path' );

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorTiposDeLancamento
          path={path}
          titulo={rota.tiposDeLancamento.titulo}
        />

        <ConteudoDaPagina>
          <Switch>
            <Route
              path={`${path}/todos`}
              exact
              component={TodosOsTiposDeLancamento}
            />
            <Route path={`${path}/novo`} exact component={Novo}></Route>
            <Route
              path={`${path}/editar/:idRegistro`}
              exact
              component={Editar}
            ></Route>

            <Redirect to={`${path}/todos`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
};

export default TiposDeLancamento;
