/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependências internas
 */
import BarraSuperior, { Link } from '../../../apresentacao/BarraSuperior';

const BarraSuperiorLancamento = props => {
  const { path, titulo } = props;

  return (
    <BarraSuperior
      path={path}
      titulo={titulo}
      style={{
        marginBottom: '1px',
        boxShadow: 'rgb(232, 232, 232) 0px 1px 0px 0px'
      }}
    >
      <Link to={`${path}/todos`} activeClassName="selected">
        Todos
      </Link>
      <Link to={`${path}/novo`} activeClassName="selected">
        Novo
      </Link>
    </BarraSuperior>
  );
};

export default BarraSuperiorLancamento;
