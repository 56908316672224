/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const ConteudoDaPagina = styled.div`
  margin: 30px 50px 0;
`;

export default ConteudoDaPagina;
