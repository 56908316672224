/**
 * Dependências externas
 */
import React from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';

const Usuario: React.FC = props => {
  const nomeDoCampo = 'usuario';
  const [field, meta] = useField(nomeDoCampo);

  const valor = field.value;
  const erro = meta.error;
  const touched = meta.touched;

  const possuiErroDeValidacao = !!erro && touched;

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...props}>
      <FormLabel htmlFor="usuario">Usuário</FormLabel>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={field.onChange}
        value={valor}
        autoFocus
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default Usuario;
