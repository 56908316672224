/**
 * Dependências externas
 */
import React, {
  useCallback,
  useReducer,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import ordeBy from 'lodash/orderBy';
import keys from 'lodash/keys';
import {
  Heading,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure
} from '@chakra-ui/core';
import moment from 'moment';

/**
 * Dependências internas
 */
import rota from 'constants/rota';
import tabelaReducer from 'apresentacao/tabela/utils/tabelaReducer';
import Mensagem from 'apresentacao/Mensagem';
import Carregando from 'apresentacao/Carregando';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import AlertaExclusao from 'apresentacao/AlertaExclusao';
import formatarMoeda from 'paginas/utils/formatarMoeda';
import formatarData from 'paginas/utils/formatarData';
import api from 'utils/api';
import DatePickerWithNavigation from 'apresentacao/date/DatePickerWithNavigation';
import datePickerReducer from 'apresentacao/date/datePickerReducer';
import NenhumRegistro from 'apresentacao/NenhumRegistro';
import BarraFiltroLancamento from './apresentacao/BarraFiltroLancamento';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import getQueryStringFiltro from 'paginas/utils/getQueryStringFiltro';

const getLancamentos = ({
  offset,
  limit = 100,
  filtrarPor,
  filtroData = ''
}) => {
  const queryStringFiltro = getQueryStringFiltro(filtrarPor);

  return api.get(`lancamentos?${queryStringFiltro}&${filtroData}`);
};

const deleteLancamento = id => api.delete(`lancamentos/${id}`);

const TodosLancamentos = props => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filtrarPor, setFiltrarPor] = useState({});

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: [],
    registrosExcluidos: [],
    mensagem: undefined
  });

  const [stateDataFiltro, dispatchDataFiltro] = useReducer(datePickerReducer, {
    isOpen: false,
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('month')
      .toDate(),
    enteredTo: moment()
      .endOf('month')
      .toDate()
  });

  const registroExcluido = useCallback(() => {
    dispatch({
      type: 'registroExcluido',
      idRegistroExcluido: state.idRegistroASerExcluido
    });
  }, [state.idRegistroASerExcluido]);

  const registrosMemoized = useMemo(() => {
    const campos = keys(state.ordenacao);

    const classificacao = reduce(
      campos,
      (result, value) => {
        return [...result, lowerCase(state.ordenacao[value].forma)];
      },
      []
    );

    const lancamentosOrdenados = ordeBy(state.registros, campos, classificacao);

    return map(lancamentosOrdenados, lancamento => {
      return (
        <Tr key={lancamento.id}>
          <Td>{lancamento.conceito}</Td>
          <Td>{lancamento.agrupador}</Td>
          <Td>{formatarData({ data: lancamento.data })}</Td>
          <Td>{formatarMoeda(lancamento.valor)}</Td>
          <Td style={{ textAlign: 'right' }}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Ações"
                icon="tresPontosVertical"
                variant="ghost"
                fontSize="38px"
                color="gray.400"
              />

              <MenuList>
                <MenuItem
                  color="red.500"
                  onClick={() =>
                    dispatch({
                      type: 'excluirRegistro',
                      idRegistro: lancamento.id,
                      nomeRegistro: `${lancamento.conceito} do ${lancamento.agrupador}`
                    })
                  }
                >
                  Excluir
                </MenuItem>
              </MenuList>
            </Menu>
          </Td>
        </Tr>
      );
    });
  }, [state.registros, state.ordenacao]);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const resultado = await getLancamentos({
            offset: state.offset,
            filtrarPor,
            filtroData: state.filtroData
          });
          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: resultado.data
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }
  }, [
    state.carregando,
    state.offset,
    filtrarPor,
    state.filtroData,
    desconectarUsuario
  ]);

  useEffect(() => {
    dispatch({ type: 'carregarRegistros', offset: 0 });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'carregarRegistros',
      offset: 0,
      filtroData: state.filtroData
    });
  }, [filtrarPor, state.filtroData]);

  useEffect(() => {
    if (state.excluindo) {
      onOpen();
    }
  }, [state.excluindo, onOpen]);

  useEffect(() => {
    if (!stateDataFiltro.isOpen) {
      if (stateDataFiltro.from && stateDataFiltro.to) {
        const dataInicio = formatarData({
          data: stateDataFiltro.from,
          formato: 'YYYY-MM-DD'
        });
        const dataFim = formatarData({
          data: stateDataFiltro.to,
          formato: 'YYYY-MM-DD'
        });
        const filtroData = `start=${dataInicio}&end=${dataFim}`;

        if (filtroData !== state.filtroData) {
          dispatch({ type: 'carregarRegistros', offset: 0, filtroData });
        }
      } else {
        if (!isEmpty(state.filtroData)) {
          dispatch({ type: 'carregarRegistros', offset: 0 });
        }
      }
    }
  }, [
    stateDataFiltro.isOpen,
    stateDataFiltro.from,
    stateDataFiltro.to,
    state.filtroData
  ]);

  return (
    <>
      <BarraFiltroLancamento
        setFiltrarPor={setFiltrarPor}
        filtrarPor={filtrarPor}
      />

      <Heading as="h2" size="lg" marginBottom="20px">
        Todos
      </Heading>

      {!state.erro && (
        <Box marginBottom="20px">
          <DatePickerWithNavigation
            isOpen={stateDataFiltro.isOpen}
            from={stateDataFiltro.from}
            to={stateDataFiltro.to}
            enteredTo={stateDataFiltro.enteredTo}
            dispatch={dispatchDataFiltro}
            isDisabled={state.carregando}
          />
        </Box>
      )}

      {!isEmpty(state.mensagem) && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={() => dispatch({ type: 'limparMensagem' })}
            marginBottom="5"
            width={['100%', '100%', '100%', '90%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.erro && <p>Ocorreu um erro..</p>}

      {!state.erro && state.carregando && <Carregando align="center" />}

      {!state.erro && !state.carregando && isEmpty(registrosMemoized) && (
        <NenhumRegistro />
      )}

      {!state.erro && !state.carregando && !isEmpty(registrosMemoized) && (
        <>
          <Box
            paddingTop="1"
            shadow="md"
            borderWidth="1px"
            backgroundColor="#fff"
            width={['100%', '100%', '100%', '90%']}
          >
            <Table>
              <Thead>
                <tr>
                  <Th>
                    <Box d="flex" alignItems="center">
                      Conceito
                    </Box>
                  </Th>
                  <Th>
                    <Box d="flex" alignItems="center">
                      Agrupador
                    </Box>
                  </Th>
                  <Th>Data</Th>
                  <Th>Valor</Th>
                  <Th />
                </tr>
              </Thead>
              <tbody>{registrosMemoized}</tbody>
            </Table>
          </Box>
          {/* <ButtonGroup spacing={10} marginTop="6">
            {state.offset > 49 && (
              <Button
                leftIcon="arrow-back"
                variantColor="blue"
                variant="link"
                onClick={() =>
                  dispatch( {
                    type: 'carregarRegistros',
                    offset: state.offset - 50
                  } )
                }
              >
                Voltar
              </Button>
            )}
            <Button
              rightIcon="arrow-forward"
              variantColor="blue"
              variant="link"
              onClick={() =>
                dispatch( {
                  type: 'carregarRegistros',
                  offset: state.offset + 50
                } )
              }
            >
              Próxima página
            </Button>
          </ButtonGroup> */}
        </>
      )}

      {isOpen && (
        <AlertaExclusao
          tipoRegistro={rota.lancamento.tituloSingular?.toLowerCase()}
          idRegistro={state.idRegistroASerExcluido}
          nomeIdentificaçãoRegistro={state.nomeRegistroASerExcluido}
          onClose={onClose}
          onCancel={() => {
            dispatch({ type: 'cancelarExclusaoDoRegistro' });
            onClose();
          }}
          onSuccess={registroExcluido}
          excluir={deleteLancamento}
        />
      )}
    </>
  );
};

export default TodosLancamentos;
