/**
 * Dependências externas
 */
import React from 'react';
import { Heading } from '@chakra-ui/core';
import lowerCase from 'lodash/lowerCase';

/**
 * Dependências internas
 */
import rota from 'constants/rota';
import Form from './apresentacao/Form';

const tituloSingularComLetrasMinusculas = lowerCase(
  rota.tiposDeLancamento.tituloSingular
);

const Editar = () => {
  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        {`Editar ${tituloSingularComLetrasMinusculas}`}
      </Heading>

      <Form />
    </>
  );
};

export default Editar;
