import React from 'react';
import { Button, Box, Collapse } from '@chakra-ui/core';

import GrupoDeCampos from 'apresentacao/form/GrupoDeCampos';
import FracaoIdeal from '../campos/FracaoIdeal';
import GrupoDeCamposEndereco from '../campos/endereco/GrupoDeCamposEndereco';

export default function InformacoesAdicionais() {
  const [show, setShow] = React.useState(false);
  return (
    <Box marginTop="5">
      <Button onClick={() => setShow(prev => !prev)} variant="outline">
        {`${show ? 'Ocultar' : 'Exibir'} informações adicionais`}
      </Button>
      <Collapse marginTop="4" isOpen={show}>
        <GrupoDeCampos>
          <FracaoIdeal isDisabled width={['25%', '25%', '25%', '20%']} />
        </GrupoDeCampos>

        <GrupoDeCamposEndereco titulo="Endereço" />
      </Collapse>
    </Box>
  );
}
