/**
 * Dependências externas
 */
import isEqual from 'lodash/fp/isEqual';
import partialRight from 'lodash/fp/partialRight';
import compose from 'lodash/fp/compose';
import getHttpStatusError from './getHttpStatusError';

const is401Error = compose(partialRight(isEqual, [401]), getHttpStatusError);

export default is401Error;
