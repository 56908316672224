/**
 * Dependências externas
 */
import reduce from 'lodash/reduce';

/**
 * Converte um object para uma query string que será utilizada para filtrar os resultados.
 * @param {Object} filtrarPor Object com a data e o formato.
 * @returns {string}
 */
export default function getQueryStringFiltro(filtrarPor: object) {
  return reduce(
    filtrarPor,
    (result, value, tipoFiltro) => {
      const queryStringFiltro = reduce(
        value,
        (result, value, valorFiltro) => {
          return result
            ? `${result}&${tipoFiltro}=${valorFiltro}`
            : `${tipoFiltro}=${valorFiltro}`;
        },
        ''
      );

      return result ? `${result}&${queryStringFiltro}` : queryStringFiltro;
    },
    ''
  );
}
