/**
 * Dependências externas
 */
import React, { useRef, useState, useContext } from 'react';
import { Box, Input, Button, FormErrorMessage } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import api from 'utils/api';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';

function FormArquivoRetorno(props: {
  atualizarRegistrosCarregados: () => void;
}) {
  const fileInput = useRef<HTMLInputElement>(null);
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [isEnviando, setEnviando] = useState(false);
  const [erro, setErro] = useState<boolean | string>(false);

  const handleEnvioArquivoRetorno = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const file =
      fileInput &&
      fileInput.current &&
      fileInput.current.files &&
      fileInput.current.files[0];

    setEnviando(true);
    setErro(false);
    api
      .post('arquivosretorno/receber', file, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `attachment; filename="${file?.name}"`
        }
      })
      .then(() => {
        (document.getElementById(
          'formArquivoRetorno'
        ) as HTMLFormElement).reset();
        props.atualizarRegistrosCarregados();
      })
      .catch(error => {
        handleHTTPError({
          error,
          handle400Error: mensagem => setErro(mensagem),
          handle401Error: desconectarUsuario,
          handle403Error: mensagem => setErro(mensagem),
          handleGenericError: mensagem => setErro(mensagem)
        });
      })
      .finally(() => {
        setEnviando(false);
      });
  };

  return (
    <form
      id="formArquivoRetorno"
      onSubmit={handleEnvioArquivoRetorno}
      style={{ marginBottom: '1.25rem' }}
    >
      <Box d="flex" alignItems="center">
        <Input
          ref={fileInput}
          type="file"
          // @ts-ignore
          width={['50%', '50%', '50%', '25%']}
          paddingX="0"
          height="100%"
          lineHeight="1.2rem"
          backgroundColor="transparent"
          border="none"
        />
        <Button
          type="submit"
          marginLeft="3"
          variant="outline"
          variantColor="blue"
          isLoading={isEnviando}
        >
          Enviar arquivo
        </Button>
      </Box>
      {erro && (
        <FormErrorMessage
          // @ts-ignore
          isInvalid={true}
        >
          {erro}
        </FormErrorMessage>
      )}
    </form>
  );
}

export default FormArquivoRetorno;
