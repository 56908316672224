/**
 * Dependências externas
 */
import React from 'react';
import { TextAlignProperty } from 'csstype';
import { Spinner } from '@chakra-ui/core';

const Carregando: React.FC<{
  align?: TextAlignProperty;
  style?: React.CSSProperties;
  size?: 'xl' | 'xs' | 'sm' | 'md' | 'lg';
  thickness?: string;
  color?: 'blue.500' | 'gray.500';
}> = ({ align, style, size = 'xl', thickness = '4px', color = 'blue.500' }) => {
  return (
    <div style={{ display: 'flex', textAlign: align, ...style }}>
      <Spinner
        thickness={thickness}
        speed="0.65s"
        emptyColor="gray.200"
        color={color}
        size={size}
      />
    </div>
  );
};

export default Carregando;
