/**
 * Dependências externas
 */
import isEmpty from 'lodash/fp/isEmpty';

/**
 * Validação especial para os campos do formulário
 * de inquilinos na tela de unidades
 * @param {boolean} possuiErroDeValidacao Valor retornado do formik
 * @param {boolean} touched Informa se o campo já foi "tocado"
 * @param {string} valor Valor atual do campo
 */
export default function validaCampoInquilino({
  possuiErroDeValidacao,
  touched,
  valor
}: {
  possuiErroDeValidacao: boolean;
  touched: boolean;
  valor: string;
}) {
  return possuiErroDeValidacao || (touched && isEmpty(valor));
}
