/**
 * Dependências externas
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import apiURL from 'constants/apiURL';
import useDataApi from 'hooks/useDataApi';
import Carregando from 'apresentacao/Carregando';
import { FormLiquidacaoManualDaFatura } from './form/liquidacaoManualDaFatura/FormLiquidacaoManualDaFatura';

function LiquidacaoManualDaFatura() {
  const { idFatura } = useParams();

  const { data, carregando, erro } = useDataApi(`${apiURL}faturas/${idFatura}`);

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Liquidação manual de fatura
      </Heading>

      {erro && <p>Ocorreu um erro..</p>}

      {!erro && carregando && <Carregando align="center" />}

      {!erro && !carregando && data && (
        <>
          <FormLiquidacaoManualDaFatura data={data} />
        </>
      )}
    </>
  );
}

export { LiquidacaoManualDaFatura };
