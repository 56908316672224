/**
 * Dependências externas
 */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Heading, Stack, Icon, Box, Text, Button } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

/**
 * Dependências internas
 */
import rota from '../constants/rota';
import layout from '../constants/layout';

const EstiloLink = styled.div`
  a {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    border-radius: 8px;
    height: 32px;
    font-size: 0.875rem;

    &.selected {
      background-color: #484848;
    }
  }

  a,
  svg {
    color: #b8b8b8;

    &.selected,
    &.selected svg {
      color: #fff;
    }
  }

  &:hover {
    a,
    svg {
      color: #fff;
    }
  }
`;

function Link(props: { to: string; icone?: string; children: string }) {
  const { to, icone, children, ...rest } = props;

  return (
    <Box {...rest}>
      <EstiloLink>
        <NavLink
          to={to}
          activeClassName="selected"
          style={{ paddingLeft: !icone ? '10px' : undefined }}
        >
          {icone && <Icon name={icone} width="32px" />}
          {children}
        </NavLink>
      </EstiloLink>
    </Box>
  );
}

function Categoria(props: { children: string }) {
  return (
    <Box
      marginTop="10px"
      marginBottom="7px"
      borderTop="1px solid transparent"
      letterSpacing="0.4px"
      lineHeight="1"
    >
      <Text
        as="span"
        color="#7b7b7b"
        fontSize="11px"
        fontWeight="500"
        textTransform="uppercase"
        paddingX="10px"
      >
        {props.children}
      </Text>
    </Box>
  );
}

const BarraLateralEsquerdaComEstilo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${layout.larguraBarraLateral};
  min-height: 100%;
  background-color: #323232;
  padding: 0 15px;
`;

function IconSair() {
  return <Icon name="sair" marginLeft="5px" />;
}

const BarraLateralEsquerda = (props: {
  desconectarUsuario: (solicitadoPeloUsuario?: boolean) => void;
}) => {
  const [nomeUsuario, setNomeUsuario] = useState('');

  useEffect(() => {
    const nomeUsuarioLocalStorage = localStorage.getItem('usuario');

    if (!!nomeUsuarioLocalStorage) {
      setNomeUsuario(nomeUsuarioLocalStorage);
    }
  }, []);

  return (
    <BarraLateralEsquerdaComEstilo>
      <Heading size="md" color="white" textAlign="center" mt={4} mb={6}>
        Condomínio
      </Heading>

      <Stack spacing={3}>
        <Categoria>Cadastro</Categoria>
        <Link to={rota.unidades.url} icone="unidade">
          {rota.unidades.titulo}
        </Link>
        <Link to={rota.tiposDeLancamento.url} icone="tiposDeLancamento">
          {rota.tiposDeLancamento.titulo}
        </Link>
        {/* <Link to={rota.centrosDeCusto.url} icone="centroDeCusto">
          {rota.centrosDeCusto.titulo}
        </Link> */}

        <Categoria>Faturamento</Categoria>
        {/* <Link to={rota.contratos.url} icone="contrato">
          {rota.contratos.titulo}
        </Link> */}
        <Link to={rota.lancamento.url} icone="lancamento">
          {rota.lancamento.titulo}
        </Link>
        <Link to={rota.faturas.url} icone="fatura">
          {rota.faturas.titulo}
        </Link>

        <Categoria>Cobrança</Categoria>
        <Link to={rota.arquivosRemessa.url} icone="arquivoRemessa">
          {rota.arquivosRemessa.titulo}
        </Link>
        <Link to={rota.arquivosRetorno.url} icone="arquivoRetorno">
          {rota.arquivosRetorno.titulo}
        </Link>

        <Categoria>Relatório</Categoria>
        <Link to={rota.inadimplencia.url} icone="relatorio">
          Inadimplência
        </Link>
      </Stack>

      <Box
        position="absolute"
        bottom="35px"
        width="calc(100% - 30px)"
        paddingLeft="10px"
      >
        {nomeUsuario && (
          <Text color="white" fontSize="0.875rem">
            {nomeUsuario}
          </Text>
        )}

        <Button
          rightIcon={IconSair}
          onClick={() => props.desconectarUsuario(true)}
          variantColor="teal"
          variant="link"
          minWidth="unset"
          fontSize="0.875rem"
        >
          Sair
        </Button>
      </Box>
    </BarraLateralEsquerdaComEstilo>
  );
};

export default BarraLateralEsquerda;
