type DatePickerState = {
  isOpen: boolean;
  from: Date | null;
  to: Date | null;
  enteredTo: Date | null;
  alterandoIsOpenState?: boolean;
};

export type ActionDatePicker =
  | { type: 'selecionaDataInicial'; day: Date }
  | { type: 'selecionaDataFinal'; day: Date }
  | { type: 'selecionaPeriodo'; from: Date; to: Date }
  | { type: 'destacaIntervalo'; day: Date }
  | { type: 'toggleIsOpen' }
  | { type: 'abrirCalendario' }
  | { type: 'fecharCalendario' }
  | { type: 'reset' };

/**
 * Reducer que controla os estados do date picker.
 * @param state O estado atual
 * @param action Ação para mudar o estado.
 * @returns {object}
 */
export default function datePickerReducer(
  state: DatePickerState,
  action: ActionDatePicker
): DatePickerState {
  switch (action.type) {
    case 'selecionaDataInicial':
      return {
        ...state,
        from: action.day,
        to: null,
        enteredTo: null
      };

    case 'selecionaDataFinal':
      return {
        ...state,
        to: action.day,
        enteredTo: action.day
      };

    case 'selecionaPeriodo':
      return {
        ...state,
        from: action.from,
        to: action.to,
        enteredTo: action.to
      };

    case 'destacaIntervalo':
      return {
        ...state,
        enteredTo: action.day
      };

    case 'toggleIsOpen':
      return {
        ...state,
        alterandoIsOpenState: true
      };

    case 'abrirCalendario':
      return {
        ...state,
        isOpen: true
      };

    case 'fecharCalendario':
      return {
        ...state,
        isOpen: false
      };

    case 'reset':
      return {
        ...state,
        from: null,
        to: null,
        enteredTo: null
      };

    default:
      throw new Error();
  }
}
