/**
 * Dependências externas
 */
import isFunction from 'lodash/isFunction';
import partialRight from 'lodash/fp/partialRight';

/**
 * Dependências internas
 */
import is400Error from './is400Error';
import is401Error from './is401Error';
import is403Error from './is403Error';
import is404Error from './is404Error';
import getMensagemDeErroHTTP from 'utils/getMensagemDeErroHTTP';

const getMensagemDeErro400HTTP = partialRight(getMensagemDeErroHTTP, [
  'Houve um erro devido aos dados enviados.'
]);

const getMensagemDeErro500HTTP = partialRight(getMensagemDeErroHTTP, [
  'Ocorreu um erro no servidor. Favor procurar o suporte técnico.'
]);

/**
 * Trata um erro HTTP.
 */
export default function handleHTTPError({
  error,
  handle400Error,
  handle401Error,
  handle403Error,
  handle404Error,
  handleGenericError
}: {
  error: any;
  handle400Error?: (mensagem: string) => void;
  handle401Error: () => void;
  handle403Error?: (mensagem: string) => void;
  handle404Error?: () => void;
  handleGenericError?: (mensagem: string) => void;
}) {
  if (error.response) {
    if (is400Error(error)) {
      if (handle400Error) {
        isFunction(handle400Error) &&
          handle400Error(getMensagemDeErro400HTTP(error));
      } else {
        isFunction(handleGenericError) &&
          handleGenericError(getMensagemDeErro400HTTP(error));
      }
    } else if (is401Error(error)) {
      isFunction(handle401Error) && handle401Error();
    } else if (is403Error(error)) {
      if (handle403Error) {
        isFunction(handle403Error) &&
          handle403Error('Usuário não está autorizado a executar essa ação.');
      } else {
        isFunction(handleGenericError) &&
          handleGenericError(
            'Usuário não está autorizado a executar essa ação.'
          );
      }
    } else if (is404Error(error)) {
      if (handle404Error) {
        isFunction(handle404Error) && handle404Error();
      } else {
        isFunction(handleGenericError) &&
          handleGenericError(getMensagemDeErro500HTTP(error));
      }
    } else {
      isFunction(handleGenericError) &&
        handleGenericError(getMensagemDeErro500HTTP(error));
    }
  } else {
    isFunction(handleGenericError) &&
      handleGenericError(
        'Ocorreu um erro no servidor. Favor procurar o suporte técnico.'
      );
  }
}
