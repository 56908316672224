/**
 * Dependências externas
 */
import parseInt from 'lodash/parseInt';
import replace from 'lodash/replace';

function moedaBRLToInt(valor: string) {
  return parseInt(replace(valor, /[^0-9]/g, ''));
}

export default moedaBRLToInt;
