/**
 * Dependências externas
 */
import styled from '@emotion/styled';

const Td = styled.td`
  padding: 0 10px;
  font-size: 14px;
  height: 50px;
  text-align: left;
  font-weight: 400;

  tr:not(:last-child) & {
    box-shadow: inset 0 -1px 0 0 #e8e8e8;
  }

  & > button {
    :hover {
      color: #1a202c;
    }
  }
`;

export default Td;
