/**
 * Dependências externas
 */
import React from 'react';
import { Heading } from '@chakra-ui/core';

/**
 * Dependências internas
 */
import Form from './apresentacao/Form';

const Novo = () => {
  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Nova unidade
      </Heading>

      <Form novo />
    </>
  );
};

export default Novo;
