const rota = {
  unidades: {
    titulo: 'Unidades',
    tituloSingular: 'Unidade',
    url: '/unidades'
  },
  centrosDeCusto: {
    titulo: 'Centros de custo',
    tituloSingular: 'Centro de custo',
    url: '/centros-de-custo'
  },
  tiposDeLancamento: {
    titulo: 'Tipos de lançamento',
    tituloSingular: 'Tipo de lançamento',
    url: '/tipos-de-lancamento'
  },
  contratos: {
    titulo: 'Contratos',
    tituloSingular: 'Contrato',
    url: '/contratos'
  },
  faturas: {
    titulo: 'Faturas',
    tituloSingular: 'Fatura',
    url: '/faturas'
  },
  lancamento: {
    titulo: 'Lançamentos',
    tituloSingular: 'Lançamento',
    url: '/lancamento'
  },
  arquivosRemessa: {
    titulo: 'Arquivos remessa',
    tituloSingular: 'Arquivo remessa',
    url: '/arquivos-remessa'
  },
  arquivosRetorno: {
    titulo: 'Arquivos retorno',
    tituloSingular: 'Arquivo retorno',
    url: '/arquivos-retorno'
  },
  inadimplencia: {
    titulo: 'Inadimplência',
    tituloSingular: 'Inadimplência',
    url: '/inadimplencia'
  }
};

export default rota;
