/**
 * Dependências externas
 */
import React, {
  useReducer,
  useEffect,
  useMemo,
  useContext,
  useState,
  useCallback
} from 'react';
import map from 'lodash/map';
import compose from 'lodash/fp/compose';
import partial from 'lodash/fp/partial';
import isEqual from 'lodash/fp/isEqual';
import partialRight from 'lodash/fp/partialRight';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import pickBy from 'lodash/pickBy';
import {
  Heading,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Scale,
  Flex,
  Tooltip,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  PopoverFooter,
  PopoverBody,
  PopoverHeader
} from '@chakra-ui/core';
import contentDisposition from 'content-disposition';
import moment from 'moment';
import { Link } from 'react-router-dom';

/**
 * Dependências internas
 */
import Carregando from 'apresentacao/Carregando';
import Mensagem from 'apresentacao/Mensagem';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import tabelaReducer from 'apresentacao/tabela/utils/tabelaReducer';
import api from 'utils/api';
import formatarMoeda from 'paginas/utils/formatarMoeda';
import formatarData from 'paginas/utils/formatarData';
import apiURL from 'constants/apiURL';
import datePickerReducer from 'apresentacao/date/datePickerReducer';
import NenhumRegistro from 'apresentacao/NenhumRegistro';
import AutenticacaoContext from 'context/AutenticacaoContext';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import BarraFiltroFaturas from 'paginas/Faturas/apresentacao/BarraFiltroFaturas';
import getQueryStringFiltro from 'paginas/utils/getQueryStringFiltro';
import DatePickerWithNavigation from 'apresentacao/date/DatePickerWithNavigation';

const getFaturas = ({ offset, limit = 50, filtrarPor, filtroData = '' }) => {
  const queryStringFiltro = getQueryStringFiltro(filtrarPor);

  return api.get(`faturas?${queryStringFiltro}&${filtroData}`);
};

function getFaturamento(queryStringPeriodo = '') {
  return api.get(`faturamento/competencia?${queryStringPeriodo}`);
}

function getRelatorioFaturas({ queryStringPeriodo, queryStringEstado }) {
  return api.get(
    `relatorios/faturas?${queryStringPeriodo}${
      queryStringEstado ? `&${queryStringEstado}` : ''
    }`,
    {
      responseType: 'blob'
    }
  );
}

function getRelatorioFaturadas(queryStringPeriodo) {
  return getRelatorioFaturas({ queryStringPeriodo });
}

function getRelatorioPagas(queryStringPeriodo) {
  return getRelatorioFaturas({
    queryStringPeriodo,
    queryStringEstado: 'estado=PAGA'
  });
}

function getRelatorioAVencer(queryStringPeriodo) {
  return getRelatorioFaturas({
    queryStringPeriodo,
    queryStringEstado: 'estado=ABERTA&estado=FECHADA'
  });
}

function getRelatorioVencidas(queryStringPeriodo) {
  return getRelatorioFaturas({
    queryStringPeriodo,
    queryStringEstado: 'estado=FECHADA'
  });
}

const getArquivoFaturas = ({ filtrarPor, filtroData = '' }) => {
  const queryStringFiltro = getQueryStringFiltro(filtrarPor);

  return api.get(`faturas/imprimir?${queryStringFiltro}&${filtroData}`, {
    responseType: 'blob'
  });
};

function postExecutarFechamento() {
  return api.post('faturas/fechar');
}

function downloadReducer(state, action) {
  switch (action.type) {
    case 'downloadInit':
      return {
        ...state,
        baixando: true,
        erro: false
      };

    case 'downloadSuccess':
      return {
        ...state,
        baixando: false,
        erro: false
      };

    case 'downloadError':
      return {
        ...state,
        baixando: false,
        erro: true,
        mensagem: action.payload.text,
        tipoMensagem: action.payload.type
      };

    case 'toggleError':
      return {
        ...state,
        erro: !state.erro
      };

    default:
      throw new Error();
  }
}

function downloadArquivo(nomeDoArquivo, arquivo) {
  const arquivoBlob = new Blob([arquivo]);
  const arquivoURL = URL.createObjectURL(arquivoBlob);

  const link = document.createElement('a');
  link.href = arquivoURL;
  link.download = nomeDoArquivo;
  link.click();

  URL.revokeObjectURL(arquivoURL);
}

const BotaoBaixarFaturas = props => {
  const {
    filtrarPor,
    filtroData,
    desconectarUsuario,
    definirMensagem,
    limparMensagem,
    isDisabled
  } = props;

  const [state, dispatch] = useReducer(downloadReducer, {
    baixando: false,
    erro: false
  });

  useEffect(() => {
    if (state.erro) {
      definirMensagem({
        status: state.tipoMensagem,
        mensagem: state.mensagem
      });
      dispatch({ type: 'toggleError' });
    }
  }, [state.erro, definirMensagem, state.mensagem, state.tipoMensagem]);

  useEffect(() => {
    let didCancel = false;

    if (state.baixando) {
      const downloadFaturas = async () => {
        try {
          const resultado = await getArquivoFaturas({
            filtrarPor,
            filtroData
          });
          if (!didCancel) {
            const getFromResultado = partial(get, [resultado]);

            const arquivoFaturas = getFromResultado('data');

            const nomeDoArquivo = compose(
              partialRight(get, ['parameters.filename', 'faturas.zip']),
              contentDisposition.parse,
              partial(getFromResultado, ['headers.content-disposition'])
            );

            downloadArquivo(nomeDoArquivo(), arquivoFaturas);

            dispatch({
              type: 'downloadSuccess'
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                }),
              handle404Error: () =>
                dispatch({
                  type: 'downloadError',

                  payload: {
                    text: 'Não existem faturas fechadas a serem baixadas.',
                    type: 'warning'
                  }
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                })
            });
          }
        }
      };

      downloadFaturas();
    }

    return () => {
      didCancel = true;
    };
  }, [state.baixando, desconectarUsuario, filtroData, filtrarPor]);

  return isEmpty(filtroData) ? (
    <Tooltip
      label="Informe um período para baixar as faturas"
      hasArrow
      placement="top"
      bg="gray.700"
    >
      <Button
        type="button"
        marginLeft="3"
        leftIcon="download"
        variant="ghost"
        variantColor="blue"
        isDisabled
      >
        Baixar faturas
      </Button>
    </Tooltip>
  ) : (
    <Button
      size="sm"
      type="button"
      marginLeft="3"
      leftIcon="download"
      variant="ghost"
      variantColor="blue"
      isLoading={state.baixando}
      loadingText="Baixar faturas"
      isDisabled={isDisabled || state.baixando || isEmpty(filtroData)}
      onClick={() => {
        limparMensagem();
        dispatch({ type: 'downloadInit' });
      }}
    >
      Baixar faturas
    </Button>
  );
};

function BotaoExecutarFechamento(props) {
  const {
    desconectarUsuario,
    definirMensagem,
    limparMensagem,
    recarregarRegistros,
    isDisabled
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = React.useCallback(() => setIsOpen(false), []);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [message, setMessage] = React.useState();

  const executarFechamento = () => {
    limparMensagem();
    setIsSubmitting(true);
    close();
  };

  React.useEffect(() => {
    if (message) {
      definirMensagem({
        status: message.status,
        mensagem: message.text
      });
    }
  }, [message, definirMensagem]);

  React.useEffect(() => {
    let didCancel = false;

    if (isSubmitting && !isDisabled) {
      postExecutarFechamento()
        .then(() => {
          if (!didCancel) {
            setMessage({
              status: 'success',
              text: 'O fechamento de faturas foi realizado. '
            });
            recarregarRegistros();
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                definirMensagem({
                  status: 'error',
                  mensagem: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                definirMensagem({
                  status: 'error',
                  mensagem: mensagemDeErro
                }),
              handle404Error: () =>
                definirMensagem({
                  status: 'error',
                  mensagem: 'Não existem faturas para executar o fechamento.'
                }),
              handleGenericError: mensagemDeErro =>
                definirMensagem({
                  status: 'error',
                  mensagem: mensagemDeErro
                })
            });
          }
        })
        .finally(() => {
          if (!didCancel) {
            setIsSubmitting(false);
          }
        });
    }

    return () => {
      didCancel = true;
    };
  }, [
    isSubmitting,
    definirMensagem,
    desconectarUsuario,
    limparMensagem,
    recarregarRegistros,
    isDisabled
  ]);

  return (
    <Popover isOpen={isOpen} onOpen={open} onClose={close} placement="bottom">
      <PopoverTrigger>
        <Button
          size="sm"
          leftIcon="cubo"
          variant="ghost"
          variantColor="blue"
          marginLeft="4"
          isDisabled={isDisabled || isSubmitting}
          isLoading={isSubmitting}
          loadingText="Executar fechamento"
        >
          Executar fechamento
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex="10">
        <PopoverArrow backgroundColor="white" />
        <PopoverCloseButton />

        <PopoverHeader>
          <Text fontSize="sm" fontWeight="bold">
            Atenção
          </Text>
        </PopoverHeader>

        <PopoverBody>
          <Text fontSize="sm">
            O fechamento somente deve ser executado após o cadastro de todos os
            lançamentos do período. Essa ação não poderá ser desfeita.
          </Text>

          <Text marginTop="2" fontSize="sm">
            Deseja continuar?
          </Text>
        </PopoverBody>

        <PopoverFooter>
          <Button variant="ghost" onClick={executarFechamento}>
            Sim
          </Button>
          <Button onClick={close} variant="ghost" type="button">
            Não
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function StatFaturamento({
  label,
  valor,
  helpText,
  getRelatorio,
  desconectarUsuario,
  definirMensagem
}) {
  const [state, dispatch] = useReducer(downloadReducer, {
    baixando: false,
    erro: false
  });

  useEffect(() => {
    if (state.erro) {
      definirMensagem({
        status: state.type,
        mensagem: state.mensagem
      });
      dispatch({ type: 'toggleError' });
    }
  }, [state.erro, definirMensagem, state.mensagem, state.type]);

  useEffect(() => {
    let didCancel = false;

    if (state.baixando) {
      getRelatorio()
        .then(resultado => {
          if (!didCancel) {
            downloadArquivo(`relatorio-${label}.pdf`, resultado.data);

            dispatch({
              type: 'downloadSuccess'
            });
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'downloadError',
                  payload: { text: mensagemDeErro, type: 'error' }
                })
            });
          }
        });
    }

    return () => {
      didCancel = true;
    };
  }, [state.baixando, getRelatorio, desconectarUsuario, label]);

  return (
    <Stat
      border="1px solid #e2e8f0"
      padding="3"
      backgroundColor="white"
      rounded="lg"
      shadow="md"
      flex={undefined}
    >
      <StatLabel as="div">
        <Flex justifyContent="space-between">
          {label}

          {!!valor && (
            <Tooltip
              label={`Gerar relatório ${label}`}
              hasArrow
              placement="top"
              aria-label={`Gerar relatório ${label}`}
            >
              <IconButton
                size="sm"
                variant="ghost"
                variantColor="blue"
                aria-label={`Gerar relatório ${label}`}
                icon="relatorio"
                isLoading={state.baixando}
                isDisabled={state.baixando}
                onClick={() => {
                  dispatch({ type: 'downloadInit' });
                }}
              />
            </Tooltip>
          )}
        </Flex>
      </StatLabel>
      <StatNumber>{valor ? `R$ ${formatarMoeda(valor)}` : '--'}</StatNumber>
      <StatHelpText>{helpText}</StatHelpText>
    </Stat>
  );
}

function somatorioValorQuantidadeReducer(accumulator, currentValue) {
  return {
    valorSomatorio: accumulator['valorSomatorio'] + currentValue['sumValor'],
    valorPagoSomatorio:
      accumulator['valorPagoSomatorio'] + currentValue['sumValorPago'],
    quantidade: accumulator['quantidade'] + currentValue['count']
  };
}

function getDataFaturadas(faturas) {
  return (
    faturas &&
    reduce(faturas, somatorioValorQuantidadeReducer, {
      valorSomatorio: 0,
      valorPagoSomatorio: 0,
      quantidade: 0
    })
  );
}

function getDataPagas(faturas) {
  return (
    faturas &&
    reduce(
      pickBy(faturas, item => 'PAGA' === item.estado),
      somatorioValorQuantidadeReducer,
      { valorSomatorio: 0, valorPagoSomatorio: 0, quantidade: 0 }
    )
  );
}

function getDataAVencer(faturas) {
  return (
    faturas &&
    reduce(
      pickBy(
        faturas,
        item =>
          'PAGA' !== item.estado && moment().isSameOrBefore(item.vencimento)
      ),
      somatorioValorQuantidadeReducer,
      { valorSomatorio: 0, valorPagoSomatorio: 0, quantidade: 0 }
    )
  );
}

function getDataVencidas(faturas) {
  return (
    faturas &&
    reduce(
      pickBy(
        faturas,
        item => 'FECHADA' === item.estado && moment().isAfter(item.vencimento)
      ),
      somatorioValorQuantidadeReducer,
      { valorSomatorio: 0, valorPagoSomatorio: 0, quantidade: 0 }
    )
  );
}

function DashboardFaturamento({
  data = [],
  queryStringPeriodo,
  desconectarUsuario,
  definirMensagem,
  limparMensagem
}) {
  const faturadas = useMemo(() => {
    return getDataFaturadas(data);
  }, [data]);

  const pagas = useMemo(() => {
    return getDataPagas(data);
  }, [data]);

  const aVencer = useMemo(() => {
    return getDataAVencer(data);
  }, [data]);

  const vencidas = useMemo(() => {
    return getDataVencidas(data);
  }, [data]);

  return (
    <Grid
      templateColumns={[
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)'
      ]}
      gap={[4, 4, 4, 6]}
    >
      <StatFaturamento
        label="Faturadas"
        isLoaded={!!faturadas}
        valor={faturadas?.valorSomatorio}
        helpText={`Quantidade: ${faturadas?.quantidade}`}
        getRelatorio={() => {
          limparMensagem();
          return getRelatorioFaturadas(queryStringPeriodo);
        }}
        desconectarUsuario={desconectarUsuario}
        definirMensagem={definirMensagem}
      />

      <StatFaturamento
        label="Pagas"
        isLoaded={!!pagas}
        valor={pagas?.valorPagoSomatorio}
        helpText={`Quantidade: ${pagas?.quantidade}`}
        getRelatorio={() => {
          limparMensagem();
          return getRelatorioPagas(queryStringPeriodo);
        }}
        desconectarUsuario={desconectarUsuario}
        definirMensagem={definirMensagem}
      />

      <StatFaturamento
        label="A vencer"
        isLoaded={!!aVencer}
        valor={aVencer?.valorSomatorio}
        helpText={`Quantidade: ${aVencer?.quantidade}`}
        getRelatorio={() => {
          limparMensagem();
          return getRelatorioAVencer(queryStringPeriodo);
        }}
        desconectarUsuario={desconectarUsuario}
        definirMensagem={definirMensagem}
      />

      <StatFaturamento
        label="Vencidas"
        isLoaded={!!vencidas}
        valor={vencidas?.valorSomatorio}
        helpText={`Quantidade: ${vencidas?.quantidade}`}
        getRelatorio={() => {
          limparMensagem();
          return getRelatorioVencidas(queryStringPeriodo);
        }}
        desconectarUsuario={desconectarUsuario}
        definirMensagem={definirMensagem}
      />
    </Grid>
  );
}

const TodasFaturas = () => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [filtrarPor, setFiltrarPor] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: [],
    registrosExcluidos: [],
    mensagem: undefined
  });

  const [miniDashboardData, setMiniDashboardData] = useState();

  const limparMensagem = useCallback(() => {
    if (state.mensagem) {
      dispatch({ type: 'limparMensagem' });
    }
  }, [state.mensagem]);

  const definirMensagem = useCallback(({ status, mensagem }) => {
    dispatch({ type: 'definirMensagem', status, mensagem });
  }, []);

  const recarregarRegistros = useCallback(() => {
    dispatch({
      type: 'carregarRegistros',
      offset: 0,
      filtroData: state.filtroData
    });
  }, [state.filtroData]);

  const [stateDataFiltro, dispatchDataFiltro] = useReducer(datePickerReducer, {
    isOpen: false,
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('month')
      .toDate(),
    enteredTo: moment()
      .endOf('month')
      .toDate()
  });

  const registrosMemoized = useMemo(
    () =>
      map(state.registros, fatura => {
        return (
          <Tr key={fatura.id}>
            <Td>{fatura.referencia}</Td>
            <Td>{formatarData({ data: fatura.fechamento })}</Td>
            <Td>{formatarData({ data: fatura.vencimento })}</Td>
            <Td style={{ textAlign: 'right' }}>
              {formatarMoeda(fatura.valor)}
            </Td>
            <Td>{fatura.estado}</Td>
            <Td style={{ textAlign: 'right' }}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Ações"
                  icon="tresPontosVertical"
                  variant="ghost"
                  fontSize="38px"
                  color="gray.400"
                />

                <MenuList>
                  <MenuItem
                    onClick={() =>
                      dispatch({
                        type: 'detalharRegistro',
                        idRegistro: fatura.id,
                        nomeRegistro: fatura.referencia,
                        itensDetalhados: fatura.itens,
                        totalItensDetalhados: formatarMoeda(fatura.valor)
                      })
                    }
                  >
                    Detalhar
                  </MenuItem>

                  {!isEqual('PAGA')(fatura.estado) && (
                    <MenuItem
                      onClick={() => {
                        api
                          .get(`${apiURL}faturas/${fatura.id}/imprimir`, {
                            responseType: 'arraybuffer'
                          })
                          .then(fatura => {
                            const arquivo = new Blob([fatura.data], {
                              type: 'application/pdf'
                            });
                            const arquivoURL = URL.createObjectURL(arquivo);

                            window.open(arquivoURL);
                            URL.revokeObjectURL(arquivoURL);
                          });
                      }}
                    >
                      Imprimir
                    </MenuItem>
                  )}

                  {!isEqual('PAGA')(fatura.estado) && (
                    <Link to={`./liquidacao-manual/${fatura.id}`}>
                      <MenuItem>Liquidação manual</MenuItem>
                    </Link>
                  )}
                </MenuList>
              </Menu>
            </Td>
          </Tr>
        );
      }),
    [state.registros]
  );

  useEffect(() => {
    dispatch({ type: 'carregarRegistros', offset: 0 });
  }, []);

  useEffect(() => {
    dispatch({
      type: 'carregarRegistros',
      offset: 0,
      filtroData: state.filtroData
    });
  }, [filtrarPor, state.filtroData]);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      Promise.all([
        getFaturas({
          offset: state.offset,
          filtrarPor,
          filtroData: state.filtroData
        }),
        getFaturamento(state.filtroData)
      ])
        .then(results => {
          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: results[0]?.data
            });

            setMiniDashboardData(results[1]?.data);
          }
        })
        .catch(error => {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        });

      return () => {
        didCancel = true;
      };
    }
  }, [
    state.carregando,
    state.offset,
    filtrarPor,
    state.filtroData,
    desconectarUsuario
  ]);

  useEffect(() => {
    if (state.detalhando) {
      onOpen();
    } else {
      onClose();
    }
  }, [state.detalhando, onOpen, onClose]);

  useEffect(() => {
    if (!stateDataFiltro.isOpen) {
      if (stateDataFiltro.from && stateDataFiltro.to) {
        const dataInicio = formatarData({
          data: stateDataFiltro.from,
          formato: 'YYYY-MM-DD'
        });
        const dataFim = formatarData({
          data: stateDataFiltro.to,
          formato: 'YYYY-MM-DD'
        });
        const filtroData = `start=${dataInicio}&end=${dataFim}`;

        if (filtroData !== state.filtroData) {
          dispatch({ type: 'carregarRegistros', offset: 0, filtroData });
        }
      } else {
        if (!isEmpty(state.filtroData)) {
          dispatch({ type: 'carregarRegistros', offset: 0 });
        }
      }
    }
  }, [
    stateDataFiltro.isOpen,
    stateDataFiltro.from,
    stateDataFiltro.to,
    state.filtroData
  ]);

  return (
    <>
      <BarraFiltroFaturas
        setFiltrarPor={setFiltrarPor}
        filtrarPor={filtrarPor}
      />

      <Heading as="h2" size="lg" marginBottom="20px">
        Todas
      </Heading>

      {!isEmpty(state.mensagem) && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={limparMensagem}
            marginBottom="5"
            width={['100%', '100%', '100%', '95%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.erro && <p>Ocorreu um erro..</p>}

      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="25px"
        width={['100%', '100%', '100%', '95%']}
        position="relative"
        zIndex="20"
      >
        {!state.erro && (
          <>
            <DatePickerWithNavigation
              isOpen={stateDataFiltro.isOpen}
              from={stateDataFiltro.from}
              to={stateDataFiltro.to}
              enteredTo={stateDataFiltro.enteredTo}
              dispatch={dispatchDataFiltro}
              isDisabled={state.carregando}
            />

            <div>
              <BotaoBaixarFaturas
                desconectarUsuario={desconectarUsuario}
                definirMensagem={definirMensagem}
                limparMensagem={limparMensagem}
                filtrarPor={filtrarPor}
                filtroData={state.filtroData}
                isDisabled={state.carregando}
              />

              <BotaoExecutarFechamento
                desconectarUsuario={desconectarUsuario}
                definirMensagem={definirMensagem}
                limparMensagem={limparMensagem}
                recarregarRegistros={recarregarRegistros}
                isDisabled={state.carregando}
              />
            </div>
          </>
        )}
      </Flex>

      {!state.erro && state.carregando && <Carregando align="center" />}

      {!state.erro && !state.carregando && isEmpty(registrosMemoized) && (
        <NenhumRegistro />
      )}

      {!state.erro && !state.carregando && !isEmpty(registrosMemoized) && (
        <Box width={['100%', '100%', '100%', '95%']} position="relative">
          <DashboardFaturamento
            data={miniDashboardData}
            queryStringPeriodo={state.filtroData}
            desconectarUsuario={desconectarUsuario}
            definirMensagem={({ status, mensagem }) =>
              dispatch({ type: 'definirMensagem', status, mensagem })
            }
            limparMensagem={limparMensagem}
          />
          <Box
            marginTop="4"
            paddingTop="1"
            shadow="md"
            borderWidth="1px"
            backgroundColor="#fff"
          >
            <Table>
              <Thead>
                <tr>
                  <Th>Unidade</Th>
                  <Th>Fechamento</Th>
                  <Th>Vencimento</Th>
                  <Th style={{ textAlign: 'right' }}>Valor</Th>
                  <Th>Situação</Th>
                  <Th />
                </tr>
              </Thead>
              <tbody>{registrosMemoized}</tbody>
            </Table>
          </Box>
        </Box>
      )}

      {isOpen && (
        <Scale in={true}>
          {styles => (
            <Modal
              isOpen
              onClose={() =>
                dispatch({ type: 'cancelarDetalhamentoDoRegistro' })
              }
              size="lg"
            >
              <ModalOverlay opacity={styles.opacity} />
              <ModalContent {...styles}>
                <ModalHeader>
                  Detalhamento da unidade {state.nomeRegistroASerDetalhado}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {!isEmpty(state.itensASeremDetalhados) && (
                    <Table>
                      <Thead>
                        <tr>
                          <Th>Descrição</Th>
                          <Th style={{ textAlign: 'right' }}>Valor</Th>
                        </tr>
                      </Thead>
                      <tbody>
                        {map(state.itensASeremDetalhados, item => (
                          <tr>
                            <Td>{item.descricao}</Td>
                            <Td style={{ textAlign: 'right' }}>
                              {formatarMoeda(item.valor)}
                            </Td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style={{ padding: '0 10px', textAlign: 'left' }}>
                            Total
                          </th>
                          <th style={{ padding: '0 10px', textAlign: 'right' }}>
                            {state.totalItensDetalhados}
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button
                    variantColor="blue"
                    onClick={() =>
                      dispatch({ type: 'cancelarDetalhamentoDoRegistro' })
                    }
                  >
                    Fechar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </Scale>
      )}
    </>
  );
};

export default TodasFaturas;
