/**
 * Dependências externas
 */
import React, {
  useReducer,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from 'react';
import map from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import partialRight from 'lodash/fp/partialRight';
import partial from 'lodash/fp/partial';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Heading,
  Box,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  IconButton,
  useToast
} from '@chakra-ui/core';
import { saveAs } from 'file-saver';

/**
 * Dependências internas
 */
import Carregando from 'apresentacao/Carregando';
import Mensagem from 'apresentacao/Mensagem';
import Table from 'apresentacao/tabela/Table';
import Thead from 'apresentacao/tabela/Thead';
import Th from 'apresentacao/tabela/Th';
import Td from 'apresentacao/tabela/Td';
import Tr from 'apresentacao/tabela/Tr';
import tabelaReducer from 'apresentacao/tabela/utils/tabelaReducer';
import api from 'utils/api';
import formatarData from 'paginas/utils/formatarData';
import handleHTTPError from 'paginas/utils/handleHTTPError';
import AutenticacaoContext from 'context/AutenticacaoContext';

/**
 * Requisição para recuperar arquivos remessa.
 * @returns {Promise<T>}
 */
function getArquivosRemessa() {
  return api.get(`arquivosremessa`);
}

function ArquivoRemessaElement(
  arquivoRemessa: { geracao: string; registros: number; sequencial: string },
  onErrorBaixarArquivo: () => void
) {
  return (
    <Tr key={arquivoRemessa.sequencial}>
      <Td>{arquivoRemessa.sequencial}</Td>
      <Td>
        {formatarData({
          data: arquivoRemessa.geracao,
          formato: 'DD/MM/YYYY HH:mm:ss'
        })}
      </Td>
      <Td>{arquivoRemessa.registros}</Td>
      <Td style={{ textAlign: 'right' }}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Ações"
            fontSize="38px"
            color="gray.400"
            {...{
              icon: 'tresPontosVertical',
              variant: 'ghost'
            }}
          />

          <MenuList>
            <MenuItem
              onClick={() => {
                api
                  .get(`arquivosremessa/${arquivoRemessa.sequencial}`)
                  .then(resultado => {
                    const getFromResultado = partial(get, [resultado]);

                    const arquivoRemessaBinary = getFromResultado('data');
                    const contentType = getFromResultado(
                      'headers.content-type'
                    );

                    const arquivo = new Blob([arquivoRemessaBinary], {
                      type: contentType
                    });
                    const nomeArquivo = `${formatarData({
                      data: arquivoRemessa.geracao,
                      formato: 'YYYYMMDD'
                    })}.rem`;

                    saveAs(arquivo, nomeArquivo);
                  })
                  .catch(onErrorBaixarArquivo);
              }}
            >
              Baixar
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

const TodosArquivosRemessa = () => {
  const desconectarUsuario = useContext(AutenticacaoContext);

  const [state, dispatch] = useReducer(tabelaReducer, {
    registros: [],
    registrosExcluidos: [],
    mensagem: undefined
  });

  const toast = useToast();

  const onErrorBaixarArquivo = useCallback(
    () =>
      toast({
        title: 'Ocorreu um erro.',
        description: 'Não foi possível baixar o arquivo',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      }),
    [toast]
  );

  const arquivosRemessa = useCallback(
    compose(
      partial(map, [
        partialRight(ArquivoRemessaElement, [onErrorBaixarArquivo])
      ]),
      partialRight(orderBy, [['sequencial'], ['desc']])
    ),
    [onErrorBaixarArquivo]
  );

  const registrosMemoized = useMemo(() => arquivosRemessa(state.registros), [
    state.registros,
    arquivosRemessa
  ]);

  useEffect(() => {
    dispatch({ type: 'carregarRegistros', offset: 0 });
  }, []);

  useEffect(() => {
    if (state.carregando) {
      let didCancel = false;

      const fetchData = async () => {
        try {
          const resultado = await getArquivosRemessa();

          if (!didCancel) {
            dispatch({
              type: 'registrosCarregados',
              registros: resultado.data,
              key: 'sequencial'
            });
          }
        } catch (error) {
          if (!didCancel) {
            handleHTTPError({
              error,
              handle400Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handle401Error: desconectarUsuario,
              handle403Error: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                }),
              handleGenericError: mensagemDeErro =>
                dispatch({
                  type: 'erroCarregarRegistros',
                  payload: mensagemDeErro
                })
            });
          }
        }
      };

      fetchData();

      return () => {
        didCancel = true;
      };
    }
  }, [state.carregando, state.offset, desconectarUsuario]);

  return (
    <>
      <Heading as="h2" size="lg" marginBottom="20px">
        Todos
      </Heading>

      {state.mensagem && (
        <>
          <Mensagem
            tipo={state.mensagem.status}
            fechar={() => dispatch({ type: 'limparMensagem' })}
            marginBottom="5"
            width={['100%', '100%', '100%', '70%']}
          >
            {state.mensagem.text}
          </Mensagem>
        </>
      )}

      {state.carregando && <Carregando align="center" />}

      {!state.carregando && !isEmpty(registrosMemoized) && (
        <>
          <Box
            paddingTop="1"
            shadow="md"
            borderWidth="1px"
            backgroundColor="#fff"
            width={['100%', '100%', '100%', '70%']}
          >
            <Table>
              <Thead>
                <tr>
                  <Th>Sequencial</Th>
                  <Th>Geração</Th>
                  <Th>Registros</Th>
                  <Th />
                </tr>
              </Thead>
              <tbody>{registrosMemoized}</tbody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
};

export default TodosArquivosRemessa;
