/**
 * Dependências externas
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Dependências internas
 */
import AreaPrincipal from 'apresentacao/AreaPrincipal';
import BarraSuperiorFaturas from './apresentacao/BarraSuperiorFaturas';
import ConteudoDaPagina from 'apresentacao/ConteudoDaPagina';
import rota from 'constants/rota';
import TodasFaturas from './TodasFaturas';
import { LiquidacaoManualDaFatura } from './LiquidacaoManualDaFatura';

const Faturas = props => {
  const path = get(props, 'match.path');

  return (
    <>
      <AreaPrincipal>
        <BarraSuperiorFaturas path={path} titulo={rota.faturas.titulo} />

        <ConteudoDaPagina>
          <Switch>
            <Route path={`${path}/todas`} exact component={TodasFaturas} />

            <Route
              path={`${path}/liquidacao-manual/:idFatura`}
              exact
              component={LiquidacaoManualDaFatura}
            />

            <Redirect to={`${path}/todas`} />
          </Switch>
        </ConteudoDaPagina>
      </AreaPrincipal>
    </>
  );
};

export default Faturas;
