/**
 * Dependências externas
 */
import React from 'react';

/**
 * Dependências internas
 */
import BarraSuperior, { Link } from '../../../apresentacao/BarraSuperior';

function BarraSuperiorArquivosRemessa(props: { path: string; titulo: string }) {
  const { path, titulo } = props;

  return (
    <BarraSuperior titulo={titulo}>
      <Link to={`${path}/todos`} activeClassName="selected">
        Ver todos
      </Link>
    </BarraSuperior>
  );
}

export default BarraSuperiorArquivosRemessa;
