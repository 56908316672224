/**
 * Dependências externas
 */
import omit from 'lodash/omit';

/**
 * Dependências internas
 */
import arrayToObject from 'utils/arrayToObject';
import { TiposDeMensagem } from 'apresentacao/Mensagem';

type TabelaState = {
  registros: Partial<Object>;
  registrosExcluidos: Array<any>;
  mensagem?: {
    status: TiposDeMensagem;
    text: string;
  };
  carregando?: boolean;
  offset?: number;
  filtroData?: string;
  idRegistroASerExcluido?: number;
  nomeRegistroASerExcluido?: string;
  idRegistroASerDetalhado?: number;
  nomeRegistroASerDetalhado?: string;
  itensASeremDetalhados?: Array<any>;
  totalItensDetalhados?: string;
  detalhando?: boolean;
  excluindo?: boolean;
};

type Action =
  | { type: 'carregarRegistros'; offset?: number; filtroData?: string }
  | { type: 'registrosCarregados'; key: string; registros: Array<any> }
  | { type: 'erroCarregarRegistros'; payload: string }
  | {
      type: 'detalharRegistro';
      idRegistro: number;
      nomeRegistro: string;
      itensDetalhados: Array<any>;
      totalItensDetalhados: string;
    }
  | { type: 'cancelarDetalhamentoDoRegistro' }
  | { type: 'excluirRegistro'; idRegistro: number; nomeRegistro: string }
  | { type: 'cancelarExclusaoDoRegistro' }
  | { type: 'registroExcluido'; idRegistroExcluido: number }
  | { type: 'erroExcluirRegistro' }
  | { type: 'definirMensagem'; status: TiposDeMensagem; mensagem: string }
  | { type: 'limparMensagem' };

/**
 * Reducer que controla os estados de uma tabela.
 * @param {TabelaState} state O estado atual.
 * @param {Action} action Ações para mudar o estado.
 * @returns {object}
 */
function tabelaReducer(state: TabelaState, action: Action): TabelaState {
  switch (action.type) {
    case 'carregarRegistros':
      return {
        ...state,
        carregando: true,
        mensagem: undefined,
        offset: action.offset,
        filtroData: action.filtroData
      };

    case 'registrosCarregados':
      const key = action.key || 'id';
      return {
        ...state,
        carregando: false,
        registros: arrayToObject(action.registros, key)
      };

    case 'erroCarregarRegistros': {
      return {
        ...state,
        carregando: false,
        mensagem: {
          status: 'error',
          text: action.payload
        }
      };
    }

    case 'detalharRegistro':
      return {
        ...state,
        idRegistroASerDetalhado: action.idRegistro,
        nomeRegistroASerDetalhado: action.nomeRegistro,
        itensASeremDetalhados: action.itensDetalhados,
        totalItensDetalhados: action.totalItensDetalhados,
        detalhando: true
      };

    case 'cancelarDetalhamentoDoRegistro':
      return {
        ...state,
        idRegistroASerDetalhado: undefined,
        nomeRegistroASerDetalhado: undefined,
        itensASeremDetalhados: undefined,
        totalItensDetalhados: undefined,
        detalhando: false
      };

    case 'excluirRegistro':
      return {
        ...state,
        idRegistroASerExcluido: action.idRegistro,
        nomeRegistroASerExcluido: action.nomeRegistro,
        excluindo: true
      };

    case 'cancelarExclusaoDoRegistro':
      return {
        ...state,
        idRegistroASerExcluido: undefined,
        nomeRegistroASerExcluido: undefined,
        excluindo: false
      };

    case 'registroExcluido':
      return {
        ...state,
        idRegistroASerExcluido: undefined,
        nomeRegistroASerExcluido: undefined,
        excluindo: false,
        mensagem: { status: 'success', text: 'Registro excluído' },
        registros: omit(state.registros, [action.idRegistroExcluido]),
        registrosExcluidos: [
          ...state.registrosExcluidos,
          action.idRegistroExcluido
        ]
      };

    case 'erroExcluirRegistro':
      return {
        ...state,
        excluindo: false,
        mensagem: {
          status: 'error',
          text: 'Não foi possível excluir o registro'
        }
      };

    case 'definirMensagem':
      return {
        ...state,
        mensagem: {
          status: action.status,
          text: action.mensagem
        }
      };

    case 'limparMensagem':
      return {
        ...state,
        mensagem: undefined
      };

    default:
      throw new Error();
  }
}

export default tabelaReducer;
