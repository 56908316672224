/**
 * Dependências externas
 */
import React from 'react';
import { connect as connectToFormik } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from '@chakra-ui/core';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';

/**
 * Dependênicas internas
 */
import getInformacoesDoCampo from 'utils/form/getInformacoesDoCampo';
import formatarMoeda from 'paginas/utils/formatarMoeda';

const ValorPagamento = props => {
  const nomeDoCampo = 'valorPagamento';

  const { formik, ...rest } = props;

  const { handleChange, handleBlur } = formik;

  const { valor, erro, possuiErroDeValidacao } = getInformacoesDoCampo(
    props,
    nomeDoCampo
  );

  const valorApenasNumeros = replace(valor, /[^0-9]/g, '');

  const valorFormatado = isEmpty(valorApenasNumeros)
    ? valorApenasNumeros
    : formatarMoeda(valorApenasNumeros);

  return (
    <FormControl isInvalid={possuiErroDeValidacao} {...rest}>
      <FormLabel htmlFor={nomeDoCampo}>Valor Pago</FormLabel>
      <Input
        type="text"
        id={nomeDoCampo}
        name={nomeDoCampo}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valorFormatado}
        textAlign="right"
        autoComplete="off"
      />
      {possuiErroDeValidacao && <FormErrorMessage>{erro}</FormErrorMessage>}
    </FormControl>
  );
};

export default connectToFormik(ValorPagamento);
